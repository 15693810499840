import useCollectAllSmartTags from '@components/pages/side-kick/hooks/useGetAllSmartTags';
import useStartRecordingSession from '@components/pages/side-kick/hooks/useStartRecordingSession';
import StyledStack from '@components/pages/side-kick/sections/start-recording/styles';
import { useTranslation } from '@desygner/ui-common-translation';
import useResponsive from '@hooks/useResponsive';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';

const SIDE_KICK_IMAGE_WIDTH = 242;
const SIDE_KICK_IMAGE_HEIGHT = 165;

export default function StartRecording() {
  const { t } = useTranslation();

  const { handleStartRecoding, isMutateLiveTokenPending, isPermissionLoading } =
    useStartRecordingSession();

  const isItMobileOrTablet = useResponsive('down', 'md');

  const { areCollectedSmartTagsSuccessfullyLoaded } = useCollectAllSmartTags();

  const isBusy = isMutateLiveTokenPending || isPermissionLoading;

  return (
    <StyledStack gap={7}>
      <img
        src="https://assets.knowz.com/assets/svg/waves.svg"
        alt="knowz live"
        width={SIDE_KICK_IMAGE_WIDTH}
        height={SIDE_KICK_IMAGE_HEIGHT}
      />
      <Typography component="h1" variant="h3">
        {t('page.sideKick.grids.transcribe.body.title', {
          defaultValue: 'Record smarter, get AI insights instantly!',
        })}
      </Typography>
      <Typography variant="body1">
        {t('page.sideKick.grids.transcribe.body.subtitle', {
          defaultValue:
            'Tap to record and let our assistant turn your words into valuable insights. Speak freely, and see your thoughts come to life!',
        })}
      </Typography>
      <LoadingButton
        disabled={!areCollectedSmartTagsSuccessfullyLoaded}
        startIcon={<GraphicEqIcon />}
        variant="contained"
        size={isItMobileOrTablet ? 'medium' : 'large'}
        onClick={handleStartRecoding}
        loading={isBusy}
      >
        {t('page.sideKick.grids.transcribe.body.button', {
          defaultValue: 'Start Smart Recording',
        })}
      </LoadingButton>
    </StyledStack>
  );
}
