import Fade from '@mui/material/Fade';
import MuiModal, { ModalProps } from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import { BreakpointValues } from '@shared-types/theme';

const PADDING = {
  sm: 2,
  md: 4,
  lg: 8,
  none: 0,
};

type Props = React.PropsWithChildren<
  Omit<ModalProps, 'children'> & {
    width?: number | string | Partial<{ [key in BreakpointValues]: number }>;
    height?: number | string | Partial<{ [key in BreakpointValues]: number }>;
    paddingType?: 'sm' | 'md' | 'lg' | 'none';
  }
>;

export default function Modal({
  width,
  height,
  open,
  children,
  paddingType = 'none',
  sx,
  ...otherProps
}: Props) {
  return (
    <MuiModal open={open} disableEnforceFocus {...otherProps}>
      <Fade in={open}>
        <Stack
          direction="row"
          sx={{
            transform: 'translate(-50%, -50%)',
            top: '50%',
            left: '50%',
            position: 'fixed',
            width,
            height,
            backgroundColor: 'background.card.light',
            borderRadius: 4,
            padding: PADDING[paddingType],
            ...sx,
          }}
        >
          {children}
        </Stack>
      </Fade>
    </MuiModal>
  );
}
