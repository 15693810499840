import StyledStack from '@components/upgrade-modal/components/card/popular-badge/styles';
import { useTranslation } from '@desygner/ui-common-translation';
import useResponsive from '@hooks/useResponsive';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

type Props = {
  switcher: React.ReactNode;
};

const IMAGE_SIZE = 20;

export default function PopularBadge({ switcher }: Props) {
  const { t } = useTranslation();
  const isItDesktop = useResponsive('up', 'md');

  return (
    <StyledStack direction={isItDesktop ? 'row' : 'column'} gap={3}>
      <Stack
        direction="row"
        sx={{ alignItems: 'center', justifyContent: 'center' }}
        gap={2}
      >
        <img
          src="https://assets.knowz.com/assets/images/star.png"
          width={IMAGE_SIZE}
          height={IMAGE_SIZE}
          alt="Most popular"
        />
        <Typography variant="subtitle2">
          {t('layout.upgrade.mostPopular', {
            defaultValue: 'Most popular',
          })}
        </Typography>
      </Stack>
      {switcher}
    </StyledStack>
  );
}
