import CONFIG from '@layouts/main/config';
import { convertToRem } from '@lib/fonts';
import { alpha, styled } from '@mui/material';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';

export const StyledAlert = styled(Alert)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  marginBottom: theme.spacing(2),
  height: convertToRem(CONFIG.SETUP_MODE.ON.HEIGHT),
}));

export const StyledStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: alpha(theme.palette.common.white, 0.1),
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'block',
  },
  borderColor: alpha(theme.palette.common.white, 0.06),
  height: theme.spacing(6),
}));
