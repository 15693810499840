import useQueryAfterWorkspaceLoaded from '@hooks/useQueryAfterWorkspaceLoaded';
import { sources } from '@lib/agent';

export default function useGetSourceDetailsByIdLazily({
  id,
}: {
  id: number | null;
}) {
  const {
    data: sourceDetailsData,
    isLoading: isSourceDetailsLoading,
    isError: isSourceDetailsError,
  } = useQueryAfterWorkspaceLoaded({
    queryKey: ['source', id],
    queryFn: async () => {
      try {
        const { data } = await sources.getOneById({
          id: id!,
        });

        return data;
      } catch (error: unknown) {
        throw new Error(`An error occurred while fetching sources: ${error}`);
      }
    },
    options: {
      enabled: !!id,
      staleTime: Infinity,
    },
  });

  return {
    sourceDetailsData,
    isSourceDetailsLoading,
    isSourceDetailsError,
  };
}
