import { styled } from '@mui/material';
import Chip from '@mui/material/Chip';

const StyledChip = styled(Chip)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 1.5,
  marginLeft: 'auto',
  marginRight: theme.spacing(1),
}));

export default StyledChip;
