import StyledCard from '@components/pages/knowledge-base/components/modals/add-knowledge-base/styles';
import useKnowledgeBaseData from '@components/pages/knowledge-base/hooks/useKnowledgeBaseData';
import UppyUpload from '@components/uppy-upload';
import Modal from '@mui/material/Modal';

export default function AddKnowledgeBaseModal() {
  const { handleToggleAddKnowledgeModal, isAddKnowledgeModalOpen } =
    useKnowledgeBaseData();

  return (
    <Modal
      open={isAddKnowledgeModalOpen}
      onClose={handleToggleAddKnowledgeModal}
    >
      <StyledCard>
        <UppyUpload
          handleToggleAddKnowledgeModal={handleToggleAddKnowledgeModal}
        />
      </StyledCard>
    </Modal>
  );
}
