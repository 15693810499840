import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';

export const StyledWrapperStack = styled(Stack)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.divider,
  borderRadius: theme.shape.borderRadius * 100,
  alignItems: 'center',
}));

export const StyledStack = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  padding: theme.spacing(2),
}));
