/* eslint-disable import/prefer-default-export */
import requests from '@lib/agent/requests';
import axiosApiInstance from '@lib/agent/services/api/axiosInstance';
import {
  LiveSideKickRequestType,
  LiveSideKickResponseType,
  TrainingSetsRequestType,
  TrainingSetsResponseType,
} from '@shared-types/sidekick';

export const live = {
  createLiveToken: (body: LiveSideKickRequestType) =>
    requests(axiosApiInstance).post<LiveSideKickResponseType>(
      '/v1/actions/live/side-kick',
      body,
    ),
  getTrainingSetByAlias: ({ alias }: TrainingSetsRequestType) =>
    requests(axiosApiInstance).get<TrainingSetsResponseType>(
      `/v1/entities/training-sets?alias=${alias}`,
    ),
};
