import { storage } from '@lib/agent';
import { useQuery } from '@tanstack/react-query';

type CredentialType = {
  key?: string;
  isShortUrl?: boolean;
};

export default function useGetCredentialByKeyLazily({
  key,
  isShortUrl,
}: CredentialType) {
  const { data: credentialData, isLoading: isCredentialDataLoading } = useQuery(
    {
      queryKey: ['credentials', key],
      queryFn: async () => {
        return await storage.getCredentials(key!, isShortUrl);
      },
      enabled: !!key,
      staleTime: Infinity,
    },
  );

  return {
    credentialData,
    isCredentialDataLoading,
  };
}
