import Timer from '@components/pages/side-kick-session/recorder/LiveTimer';
import StyledBox from '@components/pages/side-kick-session/recorder/mobile/styles';
import { useTranslation } from '@desygner/ui-common-translation';
import useTranscriptionService from '@hooks/useTranscriptionService';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import ButtonBase from '@mui/material/ButtonBase';
import Stack from '@mui/material/Stack';
import paths from '@router/paths';
import { confirm } from 'material-ui-confirm';
import { useNavigate } from 'react-router-dom';

const SHARED_PROPS = {
  disableRipple: true,
  disableTouchRipple: true,
};

export default function Recorder({ children }: React.PropsWithChildren) {
  const { t } = useTranslation();

  const { stopTranscript } = useTranscriptionService();

  const navigateTo = useNavigate();

  async function handleStopRecording() {
    confirm({
      title: t('page.sideKickSession.confirmStopRecording.title', {
        defaultValue: 'Stop Recording Session',
      }),
      description: t('page.sideKickSession.confirmStopRecording.content', {
        defaultValue: 'Are you sure you want to stop recording?',
      }),
      confirmationText: t('page.sideKickSession.confirmStopRecording.yes', {
        defaultValue: 'Yes, I want to stop recording',
      }),
      cancellationText: t('page.sideKickSession.confirmStopRecording.no', {
        defaultValue: 'No, I want to continue',
      }),
    })
      .then(() => {
        stopTranscript();
        navigateTo(paths.sideKick.pathname);
      })
      .catch(() => null);
  }

  return (
    <StyledBox>
      <Stack gap={4}>
        {children}
        <Timer />
        <Stack
          direction="row"
          gap={2.5}
          sx={{
            justifyContent: 'center',
          }}
        >
          {/* <ButtonBase {...SHARED_PROPS}> //TODO: Implement these buttons
            <PauseIcon fontSize="large" />
          </ButtonBase> */}
          <ButtonBase {...SHARED_PROPS} onClick={handleStopRecording}>
            <RadioButtonCheckedIcon color="error" fontSize="large" />
          </ButtonBase>
          {/* <ButtonBase {...SHARED_PROPS}> //TODO: Implement these buttons
            <TuneIcon fontSize="large" />
          </ButtonBase> */}
        </Stack>
      </Stack>
    </StyledBox>
  );
}
