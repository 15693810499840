import EllipsisTypography from '@components/ellipsis-typography';
import SectionHeading from '@components/pages/search/components/SectionHeading';
import StyledListItem from '@components/pages/search/components/shared-blocks/followups/styles';
import SearchIcon from '@mui/icons-material/Search';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Grid2 from '@mui/material/Grid2';
import LinkIcon from '@mui/icons-material/Link';
import Icon from '@mui/material/Icon';
import Card from '@mui/material/Card';
import { useTranslation } from '@desygner/ui-common-translation';
import usePromptHistoryState from '@components/pages/hooks/usePromptHistoryState';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useGetThreadIdFromQueryParam from '@hooks/useGetThreadFromQueryParam';
import { RootType } from '@state/store';
import useGeolocation from '@hooks/useGeoLocation';
import useAppSettings from '@hooks/useAppSettings';

type Props = {
  followups: Array<string> | [];
  disabled?: boolean;
};

export default function Followups({ followups, disabled = false }: Props) {
  const { t } = useTranslation();

  const { setNewHistoryStatePrompt } = usePromptHistoryState();

  const searchMode = useSelector((state: RootType) => state.searchResult.mode);

  const { threadId } = useGetThreadIdFromQueryParam();

  const [destroy, setDestroy] = useState(false);

  const { userGeolocation } = useAppSettings();

  const dispatch = useDispatch();

  if (followups.length === 0 || destroy === true) return null;

  function handleFollowup(followup: string) {
    dispatch({
      type: 'searchResult/askAIEmitted',
      payload: {
        thread: threadId,
        prompt: followup,
        mode: searchMode,
        context: {
          latLng: [userGeolocation.lat, userGeolocation.lng],
        },
      },
    });
    setNewHistoryStatePrompt(followup);
    setDestroy(true);
  }

  return (
    <Card>
      <Grid2 xs={12}>
        <SectionHeading
          icon={
            <Icon>
              <LinkIcon />
            </Icon>
          }
          title={t('page.search.sections.relatedQuestions', {
            defaultValue: 'Related Questions',
          })}
        >
          <Stack component={List} gap={2}>
            {followups.map((singleFollowup, i: number) => (
              <StyledListItem key={i} disableGutters disablePadding>
                <ListItemButton
                  disabled={disabled}
                  onClick={() => handleFollowup(singleFollowup)}
                  sx={{
                    color: 'text.secondary',
                    height: 80,
                    p: 2,
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                  }}
                >
                  <ListItemIcon>
                    <SearchIcon />
                  </ListItemIcon>
                  <ListItemText>
                    <EllipsisTypography lineClamp={2} variant="body2">
                      {singleFollowup}
                    </EllipsisTypography>
                  </ListItemText>
                </ListItemButton>
              </StyledListItem>
            ))}
          </Stack>
        </SectionHeading>
      </Grid2>
    </Card>
  );
}
