import Connectors from '@components/pages/connectors/Connectors';
import {
  StyledCenteredContent,
  StyledViewportHeightStack,
} from '@components/pages/styles';
import Container from '@mui/material/Container';

export default function ConnectorsIndex() {
  return (
    <Container>
      <StyledViewportHeightStack>
        <StyledCenteredContent gap={8}>
          <Connectors />
        </StyledCenteredContent>
      </StyledViewportHeightStack>
    </Container>
  );
}
