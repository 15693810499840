//TODO: props drilling should be fixed as soon as we start implementing new design
import Accordion from '@components/pages/side-kick-session/sections/content/components/feed/list-item/accordion';
import Box from '@mui/material/Box';
import { FactCheckType, TimeType } from '@shared-types/sidekick';
import { forwardRef, memo } from 'react';

const Card = memo(
  forwardRef<
    HTMLDivElement,
    FactCheckType & {
      setRowHeight: (index: number, size: number) => void;
      index: number;
      time: TimeType;
    }
  >(({ avatar, index, name, time, result, setRowHeight }, ref) => {
    return (
      <Box ref={ref} sx={{ m: 2, border: '1px solid', borderColor: 'divider' }}>
        {result.map(({ body, id, outcome, sources }, _index) => (
          <Accordion
            ref={ref}
            key={id}
            time={time}
            body={body}
            outcome={outcome}
            sources={sources}
            avatar={avatar}
            name={name}
            index={index}
            setRowHeight={setRowHeight}
          />
        ))}
      </Box>
    );
  }),
);

Card.displayName = 'Card';

export default Card;
