const MUI_CONTAINER_PADDING_X = 24;

const HEADER = {
  HEIGHT: 65,
  PADDING_Y: 24,
  PADDING_X: 24,
};

const FOOTER = {
  HEIGHT: 40,
};

const SETUP_MODE = {
  ON: {
    HEIGHT: 62,
  },
};

const CONFIG = {
  HEADER,
  FOOTER,
  MUI_CONTAINER_PADDING_X,
  SETUP_MODE,
} as const;

export default CONFIG;
