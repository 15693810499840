import { KnowzStepDefinitionType } from '@lib/step/types';

const MessageOnSlackStepDefinition: KnowzStepDefinitionType = {
  order: 401,
  group: 'Slack',
  step: {
    componentType: 'task',
    type: 'slack-message',
    name: 'Slack Message',
  },
  contextTypes: [
    'ref-json',
    'ref-string',
    'ref-source',
    'ref-thread',
    'ref-history-block',
  ],
  propertyTypes: [
    {
      id: 'message',
      name: 'Smart message',
      description: 'Define your message. You can inline the inputs you defined',
      usage: 'definition',
      wrapper: 'item',
      types: ['string'],
    },
    // { TODO
    //   id: 'knowledge',
    //   name: 'Background knowledge',
    //   description:
    //     `Define background knowledge. This knowledge won't be displayed to ` +
    //     'the user, but it may be used for continuing the conversation',
    //   usage: 'definition',
    //   wrapper: 'item',
    //   types: ['string'],
    // },
    {
      id: 'channel',
      name: 'Channel',
      description:
        'Define the channel where the message will be sent. ' +
        'Leave it empty if you want to send private messages',
      usage: 'definition',
      variant: 'short',
      wrapper: 'item',
      types: ['string'],
      isOptional: true,
    },
    {
      id: 'userId',
      name: 'User ID',
      description:
        'Define the user ID of the person you want to send the message to. ' +
        'Leave it empty if you want to send a message to a channel',
      usage: 'definition',
      variant: 'short',
      wrapper: 'item',
      types: ['string'],
      isOptional: true,
    },
  ],
};

export default MessageOnSlackStepDefinition;
