import { alpha, styled } from '@mui/material';
import Stack from '@mui/material/Stack';

const StyledStack = styled(Stack)(({ theme }) => ({
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(4),
  backgroundColor: alpha(theme.palette.warning.main, 0.1),
  borderBottom: `1px solid ${theme.palette.warning.main}`,
  borderTopRightRadius: theme.shape.borderRadius * 4,
  borderTopLeftRadius: theme.shape.borderRadius * 4,
}));

export default StyledStack;
