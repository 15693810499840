import FormProvider from '@components/react-hook-form/FormProvider';
import RHFTextField from '@components/react-hook-form/RHFTextField';
import RHFSearchModeSelect from '@components/search-form/react-hook-form-search-mode-select';
import searchFormSchema from '@components/search-form/schema';
import {
  StyledSearchButton,
  StyledSearchFormWrapper,
} from '@components/search-form/styles';
import { useTranslation } from '@desygner/ui-common-translation';
import { yupResolver } from '@hookform/resolvers/yup';
import useAppSelector from '@hooks/useAppSelector';
import SearchIcon from '@mui/icons-material/Search';
import { alpha, SxProps, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { AskAISearchType } from '@shared-types/search-result/types';
import { SubmitHandler, useForm } from 'react-hook-form';

type SearchFormValuesType = {
  name: string;
  mode: AskAISearchType['mode'];
};

type SearchFormType = {
  onSubmit: SubmitHandler<SearchFormValuesType>;
  sx?: SxProps;
};

export default function SearchForm({ onSubmit, sx }: SearchFormType) {
  const { t } = useTranslation();

  const theme = useTheme();

  const mode = useAppSelector((state) => state.searchResult.mode);

  const defaultValues: SearchFormValuesType = {
    name: '',
    mode,
  };

  const methods = useForm({
    resolver: yupResolver(searchFormSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isDirty, isSubmitting },
  } = methods;

  return (
    <StyledSearchFormWrapper sx={sx}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack
          direction="row"
          gap={2}
          sx={{
            alignItems: 'center',
            marginBlockEnd: 2.5,
            marginBlockStart: 1, //TODO: remove this when upload and screen implemented
            justifyContent: 'flex-end',
          }}
        >
          {/* <Tooltip //TODO: implement this feature
            title={t('comingSoon', {
              defaultValue: 'Coming soon',
            })}
            placement="top"
          >
            <Stack direction="row">
              <IconButton disabled>
                <UploadFileOutlinedIcon />
              </IconButton>
              <IconButton disabled>
                <CameraOutlinedIcon />
              </IconButton>
            </Stack>
          </Tooltip> */}
          <RHFSearchModeSelect name="mode" />
        </Stack>
        <RHFTextField
          sx={{
            background: theme.palette.background.default,
            borderRadius: theme.shape.borderRadius * 10,
          }}
          role="search"
          type="text"
          name="name"
          disabled={isSubmitting}
          autoComplete="off"
          helperText={undefined} //? we don't want to show the error message
          InputProps={{
            endAdornment: (
              <StyledSearchButton
                variant="outlined"
                color="inherit"
                endIcon={<SearchIcon />}
                onClick={handleSubmit(onSubmit)}
                disabled={!isDirty || isSubmitting}
                disableRipple
                disableTouchRipple
                disableFocusRipple
              >
                <Box
                  component="span"
                  sx={{ display: { xs: 'none', md: 'block' } }}
                >
                  {t('page.home.hero.search.button', {
                    defaultValue: 'Uncover secrets',
                  })}
                </Box>
              </StyledSearchButton>
            ),
            placeholder: t('page.home.hero.search.placeholder', {
              defaultValue: 'Search for...',
            }),
            sx: {
              borderRadius: theme.shape.borderRadius * 10,
              paddingInlineEnd: 0,
              paddingInlineStart: theme.spacing(2),
              height: theme.spacing(12),
              backgroundColor:
                theme.palette.mode === 'dark'
                  ? alpha(theme.palette.common.white, 0.05)
                  : alpha(theme.palette.common.black, 0.06),
            },
          }}
        />
      </FormProvider>
    </StyledSearchFormWrapper>
  );
}
