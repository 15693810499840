import { useMemo, useEffect, useState, useCallback } from 'react';
import { useTranslation } from '@desygner/ui-common-translation';
import { useParams } from 'react-router-dom';
import useThemeSettings from '@hooks/useThemeSettings';
import useAuth from '@hooks/useAuth';
import { toast } from 'react-toastify';
import {
  StyledMenuToggle,
  StyledApp,
} from '@components/pages/command-designer/styles';
import {
  StepsConfiguration,
  ToolboxConfiguration,
  ValidatorConfiguration,
} from 'sequential-workflow-designer';
import {
  SequentialWorkflowDesigner,
  WrappedDefinition,
  wrapDefinition,
} from 'sequential-workflow-designer-react';
import { stepsConfig, toolboxConfig, validatorConfig } from './config';
import RootEditor from '@components/pages/command-designer/sections/root-editor';
import StepEditor from '@components/pages/command-designer/sections/step-editor';
import {
  useGetCommand,
  useUpdateOneCommand,
  useGetCommandDefinition,
  useSaveCommandDefinition,
} from '@hooks/useCommands';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';
import { HashMap } from '@shared-types/utils';
import usePortal from '@hooks/usePortal';
//? material ui has got a component called Portal, maybe we should use that instead of createPortal
import { createPortal } from 'react-dom';
import useWorkspace from '@hooks/useWorkspace';
import useAppSelector from '@hooks/useAppSelector';
import useViewport from '@components/pages/command-designer/hooks/useViewport';

const SAVE_DEBOUNCE_IN_SECS = 15;
let debounceTimeout: NodeJS.Timeout | null = null;

function cleanup() {
  if (debounceTimeout) {
    clearTimeout(debounceTimeout);
    debounceTimeout = null;
  }
}

export default function CommandDesigner() {
  const { viewportExtension, focusStep, setDefaultViewport } = useViewport();
  const { t } = useTranslation();
  const { authMethod } = useAuth(); // TODO: for Amir to look into (authMethod does not exist anymore)
  const { id } = useParams();
  const { themeMode } = useThemeSettings();
  const [wrappedDefinition, setWrappedDefinition] = useState();
  const [selectedStepId, setSelectedStepId] = useState<string | null>(null);
  const [isEditorCollapsed, setIsEditorCollapsed] = useState(true);
  const [isToolboxCollapsed, setIsToolboxCollapsed] = useState<boolean | null>(
    null,
  );
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const toolboxConfiguration: ToolboxConfiguration = useMemo(
    () => toolboxConfig,
    [],
  );
  const stepsConfiguration: StepsConfiguration = useMemo(() => stepsConfig, []);
  const validatorConfiguration: ValidatorConfiguration = useMemo(
    () => validatorConfig,
    [],
  );

  const { navbarMain } = usePortal();
  const runnerStatus = useAppSelector((state) => state.flowRun.runnerStatus);
  const flow = useAppSelector((state) => state.flowRun.currentEvent);
  const { commandData, isLoadingCommand } = useGetCommand(id!);
  const { commandDefinition, isLoadingCommandDefinition } =
    useGetCommandDefinition(id!);
  const { mutateAsync: mutateCommandDefinitionAsync } =
    useSaveCommandDefinition();
  const { mutateAsync: mutateUpdateOneCommandAsync } = useUpdateOneCommand();

  const { setupMode } = useWorkspace();

  useEffect(() => {
    if (!wrappedDefinition?.value && commandDefinition) {
      setWrappedDefinition(wrapDefinition(commandDefinition));

      if (isToolboxCollapsed === null) {
        setIsToolboxCollapsed(commandDefinition.sequence.length !== 0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commandDefinition]);

  useEffect(() => {
    const states = flow?.state || [];
    const lastStep = states.findLast((state: string) => state.match(/^STEP_/));
    const stepId = lastStep ? lastStep.replace(/^STEP_/, '') : null;

    setSelectedStepId(stepId);
    stepId && focusStep(stepId); // TODO: restore pre-run viewport
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flow]);

  useEffect(() => {
    runnerStatus === 'idle' && setDefaultViewport();
  }, [runnerStatus]);

  const save = useCallback(
    function (isManualSave = false) {
      const { value: definition } = wrappedDefinition;
      // TODO: use a proper hashing once available
      const oldDefinitionFingerprint = JSON.stringify(commandDefinition);
      const newDefinitionFingerprint = JSON.stringify(definition);
      const hasChanges = oldDefinitionFingerprint !== newDefinitionFingerprint;

      if (hasChanges) {
        const { data } = commandData;
        const payload: HashMap<string> = {};

        for (const key of ['name', 'description', 'icon']) {
          const value = definition.properties[key] || '';
          const isValidChange =
            data[key] !== value && (key === 'icon' || value); // name can't be empty

          if (isValidChange) payload[key] = value;
        }

        Object.keys(payload).length &&
          mutateUpdateOneCommandAsync({ id: id!, ...payload });
        mutateCommandDefinitionAsync({ commandId: id!, definition });
      }

      if (isManualSave) {
        toast.success(
          t('page.commands.save', {
            defaultValue: 'Changes saved successfully',
          }),
        );
      }

      setHasUnsavedChanges(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, commandData, commandDefinition, wrappedDefinition],
  );

  useEffect(() => {
    if (hasUnsavedChanges) {
      if (!debounceTimeout) {
        debounceTimeout = setTimeout(save, SAVE_DEBOUNCE_IN_SECS * 1000);
      }
    } else {
      cleanup();
    }

    return () => cleanup();
  }, [hasUnsavedChanges, save]);

  const isLoading =
    isLoadingCommandDefinition || !wrappedDefinition || isLoadingCommand;

  if (isLoading) {
    return (
      <LinearProgress
        sx={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          width: '50%',
          maxWidth: '400px',
          transform: 'translate(-50%, -50%)',
        }}
      />
    );
  }

  function onDefinitionChange(
    wrappedDefinition: WrappedDefinition<{ properties: {}; sequence: [] }>,
  ) {
    setHasUnsavedChanges(true);
    setWrappedDefinition(wrappedDefinition);
  }

  return (
    <StyledApp
      isRunning={runnerStatus !== 'idle'}
      isLoggedIn={authMethod === 'email'}
      setupMode={setupMode}
    >
      {navbarMain &&
        createPortal(
          hasUnsavedChanges && (
            <Button
              variant="text"
              onClick={() => save(true)}
              sx={{
                opacity: 0.2,
                color: (theme) => theme.palette.text.secondary,

                '&:hover': {
                  opacity: 1,
                  backgroundColor: 'transparent!important',
                  color: (theme) => theme.palette.text.secondary,
                },
              }}
            >
              {t('page.commands.buttons.save', { defaultValue: 'Save now' })}
            </Button>
          ),
          navbarMain,
        )}

      <StyledMenuToggle
        setupMode={setupMode}
        onClick={() => setIsEditorCollapsed(!isEditorCollapsed)}
        className={isEditorCollapsed ? 'collapsed' : undefined}
      >
        <div>
          <span>
            {t('page.commands.buttons.configuration', {
              defaultValue: 'Configuration',
            })}
          </span>

          {/* TODO: replace with MUI icons */}
          {isEditorCollapsed ? (
            <svg className="sqd-toolbox-toggle-icon" viewBox="0 0 48 48">
              <path
                d="m24 30.75-12-12 2.15-2.15L24 26.5l9.85-9.85L36 18.8Z"
                className="sqd-icon-path"
              ></path>
            </svg>
          ) : (
            <svg className="sqd-toolbox-toggle-icon" viewBox="0 0 48 48">
              <path
                d="m12.45 37.65-2.1-2.1L21.9 24 10.35 12.45l2.1-2.1L24 21.9l11.55-11.55 2.1 2.1L26.1 24l11.55 11.55-2.1 2.1L24 26.1Z"
                className="sqd-icon-path"
              ></path>
            </svg>
          )}
        </div>
      </StyledMenuToggle>

      <SequentialWorkflowDesigner
        theme={themeMode}
        definition={wrappedDefinition}
        onDefinitionChange={onDefinitionChange}
        stepsConfiguration={stepsConfiguration}
        validatorConfiguration={validatorConfiguration}
        toolboxConfiguration={toolboxConfiguration}
        controlBar
        contextMenu={false}
        rootEditor={
          <RootEditor onDefinitionChange={onDefinitionChange} commandId={id!} />
        }
        stepEditor={<StepEditor />}
        keyboard={false}
        selectedStepId={selectedStepId}
        onSelectedStepIdChanged={setSelectedStepId}
        isEditorCollapsed={isEditorCollapsed}
        isToolboxCollapsed={
          isToolboxCollapsed === null ? false : isToolboxCollapsed
        }
        onIsEditorCollapsedChanged={setIsEditorCollapsed}
        onIsToolboxCollapsedChanged={setIsToolboxCollapsed}
        extensions={[viewportExtension]}

        // isReadonly?: boolean;
        // undoStackSize?: number;
        // controller?: SequentialWorkflowDesignerController;
        // customActionHandler?: CustomActionHandler;
        // extensions?: DesignerExtension[];
      />
    </StyledApp>
  );
}
