//! danger zone do not touch anything without talking to JOEY, have a nice day
import KnowledgeInfoCard from '@components/knowledge-info-card';
import StyledMarkdown from '@components/markdown/styles';
import { useTranslation } from '@desygner/ui-common-translation';
import useAppSelector from '@hooks/useAppSelector';
import { alpha, SxProps } from '@mui/material';
import ButtonBase from '@mui/material/ButtonBase';
import { knowledgeLinksType } from '@shared-types/search-result/streaming';
import { useEffect, useRef } from 'react';
import { Options as MarkdownOptions } from 'react-markdown';

type Props = React.PropsWithChildren<MarkdownOptions & { sx?: SxProps }> & {
  links?: Array<knowledgeLinksType>;
  knowledgeCard?: {
    isOpen: boolean;
    metadata: knowledgeLinksType;
  };
  setKnowledgeCard?: (value: {
    isOpen: boolean;
    metadata: knowledgeLinksType;
  }) => void;
};

export default function Markdown({
  setKnowledgeCard,
  knowledgeCard,
  links,
  children,
  sx,
  ...rest
}: Props) {
  const { t } = useTranslation();
  const streamingLinks = useAppSelector(
    (state) => state.searchResult.knowledgeLinks,
  );
  // todo
  // {
  //   externalLinks: Array<knowledgeLinksType>;
  //   knowledgeLinks: Array<knowledgeLinksType>;
  // }
  const filteredLinks = streamingLinks?.filter(
    (link) => link.kind !== 'customsearch#result',
  ) as Array<knowledgeLinksType>;

  const linksRef = useRef(links);

  useEffect(() => {
    if (filteredLinks) {
      linksRef.current = filteredLinks;
    } else {
      linksRef.current = links;
    }
  }, [streamingLinks, links, filteredLinks]);

  function handleKnowledgeCardClick(href: string) {
    const sourceId = href.split('sourceId=')[1];
    const highestScoredSource = linksRef.current
      ?.filter((link) => link.sourceId === sourceId)
      .sort((sourceA, sourceB) => sourceB.score - sourceA.score)[0];

    if (typeof highestScoredSource === 'undefined') return;

    setKnowledgeCard?.({
      isOpen: true,
      metadata: highestScoredSource,
    });
  }

  function onClose() {
    if (setKnowledgeCard) {
      setKnowledgeCard({
        isOpen: false,
        metadata: {} as knowledgeLinksType,
      });
    }
  }

  return (
    <>
      <StyledMarkdown
        sx={sx}
        components={{
          a(props) {
            const { children, node, ...rest } = props;
            const href = props.href;
            if (href?.includes('sourceId=')) {
              return (
                <ButtonBase
                  sx={{
                    textDecoration: 'underline',
                    pb: 1,
                    color: (theme) =>
                      alpha(theme.palette.brand.lightBlue.main, 0.75),
                    '&:hover': {
                      color: (theme) =>
                        alpha(theme.palette.brand.lightBlue.main, 1),
                    },
                  }}
                  onClick={() => handleKnowledgeCardClick(href)}
                >
                  {t('components.markdown.sourceLink', {
                    defaultValue: 'knowledge source', // todo: add translation
                  })}
                </ButtonBase>
              );
            }
            return (
              <a
                href={href}
                {...rest}
                target="_blank"
                rel="noopener noreferrer"
              >
                {children}
              </a>
            );
          },
        }}
        {...rest}
      >
        {children?.toString()}
      </StyledMarkdown>
      {knowledgeCard && (
        <KnowledgeInfoCard
          isOpen={knowledgeCard.isOpen}
          onClose={onClose}
          metadata={knowledgeCard.metadata}
        />
      )}
    </>
  );
}
