import {
  StyledTab,
  StyledTabList,
} from '@components/plans/genius-search-tab/styles';
import { GeniusSearchPlanType } from '@components/plans/types';

type Props = {
  geniusSearchPlan: GeniusSearchPlanType;
  togglePlan: (
    _event: React.SyntheticEvent,
    value: GeniusSearchPlanType,
  ) => void;
};

export default function GeniusSearchTab({
  geniusSearchPlan,
  togglePlan,
}: Props) {
  return (
    <StyledTabList
      TabIndicatorProps={{
        style: {
          bottom: '50%',
        },
        sx: {
          height: 'calc(100% - 5px)',
          transform: 'translateY(50%)',
          backgroundColor: 'background.card.light',
          borderRadius: 100,
        },
      }}
      value={geniusSearchPlan}
      onChange={togglePlan}
    >
      <StyledTab
        label="Monthly"
        value="monthly"
        selected={geniusSearchPlan === 'monthly'}
      />
      <StyledTab
        label="Yearly"
        value="yearly"
        selected={geniusSearchPlan === 'yearly'}
      />
    </StyledTabList>
  );
}
