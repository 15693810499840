import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import CONFIG_TOOLBAR from '@layouts/main/sections/toolbar/config';

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.card.light,
  position: 'sticky',
  bottom: CONFIG_TOOLBAR.TOOLBAR_MINI.MOBILE.HEIGHT,
  left: 0,
  right: 0,
  borderRadius: 0,
  padding: theme.spacing(4.5),
}));

export default StyledBox;
