/* eslint-disable no-case-declarations */
import SocketFactory, { SocketInterface } from '@lib/socketFactory';
import { StreamingType as AssistantStreamingType } from '@shared-types/assistants';
import {
  StreamingType as SearchResultStreamingType,
  StreamingUpdateType,
} from '@shared-types/search-result/streaming';
import { WSEvents, WSResponseType } from '@state/middleware/types';
import {
  handleIncomingData,
  handleTextToSpeech,
} from '@state/middleware/utils/textToSpeechHandler';
import { reset as userReset } from '@state/slices/assistant-result';
import {
  currentAudioPlayingIdUpdated,
  WSTextToSpeechLoaded,
  WSTTSMessageStatusUpdated,
  knowledgeLinksUpdated,
} from '@state/slices/search-result';
import {
  reset,
  WSConnected,
  WSDisconnected,
  WSErrored,
  WSLoaded,
  WSMessageStatusUpdated,
  WSResponseUpdated,
} from '@state/slices/websocket';
import { produce } from 'immer';
import { throttle } from 'lodash';
import { Action, Middleware } from 'redux';

const THROTTLED_INTERVAL_IN_MS = 170;
export let socketInstance: SocketInterface;

const initialSearchResult = {
  smartLoading: {
    type: null,
    stream_counter: 0,
    composed: [],
    response: null,
  },
  layout: {
    photo: {
      type: 'photos',
      id: '',
      query: '',
      photos: [],
      isLoading: null,
    },
    infos: {
      infosData: [],
      isLoading: null,
    },
    answer: {
      type: 'answer',
      composed: [],
      user_query: '',
      text: '',
      search_query: '',
      id: '',
      isLoading: null,
    },
    link: {
      id: '',
      type: 'links',
      links: [],
      isLoading: null,
    },
  },
  followups: {
    followupsData: [],
    isLoading: true,
  },
  TOS: null,
};

const socketMiddleware: Middleware = ({ dispatch, getState }) => {
  //TODO: currentWSResponse should be reset for assistant result as well
  let currentWSResponse = {
    searchResult: initialSearchResult,
    assistant: {
      composed: [],
      text: '',
    },
  };

  const throttledDispatch = throttle((websocketResult) => {
    dispatch(WSResponseUpdated(websocketResult));
  }, THROTTLED_INTERVAL_IN_MS);

  return (next) => (action) => {
    const { type } = action as Action<'socket/init' | 'socket/disconnect'>;
    switch (type) {
      case 'socket/init':
        if (socketInstance) return;

        socketInstance = SocketFactory.create();
        socketInstance.socket.connect();

        socketInstance.socket.on(WSEvents.CONNECTED, () => {
          dispatch(WSConnected());
        });

        socketInstance.socket.on(WSEvents.ERROR, (message) => {
          console.error('🚫Socket Error🚫', message);
          dispatch(WSErrored(message));
        });

        socketInstance.socket.on(WSEvents.MESSAGE, (message) => {
          currentWSResponse = transformMessage(message, currentWSResponse); //TODO: type of currentWSResponse will be fixed after understanding the message kind

          if (message.type === 'status' && message.response === 'started') {
            dispatch(WSMessageStatusUpdated('started'));
          }

          if (message.componentType === 'links') {
            dispatch(knowledgeLinksUpdated(message.links));
          }

          if (message.type === 'status' && message.response === 'finished') {
            dispatch(WSResponseUpdated(currentWSResponse)); //? making sure the last message is dispatched since the throttling might cause the last message to be missed
            dispatch({ type: 'searchResult/streaming/finished' });
            dispatch({ type: 'assistantResult/streaming/finished' });
            throttledDispatch.cancel();

            //! later on we need to refactor this to store the data by threadId
            setTimeout(() => {
              dispatch(reset());
              dispatch(userReset());
              currentWSResponse = {
                searchResult: initialSearchResult,
                assistant: {
                  composed: [],
                  text: '',
                },
              };
              // dispatch(WSMessageStatusUpdated('finished'));
            });
          }

          if (message.type === 'tts-start') {
            const messageId = message.id;
            dispatch(WSTextToSpeechLoaded(false));
            handleTextToSpeech({ id: messageId, dispatch, state: getState() });
            dispatch(WSTTSMessageStatusUpdated('tts-start'));
            dispatch(currentAudioPlayingIdUpdated(messageId));
          }

          if (message.type === 'tts') {
            handleIncomingData({ data: message.buffer, id: message.id });
            dispatch(WSTTSMessageStatusUpdated('tts'));
          }

          if (message.type === 'tts-end') {
            //? make audio btn enabled
            dispatch(WSTTSMessageStatusUpdated('tts-end'));
          }

          if (socketInstance.socketMessageType === 'assistant') {
            dispatch(WSMessageStatusUpdated('started'));
            dispatch(WSLoaded(false));
          }

          //? postpone the WSLoaded action until the layout is received
          if (
            message.type === 'starting-info' ||
            message.type === 'starting-answer' ||
            message.type === 'starting-photos'
          ) {
            dispatch(WSLoaded(false));
          }

          throttledDispatch(currentWSResponse);
        });
        break;
      case 'socket/disconnect':
        socketInstance.socket.on(WSEvents.DISCONNECTED, (reason) => {
          console.error('🚫Socket Disconnected🚫', reason);
          dispatch(WSDisconnected());
        });
        break;
    }

    next(action);
  };
};

export default socketMiddleware;

function transformMessage(
  message: SearchResultStreamingType | AssistantStreamingType,
  currentWSResponse: WSResponseType,
) {
  if ('type' in message) {
    //? it is a SearchResultStreamingType
    return produce(currentWSResponse, (draft) => {
      switch (message.type) {
        case 'loading-update':
          const smartLoadingElement = draft.searchResult.smartLoading;
          if (typeof message.stream_counter !== 'number') return;
          smartLoadingElement!.composed[message.stream_counter] =
            message.response!;
          smartLoadingElement!.response =
            smartLoadingElement!.composed.join('');
          break;
        case 'layout':
          if (message.layout.answer) {
            draft.searchResult.layout.answer = {
              type: 'answer',
              ...message.layout.answer,
              text: '',
              composed: [],
              isLoading: true,
            };
          }
          if (message.layout.info && message.layout.info.length > 0) {
            draft.searchResult.layout.infos = {
              infosData: [],
              isLoading: true,
            };
            draft.searchResult.layout.infos.infosData.push({
              text: '',
              ...message.layout.info[0],
              composed: [],
              type: 'info',
            });
          }
          if (message.layout.linkId) {
            draft.searchResult.layout.link = {
              id: message.layout.linkId,
              links: [],
              type: 'links',
              isLoading: true,
            };
          }
          if (message.layout.photos) {
            draft.searchResult.layout.photo = {
              ...message.layout.photos,
              photos: [],
              type: 'photos',
              isLoading: true,
            };
          }
          break;
        case 'followup':
          draft.searchResult.followups = {
            followupsData: message.followups,
            isLoading: false,
          };
          break;
        case 'update':
          return updateLayoutResponse(message, currentWSResponse);
        case 'tos':
          draft.searchResult.TOS = message.response;
          break;
        default:
          return draft;
      }
    });
  }

  //? it is an AssistantStreamingType
  if (!('type' in message)) {
    return produce(currentWSResponse, (draft) => {
      if (typeof message.stream_counter !== 'number') return;
      draft.assistant.composed[message.stream_counter] = message.text;
      draft.assistant.text = draft.assistant.composed.join('');
      return draft;
    });
  }
}

function updateLayoutResponse(
  message: StreamingUpdateType,
  currWSResponse: WSResponseType,
) {
  return produce(currWSResponse, (draft) => {
    switch (message.componentType) {
      case 'photos':
        draft.searchResult.layout.photo = {
          type: message.componentType,
          photos: message.photos,
          id: message.id,
          query: message.query,
          isLoading: false,
        };
        break;
      case 'answer':
        const answerElement = draft.searchResult.layout.answer;
        if (typeof message.stream_counter !== 'number') return;
        answerElement!.composed[message.stream_counter] = message.text;
        answerElement!.text = answerElement!.composed.join('');
        answerElement!.isLoading = false;
        break;
      case 'info':
        const infoElement = draft.searchResult.layout.infos?.infosData.find(
          (element) => element?.id === message.id,
        );

        if (infoElement) {
          if (typeof message.stream_counter !== 'number') return;
          draft.searchResult.layout.infos!.isLoading = false;
          infoElement.composed[message.stream_counter] = message.text;
          infoElement.text = infoElement.composed.join('');
        }
        break;
      case 'links':
        draft.searchResult.layout.link = {
          id: message.id,
          links: message.links,
          type: message.componentType,
          isLoading: false,
        };
        break;
      default:
        return draft;
    }
  });
}
