import { styled, TabProps } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

type StyledTabProps = TabProps & {
  label: string;
  value: string;
  selected?: boolean;
};

export const StyledTab = styled(({ ...otherProps }: StyledTabProps) => (
  <Tab disableRipple {...otherProps} />
))(({ theme, selected }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: theme.typography.pxToRem(16),
  backgroundColor: 'none',
  border: 'none',
  '&.Mui-selected': {
    color: theme.palette.text.primary,
  },
  position: selected ? 'relative' : 'static',
  zIndex: selected ? 1 : 0,
  marginInline: theme.spacing(1),
  padding: 0,
  height: theme.spacing(8),
  minHeight: theme.spacing(8),
}));

export const StyledTabList = styled(Tabs)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.warning.main,
  minHeight: theme.spacing(8.75),
  height: theme.spacing(8.75),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.spacing(30),
}));
