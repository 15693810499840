import CONFIG_TOOLBAR from '@layouts/main/sections/toolbar/config';
import { StyledGlobalToolbar } from '@layouts/main/sections/toolbar/styles';
import { styled } from '@mui/material';
import List from '@mui/material/List';

export const StyledMobileToolbar = styled(StyledGlobalToolbar)(({ theme }) => ({
  zIndex: theme.zIndex.appBar,
  bottom: 0,
  left: '50%',
  position: 'fixed',
  transform: 'translateX(-50%)',
  background: theme.palette.background.card.light,
  width: '100%',
  height: CONFIG_TOOLBAR.TOOLBAR_MINI.MOBILE.HEIGHT,
  boxShadow: theme.customShadows.dropdown,
}));

export const StyledList = styled(List)(() => ({
  justifyContent: 'space-around',
  alignItems: 'center',
  height: '100%',
})) as typeof List;
