import sendCreditsPurchasedToAnalytics from '@analytics/sendCreditsPurchasedToAnalytics';
import PLANS from '@components/plans/config';
import FormProvider from '@components/react-hook-form/FormProvider';
import RHFTextField from '@components/react-hook-form/RHFTextField';
import FormHeading from '@components/user-authentication/components/form-heading';
import useLookupUserEmail from '@components/user-authentication/hooks/useLookupEmail';
import useUserAuth from '@components/user-authentication/hooks/useUserAuth';
import { enterEmailSchema } from '@components/user-authentication/schemas';
import StyledButton from '@components/user-authentication/sections/steps/enter-email/styles';
import { useTranslation } from '@desygner/ui-common-translation';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEnabledAccessConfigurations } from '@hooks/useAccessConfigurations';
import useAuth from '@hooks/useAuth';
import useQueryParams from '@hooks/useQueryParams';
import useResponsive from '@hooks/useResponsive';
import useWorkspace from '@hooks/useWorkspace';
import serviceBranding from '@lib/auth/serviceBranding';
import { getDecodedToken, setSession } from '@lib/jwt';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import { StripePaymentStatusType } from '@shared-types/payment';
import { AccessConfigurationMinType } from '@shared-types/sso';
import { isAxiosError } from 'axios';
import { jwtDecode } from 'jwt-decode';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import EllipsisTypography from '@components/ellipsis-typography';
import useGetSubscriptionLazily from '@components/user-authentication/hooks/useGetSubscription';

type EnterEmailFormType = {
  email: string;
};

const authUrl = import.meta.env.VITE_KNOWZ_AUTH_URL || '';

const CODE_VERIFICATION_STEP = 1;

export default function EnterEmail() {
  const { t } = useTranslation();

  const isItMobileOrTablet = useResponsive('down', 'md');

  const {
    handleIsUserAlreadyRegistered,
    handleSetEmail,
    handleNextStep,
    handleCustomStep,
    handleAuthenticationJourney,
    resetForm,
  } = useUserAuth();

  const { accessConfigurationsData, isLoadingAccessConfigurations } =
    useEnabledAccessConfigurations();

  const { attemptedToLogin, handleAttemptedToLogin, handleUpdateMe } =
    useAuth();

  const token = window.localStorage.getItem('token');

  const decodedToken = getDecodedToken(token);

  const { resetWorkspaceToIndividualRole } = useWorkspace();

  const { getQueryParamByKey, removeAllQueryParamsNoMatterWhat } =
    useQueryParams();

  const subscriptionQueryParam =
    getQueryParamByKey<StripePaymentStatusType>('subscription');

  const paymentQueryParam =
    getQueryParamByKey<StripePaymentStatusType>('payment');

  const priceIdQueryParam = getQueryParamByKey<string | null>('price_id');

  const { data: subscriptionData } =
    useGetSubscriptionLazily(priceIdQueryParam);

  const invitationToken = getQueryParamByKey<string | null>('token');

  const decodedInvitationToken = invitationToken
    ? jwtDecode<{
        membership_id: string;
        email_invite: string;
      }>(invitationToken)
    : null;

  const shouldPreFillEmailForPaidUsers =
    subscriptionQueryParam === 'success' && decodedToken?.paid;

  const shouldPreFillEmailForExpiredToken =
    decodedToken?.auth_type === 'email' && !attemptedToLogin;

  const shouldPreFillEmailForWorkspaceInvitation = !!decodedInvitationToken;

  const defaultValues = {
    email:
      !shouldPreFillEmailForWorkspaceInvitation &&
      (shouldPreFillEmailForExpiredToken || shouldPreFillEmailForPaidUsers)
        ? decodedToken?.email
        : shouldPreFillEmailForWorkspaceInvitation
        ? decodedInvitationToken?.email_invite
        : '',
  };

  const methods = useForm<EnterEmailFormType>({
    resolver: yupResolver(enterEmailSchema),
    defaultValues,
  });

  const closeModal = useCallback(() => {
    removeAllQueryParamsNoMatterWhat();
    resetForm();
    handleAttemptedToLogin(true);
    resetWorkspaceToIndividualRole();
  }, [
    handleAttemptedToLogin,
    removeAllQueryParamsNoMatterWhat,
    resetForm,
    resetWorkspaceToIndividualRole,
  ]);

  const [ssoWindow, setSsoWindow] = useState<Window | null>(null);

  const onMessage = useCallback(
    (event: MessageEvent) => {
      if (event.origin === authUrl) {
        const { accessJwt, accessError } = event.data;
        if (!accessJwt && !accessError) return;

        ssoWindow && ssoWindow.close();
        window.removeEventListener('message', onMessage);
        setSsoWindow(null);

        if (accessError) return toast.error(accessError);
        setSession(accessJwt);
        handleUpdateMe(accessJwt);
        closeModal();
      }
    },
    [ssoWindow, handleUpdateMe, closeModal],
  );

  useEffect(() => {
    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onMessage]);

  useEffect(() => {
    //? check to see if user coming from a payment page
    if (priceIdQueryParam == null) return;

    const wasItSuccessful =
      subscriptionQueryParam === 'success' || paymentQueryParam === 'success';
    const associatedPlan = PLANS[priceIdQueryParam];

    if (decodedToken == null) return;

    if (associatedPlan && subscriptionData) {
      sendCreditsPurchasedToAnalytics({
        userUUID: decodedToken.uuid,
        transactionId: subscriptionData.externalId,
        currency: subscriptionData.currency,
        status: wasItSuccessful ? 'success' : 'abort',
        item: {
          price: associatedPlan.price,
          plan: associatedPlan.title,
          id: subscriptionData.id,
        },
      });
    }

    if (decodedToken.auth_type !== 'web_fingerprint') return;
    handleAuthenticationJourney('register');
    handleCustomStep(CODE_VERIFICATION_STEP);
    handleSetEmail(decodedToken.email);
  }, [subscriptionData]);

  const onSsoLogin = useCallback(
    (alias: string) => {
      ssoWindow && ssoWindow.close();
      setSsoWindow(
        window.open(
          `${authUrl}/connect/oauth2?alias=${alias}&process=access`,
          'Knowz Authentication',
          'menubar=1,resizable=1,width=900,height=550',
        ),
      );
    },
    [ssoWindow],
  );

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
  } = methods;

  const {
    mutateAsync: lookupUserEmailAsync,
    isPending: isLookupUserEmailPending,
  } = useLookupUserEmail();

  const isBusy = isSubmitting || isLookupUserEmailPending;

  const isThereAnySSOConfig =
    !isLoadingAccessConfigurations &&
    accessConfigurationsData &&
    accessConfigurationsData.data.length > 0;

  async function onSubmit(data: EnterEmailFormType) {
    try {
      const lookupEmailResponse = await lookupUserEmailAsync(data);
      if (
        lookupEmailResponse.status === 226 &&
        lookupEmailResponse.data.status === 'Im Used'
      ) {
        handleSetEmail(data.email);
        handleIsUserAlreadyRegistered(true);
        handleNextStep();
        handleAuthenticationJourney('login');
        return;
      }
    } catch (error) {
      if (isAxiosError(error) && error.response?.status === 404) {
        handleSetEmail(data.email);
        handleIsUserAlreadyRegistered(false);
        handleNextStep();
        handleAuthenticationJourney('register');
        return;
      }
      toast.error(
        t('response.errors.code.520', {
          defaultValue: 'Something went wrong. Please try again later.',
        }),
      );
    }
  }

  return (
    <Stack justifyContent="space-between" sx={{ height: '100%' }}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack gap={2} direction="row" sx={{ alignItems: 'center' }}>
          {isItMobileOrTablet && (
            <IconButton
              sx={{
                paddingTop: 12,
                paddingBottom: 8,
              }}
              onClick={closeModal}
            >
              <ArrowBackIosIcon />
            </IconButton>
          )}
          <FormHeading
            heading={t('layout.auth.desktop.modal.steps.enterEmail.label', {
              defaultValue: 'Get started with email',
            })}
          />
        </Stack>
        <Stack gap={5}>
          <Typography variant="body2">
            {t('layout.auth.desktop.modal.steps.enterEmail.description', {
              defaultValue:
                'We’ll check if you have an account, and help create one if you don’t.',
            })}
          </Typography>
          <RHFTextField
            type="email"
            size="small"
            disabled={isBusy}
            placeholder={t(
              'layout.auth.desktop.modal.steps.enterEmail.form.email.placeholder',
              {
                defaultValue: 'email',
              },
            )}
            fullWidth
            name="email"
            autoComplete="username" //? https://www.chromium.org/developers/design-documents/form-styles-that-chromium-understands/
            error={!!errors.email?.message}
            helperText={errors.email?.message}
          />
        </Stack>
        <Stack gap={2} sx={{ marginBlockStart: 5 }}>
          <LoadingButton
            onClick={handleSubmit(onSubmit)}
            disabled={isBusy} //TODO: just remove isDirty since it does not work with defaultValue properly later we find a workaround
            loading={isBusy}
            variant="contained"
            fullWidth
          >
            {t('layout.auth.desktop.modal.steps.enterEmail.form.button', {
              defaultValue: 'Continue',
            })}
          </LoadingButton>

          {isThereAnySSOConfig && (
            <>
              <Typography variant="body2" align="center">
                {t('layout.auth.desktop.modal.steps.enterEmail.form.or', {
                  defaultValue: 'or',
                })}
              </Typography>
              <Grid container spacing={4} sx={{ justifyContent: 'center' }}>
                {accessConfigurationsData.data.map(
                  (
                    { provider, alias, name }: AccessConfigurationMinType,
                    i,
                  ) => (
                    <Grid size={6} key={`${name}-${i}`}>
                      <LoadingButton
                        onClick={() => onSsoLogin(alias)}
                        variant="contained"
                        fullWidth
                      >
                        <Stack gap={2} direction="row">
                          <img
                            src={serviceBranding[provider || 'custom'].icon}
                            alt={name}
                            loading="lazy"
                            height={20}
                          />
                          <EllipsisTypography
                            title={name}
                            lineClamp={1}
                            variant="caption"
                          >
                            Login with {name}
                          </EllipsisTypography>
                        </Stack>
                      </LoadingButton>
                    </Grid>
                  ),
                )}
              </Grid>
            </>
          )}
        </Stack>
      </FormProvider>
      <Box>
        <StyledButton onClick={closeModal}>
          {t('layout.auth.desktop.modal.steps.enterEmail.anon', {
            defaultValue: 'Continue without an account',
          })}
        </StyledButton>
      </Box>
    </Stack>
  );
}
