import useTimer from '@hooks/useTimer';
import { queryClient } from '@providers/ReactQueryProvider';
import useAppDispatch from '@hooks/useAppDispatch';

export default function useTranscriptionService() {
  const dispatch = useAppDispatch();
  // const { startSpeechToText, stopSpeechToText } = useSpeechToText({
  //   continuous: true,
  //   useLegacyResults: false,
  // });

  // const { startRecording, stopRecording } = useMediaRecorder({
  //   blobOptions: {
  //     type: 'audio/wav',
  //   },
  //   recordScreen: false,
  //   mediaStreamConstraints: {
  //     audio: true,
  //     video: false,
  //   },
  // });
  const timer = useTimer();

  function startTranscript() {
    queryClient.invalidateQueries({ queryKey: ['credits'] });
    // startSpeechToText();
    timer.startTimer();
    // startRecording();
  }

  function stopTranscript() {
    // stopSpeechToText();
    timer.stopTimer();
    // stopRecording();
  }

  return {
    startTranscript,
    stopTranscript,
  };
}
