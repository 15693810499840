import { KnowzStepDefinitionType } from '@lib/step/types';

const TransientStepDefinition: KnowzStepDefinitionType = {
  order: 201,
  group: 'AI',
  step: {
    componentType: 'task',
    type: 'transient',
    name: 'AI Transient',
  },
  contextTypes: [
    'ref-json',
    'ref-string',
    'ref-source',
    'ref-thread',
    'ref-history-block',
  ],
  propertyTypes: [
    {
      id: 'prompt',
      name: 'Smart prompt',
      description:
        'Describe the task to the AI model, inlining the inputs you defined',
      usage: 'definition',
      wrapper: 'item',
      types: ['string'],
    },
    {
      id: 'output',
      name: 'Result reference',
      description:
        'Assign a name to the resulting text, for later referencing in your command',
      usage: 'output',
      wrapper: 'item',
      types: ['ref-string'],
    },
  ],
};

export default TransientStepDefinition;
