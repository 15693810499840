import StyledChip from '@components/pages/side-kick-session/sections/content/components/feed/list-item/summary/styles';
import calculateFactCheckColor from '@components/pages/side-kick-session/utils/calculateFactCheckColor';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from '@mui/material/AccordionSummary';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { FactCheckResultType, TimeType } from '@shared-types/sidekick';

type Props = {
  outcome: FactCheckResultType[number]['outcome'];
  avatar: string;
  name: string;
  time: TimeType;
};

const AVATAR_SIZE = 24;

export default function Summary({ outcome, avatar, time, name }: Props) {
  return (
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Avatar src={avatar} sx={{ width: AVATAR_SIZE, height: AVATAR_SIZE }} />
      <Typography variant="body1" color="text.secondary" sx={{ ml: 2 }}>
        {name}
      </Typography>
      <Typography
        variant="caption"
        color="text.disabled"
        component="time"
        sx={{ ml: 2 }}
      >
        {time.minutes}:{time.seconds}
      </Typography>
      <StyledChip
        label={outcome}
        size="small"
        variant="outlined"
        color={calculateFactCheckColor(outcome)}
      />
    </AccordionSummary>
  );
}
