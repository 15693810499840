import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import { BagItemType, PropertyType } from '@lib/step/types';

export interface InputBlockProps extends PropertyType {
  value: BagItem | BagItem[]; // TODO
  onChange?: (value: BagItem | BagItem[]) => void;
}

export type PropertyData = string | number;
export type BagItem = {
  type: BagItemType;
  data?: PropertyData;
};

const StyledInputWrapper = styled('div')`
  padding: ${({ theme }) => theme.spacing(2)} 0
    ${({ theme }) => theme.spacing(3)} 0;
`;

export function InputBlockWithContext({
  name,
  description,
  children,
}: {
  name?: string;
  description?: string;
  children: React.ReactNode;
}) {
  return (
    <StyledInputWrapper>
      {name && (
        <Typography sx={{ mb: 2 }} variant="body1">
          {name}
        </Typography>
      )}
      {children}
      {description && (
        <Typography color="GrayText" variant="caption">
          {description}
        </Typography>
      )}
    </StyledInputWrapper>
  );
}
