import { commands } from '@lib/agent';
import SocketFactory, { SocketInterface } from '@lib/socketFactory';
import {
  createAsyncThunk,
  createListenerMiddleware,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { CommandTriggerType } from '@shared-types/agent';
import { WSEvents } from '@state/middleware/types';
import { RootType } from '@state/store';

let socketInstance: SocketInterface;
type RunnerStatusType = 'idle' | 'triggering' | 'running' | 'done';
type EventType = {
  type: string;
  error?: string;
  state?: string[];
  dataBag?: any[];
};

export type StateType = {
  error: string | null;
  runToken: string | null;
  commandId: string | number | null;
  trigger: CommandTriggerType | null;
  runnerStatus: RunnerStatusType;

  isAutoPlay: boolean;
  autoPlayDelay: number;
  autoPlayTimeout: number | null;

  events: EventType[];
  currentEvent: EventType | null;
  currentEventIndex: number;
  isLastStep: boolean;
  isFirstStep: boolean;
};

export type RunCommandType = {
  commandId: string | number | null;
  trigger: CommandTriggerType | null;
};

const initialState: StateType = {
  currentEvent: null,
  error: null,
  commandId: null,
  trigger: null,
  runnerStatus: 'idle',
  runToken: null,
  isAutoPlay: true,
  events: [],
  autoPlayDelay: 1200,
  currentEventIndex: -1,
  autoPlayTimeout: null,
  isLastStep: true,
  isFirstStep: true,
};

const flowRun = createSlice({
  name: 'flowRun',
  initialState,
  reducers: {
    runCommand(state, action: PayloadAction<RunCommandType>) {
      const { commandId, trigger } = action.payload;

      Object.assign(state, {
        ...initialState,
        commandId,
        trigger,
        runnerStatus: 'triggering',
      });
    },

    setIsAutoPlay(state, action: PayloadAction<boolean>) {
      state.isAutoPlay = action.payload;

      if (!state.isAutoPlay) {
        flowRun.caseReducers.clearTimeout(state);
      }
    },

    setAutoPlayDelay(state, action: PayloadAction<number>) {
      state.autoPlayDelay = action.payload;
    },

    increaseStep(state) {
      if (state.currentEventIndex < state.events.length - 1) {
        state.currentEventIndex += 1;
        state.isFirstStep = state.currentEventIndex === 0;
        state.isLastStep = state.currentEventIndex === state.events.length - 1;
        flowRun.caseReducers.clearTimeout(state);
        flowRun.caseReducers.setCurrentEvent(state);
      } else {
        // Throw exception?
      }
    },

    decreaseStep(state) {
      if (state.currentEventIndex > 0) {
        state.currentEventIndex -= 1;
        state.isFirstStep = state.currentEventIndex === 0;
        state.isLastStep = state.currentEventIndex === state.events.length - 1;
        flowRun.caseReducers.setCurrentEvent(state);
      } else {
        // Throw exception?
      }
    },

    closeRun(state) {
      state.runnerStatus = 'idle';

      if (state.runToken) {
        socketInstance.socket.emit('unsubscribe', `flow_run_${state.runToken}`);
        state.runToken = null;
      }
    },

    // Internal
    addEvent(state, action: PayloadAction<EventType>) {
      state.events = [...state.events, action.payload];
    },

    setError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
    },

    setAutoPlayTimeout(state, action: PayloadAction<number | null>) {
      state.autoPlayTimeout = action.payload;
    },

    setRunToken(state, action: PayloadAction<string | null>) {
      state.runToken = action.payload;
    },

    setCurrentEvent(state) {
      state.currentEvent = state.events[state.currentEventIndex];

      if (
        state.currentEvent.type === 'flow_start' &&
        state.runnerStatus === 'triggering'
      ) {
        state.runnerStatus = 'running';
      } else if (
        state.currentEvent.type === 'flow_end' &&
        state.runnerStatus === 'running'
      ) {
        state.runnerStatus = 'done';
      }

      if (state.currentEvent.error) {
        console.error(state.currentEvent.error);
        state.error = state.currentEvent.error;
      }
    },

    clearTimeout(state) {
      if (state.autoPlayTimeout) {
        clearTimeout(state.autoPlayTimeout);
        state.autoPlayTimeout = null;
      }
    },
  },
});

const triggerCommand = createAsyncThunk(
  'flowRun/triggerCommand',
  async ({ commandId, trigger }: RunCommandType, { getState, dispatch }) => {
    const {
      flowRun: { runToken: previousRunToken },
    } = getState() as RootType;
    const {
      data: { runToken },
    } = await commands.subscribe(commandId!);

    if (previousRunToken) {
      socketInstance.socket.emit('unsubscribe', `flow_run_${previousRunToken}`);
    }

    socketInstance.socket.emit('subscribe', `flow_run_${runToken}`);
    dispatch(flowRun.actions.setRunToken(runToken));

    await commands.trigger(commandId!, {
      runToken,
      ...trigger!,
    });
  },
);

export const tickListener = createListenerMiddleware();
tickListener.startListening({
  predicate: ({ type }) =>
    type === flowRun.actions.setIsAutoPlay.type ||
    type === flowRun.actions.addEvent.type ||
    type === flowRun.actions.increaseStep.type,
  effect: async (_, { getState, dispatch }) => {
    const {
      flowRun: { isAutoPlay, autoPlayDelay, autoPlayTimeout },
    } = getState() as RootType;

    if (isAutoPlay && !autoPlayTimeout) {
      dispatch(
        flowRun.actions.setAutoPlayTimeout(
          window.setTimeout(() => {
            dispatch(flowRun.actions.increaseStep());
          }, autoPlayDelay),
        ),
      );
    }
  },
});

export const onRunCommandListener = createListenerMiddleware();
onRunCommandListener.startListening({
  predicate: ({ type }) => type === flowRun.actions.runCommand.type,
  effect: async (_, { getState, dispatch }) => {
    const {
      flowRun: { commandId, trigger },
    } = getState() as RootType;

    if (!socketInstance) {
      socketInstance = SocketFactory.create();

      socketInstance.socket.on(WSEvents.ERROR, (message) => {
        console.error('🚫Socket Error🚫', message);
        dispatch(flowRun.actions.setError(message));
      });

      socketInstance.socket.on(WSEvents.MESSAGE, (event) => {
        dispatch(flowRun.actions.addEvent(event));
      });
    }

    dispatch(
      triggerCommand({
        commandId: commandId!,
        trigger,
      }),
    );
  },
});

export const {
  runCommand,
  setIsAutoPlay,
  setAutoPlayDelay,
  increaseStep,
  decreaseStep,
  closeRun,
} = flowRun.actions;

export default flowRun.reducer;
