import SimpleDropdown from '@components/dropdown/simple';
import EllipsisTypography from '@components/ellipsis-typography';
import AudioWave from '@components/pages/side-kick-session/recorder/audio-wave';
import StyledButton from '@components/pages/side-kick-session/recorder/desktop/styles';
import Timer from '@components/pages/side-kick-session/recorder/LiveTimer';
import { useTranslation } from '@desygner/ui-common-translation';
import useTranscriptionService from '@hooks/useTranscriptionService';
import { useMediaDeviceSelect } from '@livekit/components-react';
import AdjustOutlinedIcon from '@mui/icons-material/AdjustOutlined';
import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MicIcon from '@mui/icons-material/Mic';
import { Skeleton } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import paths from '@router/paths';
import { useConfirm } from 'material-ui-confirm';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Recorder() {
  const { stopTranscript } = useTranscriptionService();
  const { t } = useTranslation();
  const confirm = useConfirm();
  const navigateTo = useNavigate();
  const { devices, activeDeviceId, setActiveMediaDevice } =
    useMediaDeviceSelect({
      kind: 'audioinput',
    });

  async function handleStopRecording() {
    confirm({
      title: t('page.sideKickSession.confirmStopRecording.title', {
        defaultValue: 'Stop Recording Session',
      }),
      description: t('page.sideKickSession.confirmStopRecording.content', {
        defaultValue: 'Are you sure you want to stop recording?',
      }),
      confirmationText: t('page.sideKickSession.confirmStopRecording.yes', {
        defaultValue: 'Yes, I want to stop recording',
      }),
      cancellationText: t('page.sideKickSession.confirmStopRecording.no', {
        defaultValue: 'No, I want to continue',
      }),
    })
      .then(() => {
        stopTranscript();
        navigateTo(paths.sideKick.pathname);
      })
      .catch(() => null);
  }

  const inputDevice = useMemo(
    () =>
      devices.find((device) => device.deviceId === activeDeviceId)?.label ||
      'Default',
    [activeDeviceId, devices],
  );

  return (
    <Card>
      <Stack direction="row" gap={2.5} sx={{ alignItems: 'center' }}>
        <StyledButton
          color="error"
          onClick={handleStopRecording}
          disableRipple
          disableTouchRipple
        >
          <AdjustOutlinedIcon fontSize="large" />
        </StyledButton>
        <Typography variant="body1" sx={{ md: 'block', xs: 'none' }}>
          {t('page.sideKickSession.recorder.recording', {
            defaultValue: 'Recording',
          })}
        </Typography>
        <Timer />
        <SimpleDropdown
          disablePortal
          options={devices.map((device) => ({
            text: device.label,
            value: device.deviceId,
            onClick: () => setActiveMediaDevice(device.deviceId),
            icon: {
              iconStart: (
                <CheckIcon
                  fontSize="small"
                  sx={{
                    visibility:
                      activeDeviceId === device.deviceId ? 'visible' : 'hidden',
                  }}
                />
              ),
            },
          }))}
          placement="top"
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, 10],
              },
            },
          ]}
          trigger={{
            element: (
              <Button
                color="inherit"
                disableElevation
                disableFocusRipple
                disableRipple
                disableTouchRipple
                size="small"
                endIcon={<ExpandMoreIcon />}
                startIcon={<MicIcon />}
                sx={{
                  width: 150,
                }}
              >
                <EllipsisTypography
                  variant="caption"
                  lineClamp={1}
                  title={inputDevice}
                >
                  {inputDevice || <Skeleton height={20} width={30} />}
                </EllipsisTypography>
              </Button>
            ),
          }}
        />
        <AudioWave />
      </Stack>
    </Card>
  );
}
