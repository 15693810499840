import {
  TranscriptsType,
  TranscriptType,
  SentimentLabelsType,
  FactCheckLabelsType,
  FactCheckType,
} from '@shared-types/sidekick';
import { ActionMap } from '@shared-types/utils';
import { createContext, useCallback, useMemo, useReducer } from 'react';

type SideKickContextType = {
  transcripts: TranscriptsType | null;
  transcriptIndices: string[];
  feeds: {
    [id: string]: FactCheckType;
  } | null;
  transcriptsCounter: number;
  feedsCounter: number;
  handleSetTranscript: (args: {
    id: string;
    content: string;
    speaker: string;
    time: { seconds: string; minutes: string };
  }) => void;
  handleUpdateSentimentLabelsById: (args: {
    id: string;
    sentiments: SentimentLabelsType;
  }) => void;
  handleUpdateFactCheckLabelsById: (args: {
    id: string;
    factChecks: FactCheckLabelsType;
  }) => void;
  handleSetFeed: (args: { id: string; feed: FactCheckType }) => void;
};

type SideKickType = {
  transcripts: SideKickContextType['transcripts'];
  transcriptIndices: SideKickContextType['transcriptIndices'];
  feeds: SideKickContextType['feeds'];
  transcriptsCounter: SideKickContextType['transcriptsCounter'];
  feedsCounter: SideKickContextType['feedsCounter'];
};

enum Types {
  SET_TRANSCRIPT = 'SET_TRANSCRIPT',
  UPDATE_SENTIMENT_LABELS_BY_ID = 'UPDATE_SENTIMENT_LABELS_BY_ID',
  UPDATE_FACT_CHECK_LABELS_BY_ID = 'UPDATE_FACT_CHECK_LABELS_BY_ID',
  SET_FEED = 'SET_FEED',
}

type SideKickPayload = {
  [Types.SET_TRANSCRIPT]: {
    id: string;
    content: string;
    speaker: string;
    time: { minutes: string; seconds: string };
  };
  [Types.UPDATE_SENTIMENT_LABELS_BY_ID]: {
    id: string;
    sentiments: SentimentLabelsType;
  };
  [Types.UPDATE_FACT_CHECK_LABELS_BY_ID]: {
    id: string;
    factChecks: FactCheckLabelsType;
  };
  [Types.SET_FEED]: {
    id: string;
    feed: FactCheckType;
  };
};

export type SideKickAction =
  ActionMap<SideKickPayload>[keyof ActionMap<SideKickPayload>];

export const SideKickContext = createContext<SideKickContextType | null>(null);

const initialState: SideKickType = {
  transcripts: null,
  transcriptIndices: [],
  feeds: null,
  transcriptsCounter: 0,
  feedsCounter: 0,
};

export default function SideKickProvider({
  children,
}: React.PropsWithChildren) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleSetTranscript = useCallback(
    ({
      content,
      id,
      time,
      speaker,
    }: Pick<TranscriptType, 'content' | 'time' | 'id' | 'speaker'>) => {
      if (content == null) {
        return null;
      }

      dispatch({
        type: Types.SET_TRANSCRIPT,
        payload: {
          content,
          speaker,
          id,
          time: {
            minutes: time.minutes.padStart(2, '0'),
            seconds: time.seconds.padStart(2, '0'),
          },
        },
      });
    },
    [],
  );

  const handleUpdateSentimentLabelsById = useCallback(
    ({ id, sentiments }: { id: string; sentiments: SentimentLabelsType }) => {
      dispatch({
        type: Types.UPDATE_SENTIMENT_LABELS_BY_ID,
        payload: {
          id,
          sentiments,
        },
      });
    },
    [],
  );

  const handleUpdateFactCheckLabelsById = useCallback(
    ({ factChecks, id }: { factChecks: FactCheckLabelsType; id: string }) => {
      dispatch({
        type: Types.UPDATE_FACT_CHECK_LABELS_BY_ID,
        payload: {
          id,
          factChecks,
        },
      });
    },
    [],
  );

  const handleSetFeed = useCallback(
    ({ id, feed }: { id: string; feed: FactCheckType }) => {
      dispatch({
        type: Types.SET_FEED,
        payload: {
          id,
          feed,
        },
      });
    },
    [],
  );

  const memoizedValue = useMemo(
    () => ({
      ...state,
      handleSetTranscript,
      handleUpdateSentimentLabelsById,
      handleUpdateFactCheckLabelsById,
      handleSetFeed,
    }),
    [
      state,
      handleSetTranscript,
      handleUpdateSentimentLabelsById,
      handleUpdateFactCheckLabelsById,
      handleSetFeed,
    ],
  );

  return (
    <SideKickContext.Provider value={memoizedValue}>
      {children}
    </SideKickContext.Provider>
  );
}

function reducer(state: SideKickType, action: SideKickAction): SideKickType {
  switch (action.type) {
    case Types.SET_TRANSCRIPT: {
      const { content, id, time, speaker } = action.payload;
      return {
        ...state,
        transcriptIndices: [...state.transcriptIndices, id],
        transcriptsCounter: state.transcriptsCounter + 1,
        transcripts: {
          ...state.transcripts,
          [id]: {
            id,
            content,
            time,
            speaker,
            labels: {
              sentiments: null,
              factChecks: null,
            },
          },
        },
      };
    }
    case Types.SET_FEED: {
      const { id, feed } = action.payload;
      return {
        ...state,
        feedsCounter: state.feedsCounter + 1,
        feeds: {
          ...state.feeds,
          [id]: feed,
        },
      };
    }
    case Types.UPDATE_SENTIMENT_LABELS_BY_ID: {
      const { id, sentiments } = action.payload;
      if (state.transcripts === null) {
        return state;
      }

      if (sentiments === null) {
        return {
          ...state,
          transcripts: {
            ...state.transcripts,
            [id]: {
              ...state.transcripts[id],
              labels: {
                ...state.transcripts[id].labels,
                sentiments: null,
              },
            },
          },
        };
      }

      const sentimentLabelsKeeper: SentimentLabelsType = [];

      sentiments.forEach(({ classification, sentiment }) => {
        sentimentLabelsKeeper.push({
          classification,
          sentiment,
        });
      });

      return {
        ...state,
        transcripts: {
          ...state.transcripts,
          [id]: {
            ...state.transcripts[id],
            labels: {
              ...state.transcripts[id].labels,
              sentiments: sentimentLabelsKeeper,
            },
          },
        },
      };
    }
    case Types.UPDATE_FACT_CHECK_LABELS_BY_ID: {
      const { id, factChecks } = action.payload;
      if (state.transcripts === null) {
        return state;
      }

      if (factChecks === null) {
        return {
          ...state,
          transcripts: {
            ...state.transcripts,
            [id]: {
              ...state.transcripts[id],
              labels: {
                ...state.transcripts[id].labels,
                factChecks: null,
              },
            },
          },
        };
      }

      return {
        ...state,
        transcripts: {
          ...state.transcripts,
          [id]: {
            ...state.transcripts[id],
            labels: {
              ...state.transcripts[id].labels,
              factChecks,
            },
          },
        },
      };
    }
    default:
      return state;
  }
}
