import ListboxComponent from '@components/autocomplete-with-carousel/listbox';
import { useNavigate, generatePath } from 'react-router-dom';
import paths from '@router/paths';
import { StyledStack } from '@components/pages/commands/styles';
import { useTranslation } from '@desygner/ui-common-translation';
import useResponsive from '@hooks/useResponsive';
import SwitchAccessShortcutAddIcon from '@mui/icons-material/SwitchAccessShortcutAdd';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { CommandMinType } from '@shared-types/agent';
import {
  useCreateOneCommand,
  useGetAllCommands,
  useUpdateOneCommand,
  useDeleteOneCommand,
} from '@hooks/useCommands';
import { useConfirm } from 'material-ui-confirm';
import CommandListItem from '@components/pages/commands/components/CommandListItem';

const IMAGE_SIZE_WIDTH = 300;
const IMAGE_SIZE_HEIGHT = 200;

export default function Commands() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [commandForDeletion, setCommandForDeletion] =
    useState<CommandMinType | null>(null);
  const { mutateAsync: mutateDeleteOneCommandAsync } = useDeleteOneCommand();
  const { mutateAsync: mutateUpdateOneCommandAsync } = useUpdateOneCommand();
  const { mutateAsync: mutateCreateOneCommandAsync } = useCreateOneCommand();
  const { commandsData, isLoadingCommands } = useGetAllCommands();
  const isItMobileOrTablet = useResponsive('down', 'md');
  const data = isLoadingCommands ? [] : commandsData?.data || [];
  const [filter, setFilter] = useState('');
  const confirm = useConfirm();

  useEffect(() => {
    if (commandForDeletion) {
      (function () {
        confirm({
          title: t('page.commands.deleteConfirmation.title', {
            defaultValue: 'Delete Command',
          }),
          description: t('page.commands.deleteConfirmation.content', {
            defaultValue: 'Are you sure you want to delete this command?',
          }),
          confirmationText: t('page.commands.deleteConfirmation.yes', {
            defaultValue: 'Yes, please',
          }),
          cancellationText: t('page.commands.deleteConfirmation.no', {
            defaultValue: "No, I'm good",
          }),
        })
          .then(onRemoveCommandHandler)
          .catch((err) => {
            setCommandForDeletion(null);
            console.error(err);
          });
      })();
    }
  }, [confirm, commandForDeletion]);

  async function onNewCommandHandler() {
    const { data } = await mutateCreateOneCommandAsync({
      name: 'My Command',
      icon: '',
      description: 'New Command',
    });

    onOpenCommandHandler(data);
  }

  function onOpenCommandHandler({ id }: CommandMinType) {
    navigate(generatePath(paths.editCommand.pathname, { id }));
  }

  function onToggleCommandHandler({ id, isEnabled }: CommandMinType) {
    mutateUpdateOneCommandAsync({ id, isEnabled: !isEnabled });
  }

  function onRemoveCommandHandler() {
    commandForDeletion && mutateDeleteOneCommandAsync(commandForDeletion.id);
    setCommandForDeletion(null);
  }

  return (
    <>
      <img
        src="https://assets.knowz.com/assets/svg/waves.svg"
        alt="knowz Commands"
        width={IMAGE_SIZE_WIDTH}
        height={IMAGE_SIZE_HEIGHT}
      />

      <Typography component="h1" variant="h3">
        {t('layout.page.commands.hero.title', {
          defaultValue: 'Hook AI everywhere',
        })}
      </Typography>

      <Button
        startIcon={<SwitchAccessShortcutAddIcon />}
        variant="contained"
        size={isItMobileOrTablet ? 'medium' : 'large'}
        onClick={onNewCommandHandler}
      >
        {t('layout.page.commands.hero.button', {
          defaultValue: 'Create new command',
        })}
      </Button>

      <StyledStack gap={5}>
        <TextField
          label={t('page.commands.search.title', {
            defaultValue: 'Find Commands',
          })}
          onChange={(event) =>
            setFilter(event.target.value.toLocaleLowerCase())
          }
          variant="outlined"
        />

        <ListboxComponent>
          {data
            ?.filter(
              (item) =>
                !filter ||
                item.name.toLocaleLowerCase().includes(filter) ||
                item.description.toLocaleLowerCase().includes(filter),
            )
            .map((item) => {
              return (
                <CommandListItem
                  key={item.id}
                  command={item}
                  onOpen={() => onOpenCommandHandler(item)}
                  onToggle={() => onToggleCommandHandler(item)}
                  onRemove={() => setCommandForDeletion(item)}
                />
              );
            })}
        </ListboxComponent>
      </StyledStack>
    </>
  );
}
