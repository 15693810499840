import ActionButton from '@components/pages/knowledge-base/components/action-button';
import '@cyntler/react-doc-viewer/dist/index.css';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import {
  StyledModal,
  StyledBox,
} from '@components/pages/knowledge-base/components/table/cells/action/source-viewer/styles';
import { useState } from 'react';
import Doc from '@components/pages/knowledge-base/components/table/cells/action/source-viewer/doc-viewer';

type Props = {
  id: number;
  shouldShowButton?: boolean;
  isOpen?: boolean;
  onClose?: VoidFunction;
  shouldShowOpenInFolder?: boolean;
};

export default function SourceViewer({
  id,
  shouldShowButton = false,
  isOpen: externalIsOpen,
  onClose: externalOnClose,
  shouldShowOpenInFolder = false,
}: Props) {
  const [internalIsOpen, setInternalIsOpen] = useState(false);

  const isModalOpen = shouldShowButton ? internalIsOpen : externalIsOpen;
  const handleClose = shouldShowButton
    ? () => setInternalIsOpen(false)
    : externalOnClose;

  function toggleModal() {
    if (shouldShowButton) {
      setInternalIsOpen(!internalIsOpen);
    }
  }

  return (
    <>
      {shouldShowButton && (
        <ActionButton onClick={toggleModal}>
          <FullscreenIcon />
        </ActionButton>
      )}

      <StyledModal
        open={isModalOpen || false}
        keepMounted
        onClose={handleClose}
      >
        <StyledBox>
          {isModalOpen && (
            <Doc id={id} shouldShowOpenInFolder={shouldShowOpenInFolder} />
          )}
        </StyledBox>
      </StyledModal>
    </>
  );
}
