import { useMemo } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import qs from 'qs';

export default function useQueryParams() {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const allQueryParams = useMemo(() => {
    return Object.fromEntries(searchParams);
  }, [searchParams]);

  const getQueryParamByKey = <T>(key: string, fallbackValue = null): T => {
    const params = new URLSearchParams(location.search);
    return (params.get(key) || fallbackValue) as T;
  };

  const setQueryParam = (key: string, value: string) => {
    const params = new URLSearchParams(location.search);
    params.set(key, value.toString());
    setSearchParams(params.toString());
  };

  const removeQueryParamByKey = (key: string) => {
    const params = new URLSearchParams(location.search);
    params.delete(key);
    setSearchParams(params.toString());
  };

  const removeQueryParamsByKeys = (keys: string[]) => {
    const params = new URLSearchParams(location.search);
    keys.forEach((key) => params.delete(key));
    setSearchParams(params.toString());
  };

  const removeAllQueryParamsNoMatterWhat = () => {
    setSearchParams('');
  };

  const stringifiedQueryParams = useMemo(
    () => qs.stringify(allQueryParams),
    [allQueryParams],
  );

  return {
    allQueryParams,
    stringifiedQueryParams,
    getQueryParamByKey,
    setQueryParam,
    removeQueryParamByKey,
    removeQueryParamsByKeys,
    removeAllQueryParamsNoMatterWhat,
  };
}
