import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';

export const StyledBox = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isCentered',
})<{ isCentered?: boolean }>(({ theme, isCentered }) => ({
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word',
  overflow: 'auto',
  maxHeight: '100%',
  padding: theme.spacing(4),
  paddingTop: theme.spacing(0),
  ...(isCentered && {
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  }),
}));

export const StyledLoadingBox = styled(Stack)(() => ({
  width: '100%',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
}));
