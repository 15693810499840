import useSideKick from '@components/pages/side-kick-session/hooks/useSideKick';
import ListItem from '@components/pages/side-kick-session/sections/content/components/feed/list-item';
import Selector from '@components/pages/side-kick-session/sections/content/components/feed/selector';
import ContentLayout from '@components/pages/side-kick-session/sections/content/content-layout';
import { useTranslation } from '@desygner/ui-common-translation';
import { Typography } from '@mui/material';
import MuiList from '@mui/material/List';
import { useCallback, useRef, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { ListOnScrollProps, VariableSizeList as List } from 'react-window';

const HEIGHT_OF_FEED_HEADER = 80;
const SCROLLING_THRESHOLD = 250;
const OVERSCAN_COUNT = 10;

export default function Feed() {
  const { feedsCounter } = useSideKick();

  const listRef = useRef<List | null>(null);

  const liRef = useRef<HTMLLIElement | null>(null);

  const rowHeights = useRef<{ [key: number]: number }>({});

  const [isAutoScrolling, setIsAutoScrolling] = useState(true);

  const { t } = useTranslation();

  const getRowHeight = (index: number) => {
    return rowHeights.current[index] || 50;
  };

  const setRowHeight = useCallback((index: number, size: number) => {
    if (rowHeights.current[index] !== size) {
      rowHeights.current = { ...rowHeights.current, [index]: size };
      listRef.current?.resetAfterIndex(index);
    }
  }, []);

  function handleScroll(event: unknown, columnHeight: number) {
    const { scrollOffset, scrollDirection } = event as ListOnScrollProps;

    if (
      scrollDirection === 'backward' &&
      scrollOffset + columnHeight - HEIGHT_OF_FEED_HEADER <
        liRef.current!.clientHeight - SCROLLING_THRESHOLD
    ) {
      setIsAutoScrolling(false);
    }
    if (scrollDirection === 'forward') {
      setIsAutoScrolling(true);
    }
  }

  return (
    <ContentLayout headerSlot={<Selector />}>
      <MuiList
        disablePadding
        sx={{
          height: '100%',
        }}
      >
        {
          <AutoSizer>
            {({ height, width }) => {
              return (
                <List
                  onScroll={(event) => handleScroll(event, height)}
                  ref={listRef}
                  height={height - HEIGHT_OF_FEED_HEADER}
                  width={width}
                  itemCount={feedsCounter}
                  itemSize={getRowHeight}
                  innerRef={liRef}
                  overscanCount={OVERSCAN_COUNT}
                  itemData={{
                    setRowHeight,
                  }}
                >
                  {ListItem}
                </List>
              );
            }}
          </AutoSizer>
        }
      </MuiList>
    </ContentLayout>
  );
}
