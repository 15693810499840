import {
  StyledStack,
  StyledTypography,
} from '@components/search-form/react-hook-form-search-mode-select/tooltip/styles';
import { useTranslation } from '@desygner/ui-common-translation';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

type Props = {
  hint: string;
};

const STAR_URL = 'https://assets.knowz.com/assets/svg/star-dark.svg';
const STAR_SIZE = 20;

export default function Content({ hint }: Props) {
  const { t } = useTranslation();

  return (
    <Stack gap={2}>
      <Stack direction="row" gap={2}>
        <AutoAwesomeIcon />
        <Typography variant="body1">
          {t('page.home.hero.search.genius.title', {
            defaultValue: 'Genius mode',
          })}
        </Typography>
      </Stack>
      <StyledStack>
        <Stack direction="row" gap={2}>
          <img
            src={STAR_URL}
            alt="knowz credits"
            width={STAR_SIZE}
            height={STAR_SIZE}
          />
          <Typography variant="subtitle2">
            {t('page.home.hero.search.genius.paymentFeature', {
              defaultValue: 'Payment feature',
            })}
          </Typography>
        </Stack>
        <StyledTypography variant="caption">{hint}</StyledTypography>
      </StyledStack>
    </Stack>
  );
}
