import ActionDrawer from '@components/pages/knowledge-base/components/table/action-drawer';
import Action from '@components/pages/knowledge-base/components/table/cells/action';
import Format from '@components/pages/knowledge-base/components/table/cells/format';
import Location from '@components/pages/knowledge-base/components/table/cells/location';
import Name from '@components/pages/knowledge-base/components/table/cells/name';
import Permission from '@components/pages/knowledge-base/components/table/cells/permission';
import SelectCell from '@components/pages/knowledge-base/components/table/cells/select';
import SyncStatus from '@components/pages/knowledge-base/components/table/cells/sync-status';
import TABLE_CONFIG from '@components/pages/knowledge-base/components/table/config';
import SelectHeader from '@components/pages/knowledge-base/components/table/headers/select';
import Skeleton from '@components/pages/knowledge-base/components/table/loading-skeleton';
import DataNotFound from '@components/pages/knowledge-base/components/table/page/data-not-found';
import {
  StyledMuiTableHeadCell,
  StyledPaper,
} from '@components/pages/knowledge-base/components/table/styles';
import KNOWLEDGE_BASE_CONFIG from '@components/pages/knowledge-base/config';
import useDebounce from '@components/pages/knowledge-base/hooks/useDebounce';
import useGetFoldersDataById from '@components/pages/knowledge-base/hooks/useGetFoldersDataById';
import useGetSourcesDataByFolderId from '@components/pages/knowledge-base/hooks/useGetSourcesDataByFolderId';
import useGetSourcesDataByQuery from '@components/pages/knowledge-base/hooks/useGetSourcesDataByQuery';
import Pagination from '@components/pagination';
import UppyUpload from '@components/uppy-upload';
import useGetFolderDataByPath from '@hooks/useGetFolderDataByPath';
import useQueryParams from '@hooks/useQueryParams';
import useWorkspace from '@hooks/useWorkspace';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { alpha } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MuiTable from '@mui/material/Table';
import MuiTableBody from '@mui/material/TableBody';
import MuiTableCell from '@mui/material/TableCell';
import MuiTableHead from '@mui/material/TableHead';
import MuiTableRow from '@mui/material/TableRow';
import MuiTableSortLabel from '@mui/material/TableSortLabel';
import TextField from '@mui/material/TextField';
import { FolderType } from '@shared-types/folders';
import { SourceType } from '@shared-types/sources';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { useTranslation } from '@desygner/ui-common-translation';

const DEBOUNCE_MAKING_REQUEST_IN_MS = 500;

export default function Table() {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState<string>(() => {
    const search =
      new URLSearchParams(window.location.search).get('search') || '';
    return search;
  });
  const { getQueryParamByKey } = useQueryParams();
  const path = getQueryParamByKey<'path' | null>('path');
  const highlightedId = getQueryParamByKey<string>('source');
  const page = Number(getQueryParamByKey('page') || 1);

  const debouncedSearch = useDebounce(
    searchQuery,
    DEBOUNCE_MAKING_REQUEST_IN_MS,
  );

  const {
    folderData,
    isFolderDataError,
    isFolderDataPending,
    isFolderDataFetching,
  } = useGetFolderDataByPath({ path });

  const { foldersData, isFoldersDataError, isFoldersDataPending } =
    useGetFoldersDataById({
      folderId: folderData?.id as number | null,
    });

  const { sourcesData, isSourcesDataError, isSourcesDataPending } =
    useGetSourcesDataByFolderId({
      folderId: folderData?.id as number | null,
      limit: KNOWLEDGE_BASE_CONFIG.PAGINATION.LIMIT,
      page: page,
    });

  const {
    filteredSourcesData,
    isFilteredSourcesDataError,
    isFilteredSourcesDataLoading,
  } = useGetSourcesDataByQuery({
    query: debouncedSearch,
    limit: KNOWLEDGE_BASE_CONFIG.PAGINATION.LIMIT,
    page,
  });

  const [openDrawerById, setOpenDrawerById] = useState<number | null>(null);

  const resources = useMemo(
    () => [...(foldersData || []), ...(sourcesData?.data || [])],
    [foldersData, sourcesData],
  );
  const { setupMode } = useWorkspace();

  const areResourcesPending =
    isSourcesDataPending ||
    isFoldersDataPending ||
    isFolderDataPending ||
    isFilteredSourcesDataLoading;
  const isResourceEmpty = resources.length === 0;

  const hasResourceError =
    isFolderDataError ||
    isFoldersDataError ||
    isSourcesDataError ||
    isFilteredSourcesDataError;

  function handleToggleActionDrawer(id: number | null) {
    setOpenDrawerById(id);
  }

  const columns = useMemo<ColumnDef<FolderType | SourceType>[]>(
    () => [
      {
        id: 'select',
        header: (props) => <SelectHeader {...props} />,
        cell: (props) => <SelectCell {...props} />,
      },
      {
        header: 'Name',
        accessorKey: 'name',
        cell: (props) => <Name {...props} />,
      },
      {
        header: 'Format',
        accessorKey: 'fileType',
        cell: (props) => <Format {...props} />,
      },
      {
        header: 'Location',
        accessorKey: 'sourceConfiguration',
        cell: (props) => <Location {...props} />,
      },
      {
        header: 'Sync Status',
        cell: (props) => <SyncStatus {...props} />,
      },
      {
        header: 'Permission',
        accessorKey: 'examplesMemberships',
        cell: (props) => <Permission {...props} />,
      },
      {
        header: 'Action',
        accessorKey: 'action',
        cell: (props) => (
          <Action
            {...props}
            handleToggleActionDrawer={handleToggleActionDrawer}
          />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [path, setupMode],
  );

  const tableData = useReactTable(
    (filteredSourcesData?.data || []).length > 0
      ? {
          data: filteredSourcesData?.data || [],
          columns,
          getCoreRowModel: getCoreRowModel(),
          getFilteredRowModel: getFilteredRowModel(),
          manualPagination: true,
          enableRowSelection: true,
        }
      : {
          data: resources,
          columns,
          getCoreRowModel: getCoreRowModel(),
          getFilteredRowModel: getFilteredRowModel(),
          manualPagination: true,
          enableRowSelection: true,
        },
  );

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Escape') {
      setSearchQuery('');
    }
  }

  function handleClearSearch() {
    setSearchQuery('');
  }

  function handleSearchQuery(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchQuery(event.target.value);
  }

  const paginationPageCount =
    filteredSourcesData?.data.length !== 0
      ? filteredSourcesData?.meta.pagination.pageCount ||
        sourcesData?.meta.pagination.pageCount ||
        0
      : 0;

  return !areResourcesPending && isResourceEmpty ? (
    <UppyUpload />
  ) : (
    <>
      <ActionDrawer
        anchor="right"
        isDrawerOpenById={openDrawerById}
        onClose={() => handleToggleActionDrawer(null)}
      />
      <TextField
        size="small"
        placeholder={t(
          'page.assistants.knowledgeBase.table.search.placeholder',
          { defaultValue: 'Search sources by name' },
        )}
        sx={{
          width: {
            xs: '100%',
            md: 250,
          },
        }}
        value={searchQuery}
        InputProps={{
          sx: {
            pr: 3,
          },
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: searchQuery && (
            <InputAdornment position="end">
              <IconButton size="small" onClick={handleClearSearch}>
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        onChange={handleSearchQuery}
        onKeyDown={handleKeyDown}
      />
      {filteredSourcesData?.data.length === 0 ? (
        <DataNotFound />
      ) : (
        <StyledPaper>
          <MuiTable>
            <MuiTableHead>
              <MuiTableRow>
                {tableData?.getHeaderGroups().map((headerGroup) => {
                  return headerGroup.headers.map((header) => {
                    const isItSelectCell = header.id === 'select';
                    const areTheySelectOrActionCell =
                      isItSelectCell || header.id === 'action';
                    return (
                      <StyledMuiTableHeadCell
                        key={header.id}
                        sx={{
                          ...(isItSelectCell && {
                            width: '10px',
                          }),
                        }}
                      >
                        <MuiTableSortLabel
                          active={!areTheySelectOrActionCell}
                          hideSortIcon={areTheySelectOrActionCell}
                          IconComponent={SwapVertIcon}
                          sx={{
                            width: '100%',
                            justifyContent: 'space-between',
                          }}
                        >
                          <span>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                          </span>
                        </MuiTableSortLabel>
                      </StyledMuiTableHeadCell>
                    );
                  });
                })}
              </MuiTableRow>
            </MuiTableHead>
            <MuiTableBody>
              {areResourcesPending || isFolderDataFetching ? (
                <Skeleton
                  headItems={TABLE_CONFIG.ADMIN_TABLE_HEADER}
                  numberOfRows={KNOWLEDGE_BASE_CONFIG.PAGINATION.LIMIT}
                />
              ) : (
                tableData.getRowModel().rows.map((row) => {
                  const canBeHighlighted =
                    String(row.original.id) === highlightedId;
                  return (
                    <MuiTableRow
                      key={row.id}
                      sx={{
                        backgroundColor: canBeHighlighted
                          ? (theme) => alpha(theme.palette.info.main, 0.3)
                          : undefined,
                      }}
                    >
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <MuiTableCell key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </MuiTableCell>
                        );
                      })}
                    </MuiTableRow>
                  );
                })
              )}
            </MuiTableBody>
          </MuiTable>
        </StyledPaper>
      )}
      {paginationPageCount > 0 && (
        <Pagination pageCount={paginationPageCount} />
      )}
    </>
  );
}
