import { useMemo, useState } from 'react';
import {
  DefaultViewportController,
  Vector,
  WorkspaceApi,
} from 'sequential-workflow-designer';

const VIEWPORT_MARGINS = {
  top: 160,
  left: 244,
  right: 412,
  bottom: 10,
};

const SCALES = [0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.5];

function getScreenConstraints() {
  const width = window.innerWidth;
  const height = window.innerHeight;
  const horizontalMargin = VIEWPORT_MARGINS.left + VIEWPORT_MARGINS.right;
  const verticalMargin = VIEWPORT_MARGINS.top + VIEWPORT_MARGINS.bottom;
  const left = (width - horizontalMargin) / 2 + VIEWPORT_MARGINS.left;
  const top = (height - verticalMargin) / 2 + VIEWPORT_MARGINS.top;

  return {
    width,
    height,
    horizontalMargin,
    verticalMargin,
    center: {
      left,
      top,
    },
  };
}

export default function useViewport() {
  const [workspaceApi, setWorkspaceApi] = useState<WorkspaceApi | null>(null);

  function getDefaultViewport() {
    const { height, verticalMargin, center } = getScreenConstraints();
    const box = document.querySelector('.sqd-workspace-canvas > g')?.getBBox();
    let scale = 1;

    if (height - verticalMargin < box.height) {
      scale = (height - verticalMargin) / box.height;
    }

    return {
      scale,
      position: new Vector(
        center.left - (box.width / 2) * scale,
        center.top - (box.height / 2) * scale,
      ),
    };
  }

  const viewportExtension = useMemo(
    () => ({
      viewportController: {
        create(api: WorkspaceApi) {
          setWorkspaceApi(api);

          const controller = DefaultViewportController.create(api, {
            scales: SCALES,
            smoothDeltaYLimit: 16,
            padding: 10,
          });

          controller.getDefault = getDefaultViewport;

          return controller;
        },
      },
    }),
    [],
  );

  function setDefaultViewport() {
    workspaceApi?.setViewport(getDefaultViewport());
  }

  function focusStep(setId: string) {
    if (!workspaceApi) return;
    const stepPosition = getStepPosition(setId);
    if (!stepPosition) return;

    const { bBox, x, y } = stepPosition;
    const { center } = getScreenConstraints();
    const scale = 1.2;

    workspaceApi?.setViewport({
      scale,
      position: new Vector(
        center.left - x - (bBox.width / 2) * scale,
        center.top - y - (bBox.height / 2) * scale,
      ),
    });
  }

  function getStepPosition(setId: string) {
    let element = document.querySelector(
      `.sqd-workspace-canvas [data-step-id="${setId}"]`,
    ) as SVGGElement | null | undefined;
    if (!element) return null;

    const bBox = element.getBBox();
    const match = element
      .getAttribute('transform')
      ?.match(/translate\((.+),\s*(.*)\)/);
    if (!match || match.length < 3) return;

    let x = parseFloat(match[1]);
    let y = parseFloat(match[2]);

    while ((element = element.parentElement?.closest('g'))) {
      if (element.classList.contains('sqd-root-start-stop')) break;

      const match = element
        .getAttribute('transform')
        ?.match(/translate\((.+),\s*(.*)\)/);

      if (match && match.length! >= 3) {
        x += parseFloat(match[1]);
        y += parseFloat(match[2]);
      }
    }

    return { bBox, x, y };
  }

  return {
    viewportExtension,
    setDefaultViewport,
    focusStep,
  };
}
