const attributes = [
  'Clumsy',
  'Sassy',
  'Disco',
  'Fluffy',
  'Grumpy',
  'Sparkly',
  'Ninja',
  'Wobbly',
  'Sneaky',
  'Giggly',
  'Awkward',
  'Dapper',
  'Sleepy',
  'Jazzy',
  'Hipster',
  'Zany',
  'Quirky',
  'Derpy',
  'Fabulous',
  'Gangsta',
  'Yodeling',
  'Burping',
  'Majestic',
  'Tipsy',
  'Moonwalking',
  'Snarky',
  'Squishy',
  'Bouncy',
  'Dazzling',
  'Groovy'
];

const animals = [
  'Koala',
  'Dinosaur',
  'Octopus',
  'Penguin',
  'Giraffe',
  'Platypus',
  'Llama',
  'Narwhal',
  'Sloth',
  'Panda',
  'Raccoon',
  'Hedgehog',
  'Axolotl',
  'Kangaroo',
  'Chameleon',
  'Wombat',
  'Capybara',
  'Lemur',
  'Quokka',
  'Armadillo',
  'Aardvark',
  'Pangolin',
  'Mongoose',
  'Tapir',
  'Okapi',
  'Echidna',
  'Dugong',
  'Numbat',
  'Kookaburra',
  'Manatee'
];

const knownNames: { [speaker: string]: string } = {};

export default function getFriendlyName(speaker: string) {
  if (knownNames[speaker]) {
    return knownNames[speaker];
  }

  const randomAttribute = attributes[Math.floor(Math.random() * attributes.length)];
  const randomAnimal = animals[Math.floor(Math.random() * animals.length)];
  const name = `${randomAttribute} ${randomAnimal}`;

  knownNames[speaker] = name;

  return name;
}
