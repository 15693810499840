const AVATAR_SIZE = 24;

const STEPS = ['Name', 'Logo', 'Invite'];

const CONFIG = {
  AVATAR_SIZE,
  STEPS,
};

export default CONFIG;
