import UpgradeAccount from '@components/pages/upgrade-account';
import isEmbedded from '@lib/loaderHelper';
import protectRoutes from '@lib/protectRoutes';
import AppStatus from '@pages/app-status';
import AssistantsPage from '@pages/assistants';
import AssistantsSessionPage from '@pages/assistants-session';
import AuditPage from '@pages/audit';
import CommandDesignerPage from '@pages/command-designer';
import CommandsPage from '@pages/commands';
import ConnectorsPage from '@pages/connectors';
import ErrorPage from '@pages/error';
import GetTheAppPage from '@pages/get-the-app';
import HistoryPage from '@pages/history';
import HomePage from '@pages/home';
import {
  AssistantsSessionOutlet,
  DashboardOutlet,
  MainOutlet,
} from '@pages/IndexPage';
import KnowledgeBasePage from '@pages/knowledge-base';
import SearchPage from '@pages/search';
import SideKickPage from '@pages/side-kick';
import SideKickSessionPage from '@pages/side-kick-session';
import SsoPage from '@pages/sso';
import SsoEditorPage from '@pages/sso-editor';
import UpgradeAccountPage from '@pages/upgrade-account';
import Users from '@pages/users';
import paths from '@router/paths';
import {
  json,
  LoaderFunctionArgs,
  Outlet,
  RouteObject,
} from 'react-router-dom';

const routes: RouteObject[] = [
  {
    path: '/',
    element: isEmbedded ? <Outlet /> : <DashboardOutlet />,
    errorElement: <ErrorPage />,
    loader: protectRoutes,
    children: [
      {
        path: paths.editCommand.pathname,
        element: <CommandDesignerPage />,
      },
      {
        path: paths.audit.pathname,
        element: <AuditPage />,
      },
      {
        path: paths.status.pathname,
        element: <AppStatus />,
      },
    ],
  },
  {
    path: '/',
    element: <MainOutlet />,
    errorElement: <ErrorPage />,
    loader: protectRoutes,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: paths.search.pathname,
        element: <SearchPage />,
        loader: async ({ request }: LoaderFunctionArgs) => {
          const url = new URL(request.url).searchParams.get('t');
          if (url == null) {
            return json({ error: 'thread id is required' }, { status: 404 });
          }

          return 'ok';
        },
      },
      {
        path: paths.sideKick.pathname,
        element: <SideKickPage />,
      },
      {
        path: paths.sideKickSession.pathname,
        element: <SideKickSessionPage />,
      },
      {
        path: paths.connectors.pathname,
        element: <ConnectorsPage />,
      },
      {
        path: paths.commands.pathname,
        element: <CommandsPage />,
      },
      {
        path: paths.history.pathname,
        element: <HistoryPage />,
      },
      {
        path: paths.assistants.pathname,
        element: <AssistantsPage />,
      },
      {
        path: paths.assistantsSession.pathname,
        element: <AssistantsSessionPage />,
      },
      {
        path: paths.sso.pathname,
        element: <SsoPage />,
      },
      {
        path: paths.editSso.pathname,
        element: <SsoEditorPage />,
      },
      {
        path: paths.users.pathname,
        element: <Users />,
        errorElement: <ErrorPage />,
      },
      {
        path: paths.getTheApp.pathname,
        element: <GetTheAppPage />,
      },
      {
        path: paths.knowledgeBase.pathname,
        element: <KnowledgeBasePage />,
      },
      {
        path: paths.upgradeAccount.pathname,
        element: <UpgradeAccountPage />,
      },
    ],
  },
  {
    path: paths.assistantsSession.pathname,
    element: <AssistantsSessionOutlet />,
    loader: protectRoutes,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <AssistantsSessionPage />,
      },
    ],
  },
];

export default routes;
