import TagManager from 'react-gtm-module';

type Props = {
  userUUID: string;
  usedAmount: number;
  totalAmount: number;
};

export default function sendCreditsUsedToAnalytics({
  usedAmount,
  totalAmount,
  userUUID,
}: Props) {
  TagManager.dataLayer({
    dataLayer: {
      event: 'credits_used',
      eventProps: {
        used_amount: usedAmount,
        total_amount: totalAmount,
        user_uuid: userUUID,
      },
    },
  });
}
