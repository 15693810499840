//TODO: I would like to go through this with @Nax to make sure If I have covered all edge cases. so comments can be removed afterwards.
import { configureStore } from '@reduxjs/toolkit';
import assistantResultMiddleware from '@state/middleware/assistant-result';
import searchResultMiddleware from '@state/middleware/search-result';
import socketMiddleware from '@state/middleware/websocket';
import assistantResultSlice from '@state/slices/assistant-result';
import searchResultSlice from '@state/slices/search-result';
import transcriptionSlice from '@state/slices/transcription';
import websocketSlice from '@state/slices/websocket';
import flowRunSlice, {
  onRunCommandListener,
  tickListener,
} from '@state/slices/flow-run';

const store = configureStore({
  reducer: {
    assistantResult: assistantResultSlice,
    searchResult: searchResultSlice,
    transcription: transcriptionSlice,
    websocket: websocketSlice,
    flowRun: flowRunSlice,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat([
      socketMiddleware,
      assistantResultMiddleware,
      searchResultMiddleware,
      onRunCommandListener.middleware,
      tickListener.middleware,
    ]);
  },
});

export type RootType = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
