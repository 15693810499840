import Tooltip from '@mui/material/Tooltip';
import { forwardRef } from 'react';
import IconButton from '@mui/material/IconButton';
import { StyledClickableAvatar } from '@components/workspace/workspace-selector/triggers/styles';
import useWorkspace from '@hooks/useWorkspace';
import useAuth from '@hooks/useAuth';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

type Props = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const Mini = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { workspace } = useWorkspace();
  const { me } = useAuth();

  return (
    <Tooltip
      title="Workspace Selection"
      placement="right"
      arrow
      componentsProps={{
        tooltip: { sx: { marginLeft: '18px!important' } },
      }}
    >
      <IconButton ref={ref} {...props} sx={{ marginBottom: '10px' }}>
        {workspace && (
          <StyledClickableAvatar src={workspace.profilePicture || ''}>
            {workspace.name[0].toUpperCase()}
          </StyledClickableAvatar>
        )}
        {!workspace && me && <HomeOutlinedIcon />}
        {/* TODO: skeleton */}
      </IconButton>
    </Tooltip>
  );
});

Mini.displayName = 'Mini';

export default Mini;
