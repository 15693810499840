import { alpha, styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

export const StyledTypography = styled(Typography)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
}));

export const StyledStack = styled(Stack)(({ theme }) => ({
  color: '#E3C4EE', //TODO: we need to add this color to the theme palette after new design is implemented
  backgroundColor: alpha('#E3C4EE', theme.palette.action.hoverOpacity),
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
}));
