import { useTranslation } from '@desygner/ui-common-translation';
import useAuth from '@hooks/useAuth';
import useWorkspace from '@hooks/useWorkspace';
import CONFIG_HEADER from '@layouts/main/config';
import ToolbarExpansionButton from '@layouts/main/sections/toolbar/components/expand-button';
import CONFIG_TOOLBAR from '@layouts/main/sections/toolbar/config';
import CreditUsage from '@layouts/main/sections/toolbar/expanded/sections/credit-usage';
import {
  StyledExpandedToolbar,
  StyledListItem,
  StyledStack,
  StyledStickyBox,
} from '@layouts/main/sections/toolbar/expanded/styles';
import useToolbar from '@layouts/main/sections/toolbar/hooks/useToolbar';
import { StyledListStack } from '@layouts/main/sections/toolbar/mini/desktop/styles';
import Socials from '@layouts/main/sections/toolbar/socials';
import { StyledNavLink } from '@layouts/main/sections/toolbar/styles';
import WorkspaceSelector from '@components/workspace/workspace-selector';
import { convertToRem } from '@lib/fonts';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import { useRef } from 'react';
import Transition from 'react-transition-group/Transition';
import MuiLink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import paths from '@router/paths';

export default function ExpandedToolbar() {
  const { t } = useTranslation();
  const nodeRef = useRef(null);
  const { me } = useAuth();
  const { setupMode, alertHeight } = useWorkspace();

  const {
    toolbarExpansionStatus,
    handleHideExpandedButton,
    handleDisplayExpandedButton,
    isExpandedButtonDisplayed,
  } = useToolbar();

  return (
    toolbarExpansionStatus === 'on' && (
      <Transition
        nodeRef={nodeRef}
        in={isExpandedButtonDisplayed}
        timeout={CONFIG_TOOLBAR.DELAY_HIDE_EXPANDED_BUTTON}
      >
        {(state) => (
          <StyledStickyBox
            onMouseEnter={handleDisplayExpandedButton}
            onMouseLeave={handleHideExpandedButton}
            sx={{
              pr: convertToRem(CONFIG_TOOLBAR.PATH_TO_SHOW_COLLAPSING_BUTTON),
              marginTop: convertToRem(
                CONFIG_HEADER.HEADER.PADDING_Y + CONFIG_HEADER.HEADER.HEIGHT,
              ),
              height: `calc(100vh - (
                  ${convertToRem(CONFIG_HEADER.HEADER.HEIGHT)} +
                  ${convertToRem(CONFIG_HEADER.FOOTER.HEIGHT)} +
                    ${convertToRem(
                      alertHeight &&
                        alertHeight + CONFIG_HEADER.HEADER.PADDING_Y,
                    )}
                  )
                )`,
              top: convertToRem(
                CONFIG_HEADER.HEADER.HEIGHT +
                  CONFIG_HEADER.HEADER.PADDING_Y +
                  (alertHeight &&
                    alertHeight + CONFIG_HEADER.HEADER.PADDING_Y / 2),
              ),
            }}
          >
            <StyledExpandedToolbar disableGutters>
              <StyledStack>
                <StyledListStack
                  disablePadding
                  component={Stack}
                  direction="column"
                >
                  {(!CONFIG_TOOLBAR.IS_WORKSPACE_SELECTOR_ALPHA ||
                    me?.alpha) && <WorkspaceSelector variant="default" />}

                  {CONFIG_TOOLBAR.TOOLBAR_ITEMS.filter(
                    ({ isAlpha }) => me?.alpha || !isAlpha,
                  )
                    .filter(({ setupMode: singleSetupMode }) =>
                      singleSetupMode.includes(setupMode),
                    )
                    .map(({ defaultLabel, icon, mappedLabel, url }, i) => (
                      <StyledListItem
                        to={url}
                        key={i}
                        component={StyledNavLink}
                        disablePadding
                        disableGutters
                      >
                        <ListItemButton>
                          <ListItemIcon>{icon}</ListItemIcon>
                          <ListItemText
                            primary={t(mappedLabel, {
                              defaultValue: defaultLabel,
                            })}
                            primaryTypographyProps={{
                              variant: 'body2',
                            }}
                          />
                        </ListItemButton>
                      </StyledListItem>
                    ))}
                </StyledListStack>
                <Stack gap={2} sx={{ justifyContent: 'center' }}>
                  <Socials />
                  <Stack
                    direction="row"
                    gap={3}
                    sx={{ justifyContent: 'center' }}
                  >
                    <MuiLink
                      component={Link}
                      target="_blank"
                      to={paths.pp.pathname}
                    >
                      <Typography variant="caption">
                        {t('layout.toolbar.buttons.pp', {
                          defaultValue: 'Privacy Policy',
                        })}
                      </Typography>
                    </MuiLink>
                    <MuiLink
                      component={Link}
                      target="_blank"
                      to={paths.tos.pathname}
                    >
                      <Typography variant="caption">
                        {t('layout.toolbar.buttons.tos', {
                          defaultValue: 'Terms of Service',
                        })}
                      </Typography>
                    </MuiLink>
                  </Stack>
                  <CreditUsage />
                </Stack>
              </StyledStack>
              {state === 'exited' ? null : (
                <ToolbarExpansionButton ref={nodeRef} />
              )}
            </StyledExpandedToolbar>
          </StyledStickyBox>
        )}
      </Transition>
    )
  );
}
