import Modal from '@components/modal';
import Plans from '@components/plans';
import { StyledIconButton } from '@components/upgrade-modal/styles';
import useQueryParams from '@hooks/useQueryParams';
import CloseIcon from '@mui/icons-material/Close';

export default function UpgradeModal() {
  const { getQueryParamByKey, removeQueryParamByKey } = useQueryParams();
  const actionQueryParam = getQueryParamByKey<'upgrade-account'>('action');

  function onClose() {
    removeQueryParamByKey('action');
  }

  return (
    <Modal
      open={actionQueryParam === 'upgrade-account'}
      width="80%"
      sx={{ p: 4, maxHeight: '90%' }}
    >
      <StyledIconButton onClick={onClose}>
        <CloseIcon />
      </StyledIconButton>
      <Plans />
    </Modal>
  );
}
