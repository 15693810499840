import { payments } from '@lib/agent';
import { useState } from 'react';

export default function usePaymentWithStripeByPriceId() {
  const [isLoading, setIsLoading] = useState(false);

  async function handleRedirectToStripe({
    priceId,
    mode,
  }: {
    priceId: string;
    mode: 'subscription' | 'payment';
  }) {
    setIsLoading(true);

    try {
      //? https://www.abeautifulsite.net/posts/opening-a-new-window-after-an-async-operation/
      const newWindow = window.open('about:blank', '_blank');
      if (newWindow) {
        const response = await payments.getStripePaymentLink({ priceId, mode });
        newWindow.location.href = response?.data.url;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  return {
    handleRedirectToStripe,
    isLoading,
  };
}
