import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';

const StyledCircularProgressStack = styled(Stack)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
}));

export default StyledCircularProgressStack;
