import { insertDirective$, usePublisher } from '@mdxeditor/editor';
import {
  useSequentialWorkflowDesignerController,
  useStepEditor,
} from 'sequential-workflow-designer-react';
import { useMemo, useState } from 'react';
import {
  getStepContextTypes,
  getAvailableOptionsOfType,
} from '@components/pages/command-designer/config';
import { BagItemType, StepType } from '@lib/step/types';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const CONTEXT_LIMIT_TOTAL = 15;

function ContextButton({ value, type }: { value: string; type: BagItemType }) {
  const insertDirective = usePublisher(insertDirective$);

  return (
    <Button
      size="small"
      color="secondary"
      variant="outlined"
      onClick={() =>
        insertDirective({
          name: 'context',
          type: 'textDirective',
          attributes: { type },
          children: [{ type: 'text', value }], // TODO: fix missing type TextDirective
        })
      }
    >
      <Box
        component="span"
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {value}
      </Box>
    </Button>
  );
}

export default function ToolbarContents({ step }: { step: StepType }) {
  const [filter, setFilter] = useState('');

  const { id, definition } = useStepEditor();

  const availableContext = useMemo(() => {
    const result = [];
    const types = getStepContextTypes(step);

    for (const type of types) {
      const availableContextOfType = getAvailableOptionsOfType(
        id,
        type,
        definition,
      ) as string[];

      result.push(...availableContextOfType.map((value) => ({ value, type })));
    }

    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, step]);

  const filtredContext = useMemo(
    () =>
      availableContext.filter(
        (context) =>
          !filter ||
          context.value.toLocaleLowerCase().includes(filter) ||
          context.type.toLocaleLowerCase().includes(filter),
      ),
    [availableContext, filter],
  );

  if (availableContext.length === 0) {
    return (
      <Alert
        sx={{
          width: '100%',
          height: '100%',
          alignItems: 'center',
          background: (theme) => theme.palette.background.paper,
          borderTop: (theme) =>
            `1px solid ${theme.palette.background.card.main}`,

          borderRadius: '0!important',
        }}
        severity="info"
      >
        No context variables defined yet. You can add context in previous steps.
      </Alert>
    );
  }

  return (
    <Stack
      gap={1}
      sx={{
        width: '100%',
        padding: (theme) => theme.spacing(3, 2, 2, 2),
        background: (theme) => theme.palette.background.paper,
        borderRadius: 0,
        borderTop: (theme) => `1px solid ${theme.palette.background.card.main}`,
      }}
    >
      <TextField
        size="small"
        autoComplete="off"
        label="Insert context from previous steps"
        onChange={(event) => setFilter(event.target.value.toLocaleLowerCase())}
        variant="outlined"
      />

      <Stack
        direction="row"
        gap={1}
        sx={{
          width: '100%',
        }}
      >
        {filtredContext.slice(0, CONTEXT_LIMIT_TOTAL).map((context, i) => (
          <ContextButton key={`context-button-${i}`} {...context} />
        ))}

        {filtredContext.length > CONTEXT_LIMIT_TOTAL && (
          <Typography>...</Typography>
        )}

        {!filtredContext.length && <Typography>No context found</Typography>}
      </Stack>
    </Stack>
  );
}
