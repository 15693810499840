import {
  StyledPaper,
  StyledStack,
} from '@components/pages/side-kick-session/sections/content/content-layout/styles';

type Props = React.PropsWithChildren<{
  headerSlot: React.ReactNode;
}>;

export default function ContentLayout({ headerSlot, children }: Props) {
  return (
    <StyledPaper square>
      <StyledStack direction="row" gap={4}>
        {headerSlot}
      </StyledStack>
      {children}
    </StyledPaper>
  );
}
