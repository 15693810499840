import useGetCreditAsPercentage from '@hooks/useGetCreditAsPercentage';
import {
  StyledExpandedSlider,
  StyledImage,
  StyledMiniDesktopSlider,
} from '@layouts/main/sections/toolbar/components/credit-quantity/styles';
import { CreditQuantityType } from '@layouts/main/sections/toolbar/components/credit-quantity/types';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const STAR_SIZE = 20;
const PROGRESS_SIZE = 40;

const SLIDERS = {
  expanded: StyledExpandedSlider,
  miniDesktop: StyledMiniDesktopSlider,
  miniMobile: CircularProgress,
};

const STAR_ALT = 'Credit Star';

export default function CreditQuantity({ variant }: CreditQuantityType) {
  const { creditsData, isCreditLoading } = useGetCreditAsPercentage();

  const CalculatedSlider = SLIDERS[variant];

  const theme = useTheme();

  const STAR_URL = `https://assets.knowz.com/assets/svg/star-${theme.palette.mode}.svg`;

  if (variant === 'miniMobile') {
    return (
      <Box
        sx={{
          position: 'relative',
          width: PROGRESS_SIZE,
          height: PROGRESS_SIZE,
        }}
      >
        <svg width={0} height={0}>
          <defs>
            <linearGradient
              id="credits-gradient"
              x1="0%"
              y1="0%"
              x2="0%"
              y2="100%"
            >
              <stop offset="0%" stopColor={theme.palette.primary.light} />
              <stop offset="100%" stopColor={theme.palette.primary.main} />
            </linearGradient>
          </defs>
        </svg>
        <CalculatedSlider
          color="primary"
          sx={{ 'svg circle': { stroke: 'url(#credits-gradient)' } }}
          variant="determinate"
          value={Math.round(Number(creditsData))}
        />
        <StyledImage
          src={STAR_URL}
          alt={STAR_ALT}
          width={STAR_SIZE}
          height={STAR_SIZE}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      </Box>
    );
  }

  return (
    <>
      <StyledImage
        src={STAR_URL}
        alt={STAR_ALT}
        width={STAR_SIZE}
        height={STAR_SIZE}
      />
      <CalculatedSlider
        value={creditsData || 0}
        sx={{
          cursor: 'inherit',
        }}
        orientation={variant === 'miniDesktop' ? 'vertical' : 'horizontal'}
      />
      {isCreditLoading || typeof creditsData === 'undefined' ? (
        <Skeleton variant="rectangular" width={35} height={20} />
      ) : (
        <Typography variant="overline" sx={{ fontWeight: 400 }}>
          {creditsData < 0 ? 0 : Math.round(Number(creditsData))}%
        </Typography>
      )}
    </>
  );
}
