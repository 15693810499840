import useGetSentimentLabelsFromCDN from '@components/pages/side-kick-session/hooks/useGetSentimentLabelsFromCDN';
import {
  StyledStackWrapper,
  StyledStack,
} from '@components/pages/side-kick-session/sections/content/components/transcript/card/styles';
import calculateFactCheckColor from '@components/pages/side-kick-session/utils/calculateFactCheckColor';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { TranscriptType } from '@shared-types/sidekick';
import { forwardRef, memo } from 'react';
import getFriendlyName from './getFriendlyName';

const Card = forwardRef<HTMLDivElement, Omit<TranscriptType, 'id'>>(
  ({ content, labels, time, speaker }, ref) => {
    const { data: sentimentLabelsFromCDN } = useGetSentimentLabelsFromCDN();

    if (!content) return null;

    return (
      <StyledStackWrapper gap={3} ref={ref}>
        <StyledStack direction="row" gap={3}>
          <Avatar alt="John Doe" sx={{ width: 24, height: 24 }} />
          <Typography variant="subtitle2">{getFriendlyName(speaker)}</Typography>
          <Typography variant="body2" color="text.secondary">
            {time.minutes}:{time.seconds}
          </Typography>
        </StyledStack>
        <Typography sx={{ ml: 9 }} variant="body2">
          {content}
        </Typography>
        <Stack
          direction="row"
          gap={2}
          sx={{
            flexWrap: 'wrap',
          }}
        >
          {labels?.sentiments?.map(({ sentiment, classification }, index) => (
            <Chip
              key={index}
              label={sentiment}
              size="small"
              variant="outlined"
              color={
                sentimentLabelsFromCDN?.[classification]?.[sentiment] ??
                'default'
              }
              sx={{
                borderRadius: 1.5,
              }}
            />
          ))}
          {labels?.factChecks?.map((label, index) => (
            <Chip
              key={index}
              label={label.outcome}
              size="small"
              variant="outlined"
              color={calculateFactCheckColor(label.outcome)}
              sx={{
                borderRadius: 1.5,
              }}
            />
          ))}
        </Stack>
      </StyledStackWrapper>
    );
  },
);

Card.displayName = 'Card';

export default memo(Card);
