import useSideKick from '@components/pages/side-kick-session/hooks/useSideKick';
import DialogueCard from '@components/pages/side-kick-session/sections/content/components/transcript/card';
import { useEffect, useRef } from 'react';
import { ListChildComponentProps } from 'react-window';

type Props = Omit<ListChildComponentProps, 'data'> & {
  data: {
    setRowHeight: (index: number, size: number) => void;
  };
};

export default function ListItem({ index, data, style }: Props) {
  const { transcripts, transcriptIndices } = useSideKick();

  const rowRef = useRef<HTMLDivElement>(null);

  const { setRowHeight } = data;

  useEffect(() => {
    if (rowRef.current) {
      const newHeight = rowRef.current.clientHeight;
      setRowHeight(index, newHeight);
    }
  }, [index, setRowHeight, transcripts]);

  if (transcripts === null) return null;

  const transcript = transcripts[transcriptIndices[index]];

  if (transcript?.content == null) return null;

  return (
    <li style={style}>
      <DialogueCard
        ref={rowRef}
        speaker={transcript.speaker}
        content={transcript.content}
        labels={transcript.labels}
        time={transcript.time}
      />
    </li>
  );
}
