const KNOWZ_ASSISTANTS = {
  knowzBot: {
    url: 'https://assets.knowz.com/assets/svg/ai-orb.svg',
    title: 'Knowz Bot',
    description:
      'Answers questions integrating automation of knowledge base management',
  },
  factChecker: {
    url: 'https://assets.knowz.com/assets/svg/fack-checker.svg',
    title: 'Knowz Fact Checker',
    description:
      'Verify or correct claims using internet sources & uploaded files.',
  },
};

const SMART_TAGS = {
  'Content Sensitivity': {
    description: 'Tag and rate content sensitive topics',
  },
  'Bias Detection': {
    description: 'Tag potentially harmful biased speech',
  },
  'Fallacy Detection': {
    description:
      'Tag Ad hominem attacks, strawman arguments and other common fallacies',
  },
  'NSFW Detection': {
    description: 'Not safe for work content detection',
  },
  'Customer Sentiment': {
    description:
      'Tag and rate customer sentiment and satisfaction related content',
  },
  'Moderation Category': {
    description: 'Tag and rate content moderation categories',
  },
  'Action Points': {
    description: 'Tag and rate content related to actions and decisions',
  },
  'Decision Points': {
    description: 'Tag and rate content related to decisions',
  },
  'Follow-Up Items': {
    description: 'Tag and rate content related to follow up items and tasks',
  },
  'Meeting Notes': {
    description: 'Tag and rate content related to meeting notes',
  },
  'Question Detection': {
    description: 'Tag and rate questions, queries and inquiries',
  },
  'Sentiment Detection': {
    description: 'Tag and rate content related to sentiment',
  },
  'Urgency Signals': {
    description: 'Tag and rate content related to urgency signals',
  },
  'Meeting Elements': {
    description: 'Tag and rate content related to meeting elements',
  },
  'Place Detection': {
    description: 'Tag content mentioning places both virtual and real',
  },
  'RSVP Detection': {
    description: 'Tag content related to attendance commitments',
  },
  'Time Detection': {
    description: 'Tag content mentioning days and times',
  },
  'Compliance Adherence': {
    description: 'Tag compliance related topics',
  },
  'Key Phrase Detection': {
    description:
      'Tag pre-defined key phrases relating to potential sales and budgets',
  },
  'Objection Handling': {
    description: 'Tag content related to handling customer objections',
  },
  'Product Interest Level': {
    description: 'Tag and rate product interest levels',
  },
  'Upsell Opportunities': {
    description: 'Tag potentially upsell opportunities',
  },
};

export default {
  SMART_TAGS,
  KNOWZ_ASSISTANTS,
};
