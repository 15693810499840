import Modal from '@components/modal';
import useQueryParams from '@hooks/useQueryParams';
import { Container } from '@mui/material';

type Props = React.PropsWithChildren<
  Pick<React.ComponentProps<typeof Modal>, 'width' | 'height' | 'onClose'>
>;

export default function ModalWrapper({ width, height, children }: Props) {
  const { getQueryParamByKey } = useQueryParams();

  const actionQueryParam = getQueryParamByKey<string | null>('action');

  return (
    <Modal
      open={actionQueryParam === 'setup-workspace'}
      width={width}
      height={height}
    >
      <Container sx={{ p: 4 }}>{children}</Container>
    </Modal>
  );
}
