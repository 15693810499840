import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';

export const StyledStack = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(6),
  alignItems: 'center',
  position: 'sticky',
  top: 0,
  zIndex: 1,
  backgroundColor: theme.palette.background.card.light,
  [theme.breakpoints.up('md')]: {
    borderBlockEnd: `1px solid ${theme.palette.divider}`,
  },
  height: theme.spacing(20),
}));

export const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: 0,
  overflowY: 'hidden',
  backgroundColor: theme.palette.background.card.light,
  width: '100%',
  height: '100%',
}));
