import WorkspaceSelector from '@components/workspace/workspace-selector';
import { useTranslation } from '@desygner/ui-common-translation';
import useAuth from '@hooks/useAuth';
import useWorkspace from '@hooks/useWorkspace';
import ToolbarExpansionButton from '@layouts/main/sections/toolbar/components/expand-button';
import CONFIG from '@layouts/main/sections/toolbar/config';
import useToolbar from '@layouts/main/sections/toolbar/hooks/useToolbar';
import CreditUsage from '@layouts/main/sections/toolbar/mini/desktop/sections/credit-usage';
import {
  StyledDesktopMiniToolbar,
  StyledList,
} from '@layouts/main/sections/toolbar/mini/desktop/styles';
import { StyledListItem } from '@layouts/main/sections/toolbar/mini/styles';
import { StyledNavLink } from '@layouts/main/sections/toolbar/styles';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { useRef } from 'react';
import Transition from 'react-transition-group/Transition';

export default function DesktopMiniToolbar() {
  const { t } = useTranslation();

  const {
    toolbarExpansionStatus,
    handleDisplayExpandedButton,
    handleHideExpandedButton,
    isExpandedButtonDisplayed,
  } = useToolbar();

  const { me } = useAuth();
  const { setupMode } = useWorkspace();
  const nodeRef = useRef(null);

  return (
    toolbarExpansionStatus === 'off' && (
      <Transition
        nodeRef={nodeRef}
        in={isExpandedButtonDisplayed}
        timeout={CONFIG.DELAY_HIDE_EXPANDED_BUTTON}
      >
        {(state) => (
          <StyledDesktopMiniToolbar
            onMouseEnter={handleDisplayExpandedButton}
            onMouseLeave={handleHideExpandedButton}
            disableGutters
          >
            <StyledList disablePadding component={Stack}>
              {(!CONFIG.IS_WORKSPACE_SELECTOR_ALPHA || me?.alpha) && (
                <WorkspaceSelector variant="mini" />
              )}
              {CONFIG.TOOLBAR_ITEMS.filter(
                ({ isAlpha }) => me?.alpha || !isAlpha,
              )
                .filter(({ setupMode: singleSetupMode }) =>
                  singleSetupMode.includes(setupMode),
                )
                .map(({ defaultLabel, icon, mappedLabel, url }, i) => (
                  <Tooltip
                    key={i}
                    arrow
                    placement="right"
                    title={t(mappedLabel, { defaultValue: defaultLabel })}
                  >
                    <StyledListItem
                      disablePadding
                      disableGutters
                      component={StyledNavLink}
                      to={url}
                    >
                      <IconButton>{icon}</IconButton>
                    </StyledListItem>
                  </Tooltip>
                ))}
              <CreditUsage />
            </StyledList>
            {state === 'exited' ? null : (
              <ToolbarExpansionButton ref={nodeRef} />
            )}
          </StyledDesktopMiniToolbar>
        )}
      </Transition>
    )
  );
}
