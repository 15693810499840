import { styled } from '@mui/material';
import { useState } from 'react';
import {
  useRootEditor,
  wrapDefinition,
} from 'sequential-workflow-designer-react';
import Button from '@mui/material/Button';
import SingleObject from '../step-editor/SingleObject';
import { InputBlockWithContext } from '../step-editor/InputBlockWithContext';
import {
  StyledProperties,
  StyledModifiableTitle,
} from '@components/pages/command-designer/sections/step-editor/styles';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DownloadIcon from '@mui/icons-material/Download';
import Runner from './Runner';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useTranslation } from '@desygner/ui-common-translation';

import * as Icons from '@mui/icons-material';
import useAppDispatch from '@hooks/useAppDispatch';
import { runCommand } from '@state/slices/flow-run';
import useAppSelector from '@hooks/useAppSelector';
import { useDialogs } from '@toolpad/core';
import TriggerPicker from '@components/pages/command-designer/sections/root-editor/TriggerPicker';

const StyledOptionsContainer = styled('div')`
  margin-bottom: ${({ theme }) => theme.spacing(4)};

  & > button {
    width: 100%;
  }
`;

function downloadDefinition(id: string | number, definition: any) {
  const data =
    'data:text/json;charset=utf-8,' +
    encodeURIComponent(JSON.stringify(definition, null, 2));

  const node = document.createElement('a');
  const name = definition.properties.name || `command-${id}`;

  node.setAttribute('href', data);
  node.setAttribute('download', `${name}.json`);
  document.body.appendChild(node);
  node.click();
  node.remove();
}

const icons = Object.keys(Icons)
  .filter((key) => key.endsWith('TwoTone'))
  .map((label) => ({ label }));

export default function Editor({
  onDefinitionChange,
  commandId,
}: {
  onDefinitionChange: (definition: any) => void;
  commandId: string | number;
}) {
  const dialogs = useDialogs();
  const { t } = useTranslation();
  const { properties, setProperty, definition } = useRootEditor();
  const isEmpty = definition.sequence.length === 0;
  const [importedDefinition, setImportedDefinition] = useState(null);
  const runnerStatus = useAppSelector((state) => state.flowRun.runnerStatus);
  const dispatch = useAppDispatch();

  const [selectedIcon, setSelectedIcon] = useState<{ label: string } | null>(
    () => icons.find((i) => i.label === properties.icon) || null,
  );

  if (runnerStatus !== 'idle') {
    return <Runner />;
  }

  async function handleRun() {
    const eventType = definition?.sequence[0]?.type?.replace(/-trigger$/, '');
    const trigger =
      eventType === 'cron'
        ? { eventType }
        : await dialogs.open(TriggerPicker, eventType);

    if (!trigger) return;
    dispatch(runCommand({ commandId, trigger }));
  }

  return (
    <StyledProperties justifyContent="space-between">
      <Box>
        <StyledModifiableTitle
          value={properties.name || 'Command Name'}
          onChange={(e) => setProperty('name', e.target.value)}
        />

        <InputBlockWithContext description="Select an icon for easily identifying your command">
          <Autocomplete
            disablePortal
            options={icons}
            value={selectedIcon}
            onChange={(event, value) => {
              setProperty('icon', value?.label || null);
              setSelectedIcon(value);
            }}
            renderInput={(params) => {
              if (properties.icon) {
                const { [properties.icon]: Icon } = Icons;
                return (
                  <Stack sx={{ position: 'relative' }}>
                    {Icon && (
                      <Icon
                        sx={{
                          position: 'absolute',
                          top: 1,
                          right: 1,
                          fontSize: 36,
                        }}
                      />
                    )}
                    <TextField
                      {...params}
                      sx={{ pr: 11 }}
                      size="small"
                      label="Icon"
                    />
                  </Stack>
                );
              }

              return <TextField {...params} size="small" label="Icon" />;
            }}
          />
        </InputBlockWithContext>

        <SingleObject
          usage="input"
          name="Command description"
          description="Describe your command for easy reference"
          wrapper="item"
          types={['string']}
          value={{ type: 'string', data: properties.description }}
          onChange={(value) => setProperty('description', value.data)}
        />
      </Box>

      <Box>
        {!isEmpty && (
          <InputBlockWithContext name="Test this Command">
            <StyledOptionsContainer sx={{ mb: 0 }}>
              <Button
                size="large"
                variant="contained"
                icon={{ name: 'editor-animation' }}
                onClick={handleRun}
              >
                Test Run
              </Button>
            </StyledOptionsContainer>
          </InputBlockWithContext>
        )}

        <StyledOptionsContainer>
          {isEmpty ? (
            <>
              <SingleObject
                usage="input"
                name="Import command"
                description="Paste an existing command definition here to import it"
                wrapper="item"
                types={['string']}
                value={{ type: 'string' }}
                onChange={(value) => {
                  const definition = JSON.parse(value.data);

                  definition &&
                    definition.properties &&
                    definition.sequence &&
                    setImportedDefinition(definition);
                }}
              />

              <Button
                startIcon={<AttachFileIcon />}
                disabled={!importedDefinition}
                variant="outlined"
                onClick={() =>
                  onDefinitionChange(wrapDefinition(importedDefinition!))
                }
              >
                {t('page.commands.import', {
                  defaultValue: 'Import flow',
                })}
              </Button>
            </>
          ) : (
            <Button
              icon={{ name: 'download' }}
              variant="outlined"
              onClick={() => {
                downloadDefinition(commandId, definition);
              }}
              startIcon={<DownloadIcon />}
            >
              Export flow
            </Button>
          )}
        </StyledOptionsContainer>
      </Box>
    </StyledProperties>
  );
}
