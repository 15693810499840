import { useState, useEffect } from 'react';
import Markdown from '@components/markdown';
import DocViewer, {
  DocViewerRenderers,
  IDocument,
} from '@cyntler/react-doc-viewer';
import useGetSourceDetailsByIdLazily from '@components/pages/knowledge-base/hooks/useGetSourceDetailsByIdLazily';
import useGetCredentialByKey from '@hooks/useGetCredentialByKey';
import { StyledBox, StyledLoadingBox } from './style';
import { styled } from '@mui/material';
import qs from 'qs';
import paths from '@router/paths';
import { useNavigate } from 'react-router-dom';
import useQueryParams from '@hooks/useQueryParams';
import OpenInFolderButton from '@components/pages/knowledge-base/components/table/cells/action/source-viewer/doc-viewer/open-in-folder-button';

const StyledDocViewer = styled(DocViewer)`
  #proxy-renderer,
  #msdoc-renderer {
    height: 100%;
    width: 100%;
    overflow: auto;
  }
`;

type DocProps = {
  id: number;
  shouldShowOpenInFolder?: boolean;
};

type FileType = 'application/json' | 'json' | 'txt' | 'md' | 'pdf' | 'docx';

const FILE_TYPE_CONFIGS = {
  'application/json': 'json',
  json: 'json',
  txt: 'text',
  md: 'markdown',
  pdf: 'pdf',
  docx: 'docx',
} as const;

interface LoadingRendererProps {
  document?: IDocument;
  fileName?: string;
}

const LoadingRenderer = ({ fileName }: LoadingRendererProps) => {
  const fileText = fileName || '';

  return (
    <StyledLoadingBox>
      {fileText ? (
        <span>Loading {fileText}...</span>
      ) : (
        <span>Loading document...</span>
      )}
    </StyledLoadingBox>
  );
};

export default function Doc({ id, shouldShowOpenInFolder }: DocProps) {
  const { sourceDetailsData } = useGetSourceDetailsByIdLazily({ id });
  const [content, setContent] = useState<string | object>('');
  const [isContentLoading, setIsContentLoading] = useState(false);

  const isShortUrl = sourceDetailsData?.fileType === 'docx';
  const hasHttps = sourceDetailsData?.path?.startsWith('https://');

  const { credentialData, isCredentialDataLoading } = useGetCredentialByKey({
    key: hasHttps ? undefined : sourceDetailsData?.path,
    isShortUrl,
  });

  const fileUrl = hasHttps ? sourceDetailsData?.path : credentialData?.data.url;
  const fileType = sourceDetailsData?.fileType as FileType;

  useEffect(() => {
    if (!fileUrl) return;
    const fetchContent = async () => {
      try {
        const response = await fetch(fileUrl);
        let data;
        if (fileType === 'json') {
          data = await response.json();
        }
        if (fileType === 'txt' || fileType === 'md') {
          data = await response.text();
        }
        setContent(data);
      } catch (error) {
        console.error('Error fetching content:', error);
      } finally {
        setIsContentLoading(false);
      }
    };
    if ((sourceDetailsData?.size ?? 0) >= 15000) {
      setContent('File size is too large to preview');
    } else if (fileType !== 'docx' && fileType !== 'pdf') {
      fetchContent();
    } else {
      setIsContentLoading(false);
    }
  }, [fileType, fileUrl, sourceDetailsData?.size]);

  const { allQueryParams } = useQueryParams();
  const navigateTo = useNavigate();

  const handleShowInFolder = () => {
    if (!sourceDetailsData) return;

    const rootPath = paths.knowledgeBase.pathname;
    const path = qs.stringify({
      ...allQueryParams,
      page: 1, //? reset page to 1 when folder is clicked
      path: sourceDetailsData.folder?.path,
      source: sourceDetailsData.id,
      search: sourceDetailsData.name,
    });
    navigateTo(`${rootPath}?${path}`);
  };

  function renderJsonContent(content: string | object) {
    const isContentObject = typeof content === 'object';
    const formattedJson = isContentObject
      ? JSON.stringify(content, null, 2)
      : content;
    return (
      <>
        {shouldShowOpenInFolder && isContentObject && (
          <OpenInFolderButton handleShowInFolder={handleShowInFolder} />
        )}

        <StyledBox isCentered={!isContentObject}>{formattedJson}</StyledBox>
      </>
    );
  }

  function renderTextContent(textContent: string) {
    return (
      <>
        {shouldShowOpenInFolder && (
          <OpenInFolderButton handleShowInFolder={handleShowInFolder} />
        )}

        <StyledBox>
          <Markdown>{textContent}</Markdown>
        </StyledBox>
      </>
    );
  }

  function renderDocViewer() {
    if (!fileUrl) return null;

    const docs = [
      {
        uri: fileUrl,
        fileType,
        fileName: sourceDetailsData?.name,
      },
    ];

    return (
      <>
        {shouldShowOpenInFolder && (
          <OpenInFolderButton handleShowInFolder={handleShowInFolder} />
        )}

        <StyledDocViewer
          documents={docs}
          pluginRenderers={DocViewerRenderers}
          config={{
            loadingRenderer: {
              overrideComponent: LoadingRenderer,
            },
            pdfVerticalScrollByDefault: true,
            pdfZoom: {
              defaultZoom: 1,
              zoomJump: 0.2,
            },
            header: {
              disableHeader: false,
            },
          }}
        />
      </>
    );
  }

  if (isCredentialDataLoading || isContentLoading) {
    return <LoadingRenderer fileName={sourceDetailsData?.name} />;
  }

  const fileTypeConfig = FILE_TYPE_CONFIGS[fileType];

  switch (fileTypeConfig) {
    case 'json':
      return renderJsonContent(content);
    case 'text':
    case 'markdown':
      if (typeof content === 'object') return;
      return renderTextContent(content);
    case 'pdf':
    case 'docx':
      return renderDocViewer();
    default:
      // fileTypeConfig satisfies never;   //? comment out because it's preventing prettier
      return null;
  }
}
