import { KnowzStepDefinitionType } from '@lib/step/types';

const SendEmailStepDefinition: KnowzStepDefinitionType = {
  order: 403,
  group: 'Knowz Cloud',
  step: {
    componentType: 'task',
    type: 'send-email',
    name: 'Send Email',
  },
  contextTypes: [
    'ref-json',
    'ref-string',
    'ref-source',
    'ref-thread',
    'ref-history-block',
  ],
  propertyTypes: [
    {
      id: 'subject',
      name: 'Smart subject',
      description:
        'Define the subject of the email. You can inline the inputs you defined',
      usage: 'definition',
      wrapper: 'item',
      types: ['string'],
    },
    {
      id: 'body',
      name: 'Smart body',
      description:
        'Define the body of the email. You can inline the inputs you defined',
      usage: 'definition',
      wrapper: 'item',
      types: ['string'],
    },
  ],
};

export default SendEmailStepDefinition;
