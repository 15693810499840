import { styled } from '@mui/material';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';

export const StyledCard = styled(Card)(({ theme }) => ({
  width: theme.spacing(140),
  backgroundColor:
    theme.palette.mode === 'light' ? 'white' : theme.palette.common.black,
  boxShadow: theme.shadows[1],
  position: 'fixed',
  zIndex: theme.zIndex.modal,
  top: theme.spacing(22.5),
  right: theme.spacing(2.5),
  border:
    theme.palette.mode === 'light'
      ? 'none'
      : `1px solid ${theme.palette.grey[800]}`,
  paddingBlockEnd: theme.spacing(3),
})) as typeof Card;

//? Temporary alternative for the FileIcon component
export const FileIcon = styled(Stack)(({ theme }) => ({
  width: 40,
  height: 40,
  backgroundColor: theme.palette.grey[800],
  borderRadius: theme.shape.borderRadius,
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: theme.spacing(2),
}));
