import { styled } from '@mui/material';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

export const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  border: '1px solid',
  borderColor: theme.palette.divider,
}));

export const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  height: '100%',
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
})) as typeof Paper;

export const StyledBox = styled(Box)(() => ({
  overflow: 'auto',
  height: '100%',
}));
