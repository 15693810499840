import Details from '@components/pages/side-kick-session/sections/content/components/feed/list-item/details';
import Summary from '@components/pages/side-kick-session/sections/content/components/feed/list-item/summary';
import MuiAccordion from '@mui/material/Accordion';
import { FactCheckResultType, TimeType } from '@shared-types/sidekick';
import { forwardRef, useCallback, useState } from 'react';
import { flushSync } from 'react-dom';

type Props = Omit<FactCheckResultType[number], 'id'> & {
  index: number;
  setRowHeight: (index: number, size: number) => void;
  avatar: string;
  name: string;
  time: TimeType;
};

const Accordion = forwardRef<HTMLDivElement, Props>(
  (
    { body, outcome, index, avatar, time, name, sources, setRowHeight },
    ref,
  ) => {
    const [isExpandedLocal, setIsExpandedLocal] = useState(false);
    const handleToggle = useCallback(
      (_event: React.SyntheticEvent, isExpanded: boolean) => {
        flushSync(() => {
          setIsExpandedLocal(isExpanded);
        });

        if (ref && 'current' in ref && ref.current) {
          const updatedHeight = ref.current.scrollHeight;
          setRowHeight(index, updatedHeight);
        }
      },
      [index, ref, setRowHeight],
    );

    return (
      <MuiAccordion
        disableGutters
        expanded={isExpandedLocal}
        onChange={handleToggle}
        slotProps={{
          transition: {
            timeout: 0,
          },
        }}
      >
        <Summary time={time} outcome={outcome} avatar={avatar} name={name} />
        <Details reasoning={body} />
      </MuiAccordion>
    );
  },
);

Accordion.displayName = 'Accordion';

export default Accordion;
