import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTranslation } from '@desygner/ui-common-translation';

interface OpenInFolderButtonProps {
  handleShowInFolder: () => void;
}

const OpenInFolderButton: React.FC<OpenInFolderButtonProps> = ({
  handleShowInFolder,
}) => {
  const { t } = useTranslation();

  return (
    <Button
      variant="text"
      onClick={handleShowInFolder}
      sx={{
        backgroundColor: 'grey.500',
        color: 'white',
        borderRadius: 0,
        '&:hover': {
          color: 'grey.300',
        },
      }}
    >
      <Typography variant="h6">
        {t('sourceViewer.docViewer.openInFolder', {
          defaultValue: 'Open in folder',
        })}
      </Typography>
    </Button>
  );
};

export default OpenInFolderButton;
