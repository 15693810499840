import { FolderType } from '@shared-types/folders';
import { SourceType } from '@shared-types/sources';
import { CellContext } from '@tanstack/react-table';
import Stack from '@mui/material/Stack';
import LoadingFolderButton from '@components/pages/knowledge-base/components/loading-folder-button';
import useKnowledgeBaseData from '@components/pages/knowledge-base/hooks/useKnowledgeBaseData';
import { Link, useLocation } from 'react-router-dom';
import useQueryParams from '@hooks/useQueryParams';
import qs from 'qs';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import Typography from '@mui/material/Typography';

export default function Name({
  getValue,
  row,
}: CellContext<FolderType | SourceType, unknown>) {
  const name = getValue<string>();
  const isItAFolder = 'breadcrumb' in row.original;
  const folderId = row.original.id;
  const { pathname } = useLocation();
  const { handleCurrentFolderId } = useKnowledgeBaseData();
  const { allQueryParams } = useQueryParams();

  if (isItAFolder) {
    const path = (row.original as FolderType).path;

    return (
      <Stack direction="row" gap={2}>
        <LoadingFolderButton
          LinkComponent={Link}
          to={{
            pathname,
            search: qs.stringify({
              ...allQueryParams,
              page: 1, //? reset page to 1 when folder is clicked
              path,
            }),
          }}
          onClick={() => handleCurrentFolderId(folderId)}
        >
          {isItAFolder && <FolderOutlinedIcon sx={{ marginInlineEnd: 1 }} />}
          <span style={{ paddingBlockStart: 2 }}>{name}</span>
        </LoadingFolderButton>
      </Stack>
    );
  }

  //? then it is a file
  return name;
}
