import Typography from '@mui/material/Typography';
import { useStopwatch } from 'react-timer-hook';
import Stack from '@mui/material/Stack';

export default function Timer() {
  //TODO: we should use this hook for 10 minutes timer in code verification step in secure account
  const { minutes, seconds, hours } = useStopwatch({
    autoStart: true,
  });

  return (
    <Stack sx={{ width: 100 }}>
      <Typography
        variant="body2"
        sx={{ textAlign: 'center', fontFamily: 'Monospace', lineHeight: 1 }}
      >
        {String(hours).padStart(2, '0')}:{String(minutes).padStart(2, '0')}:
        {String(seconds).padStart(2, '0')}
      </Typography>
    </Stack>
  );
}
