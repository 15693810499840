import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';

export const StyledStack = styled(Stack)(({ theme }) => ({
  borderRadius: theme.spacing(4),
  padding: theme.spacing(8),
}));

export const StyledStackWrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isMostPopular',
})<{ isMostPopular?: boolean }>(({ theme, isMostPopular }) => ({
  flex: 1,
  border: '1px solid',
  borderColor: isMostPopular
    ? theme.palette.warning.main
    : theme.palette.divider,
  borderRadius: theme.shape.borderRadius * 4,
  flexBasis: '100%',
  width: '100%',
  backgroundColor: theme.palette.background.default,
}));
