import { KnowzStepDefinitionType } from '@lib/step/types';

const AutomateDesignStepDefinition: KnowzStepDefinitionType = {
  order: 404,
  group: 'Desygner Cloud',
  step: {
    componentType: 'task',
    type: 'design-automation',
    name: 'Automate Design',
  },
  contextTypes: ['ref-string', 'ref-source'],
  propertyTypes: [
    {
      id: 'placeholders',
      name: 'Smart payload',
      description:
        'Produce a placeholders payload, inlining the inputs you defined',
      usage: 'definition',
      wrapper: 'item',
      types: ['string'],
    },
    {
      id: 'template',
      name: 'Template',
      description: 'Select the template you want to use for the design',
      usage: 'definition',
      wrapper: 'item',
      types: ['id-template'],
    },
    {
      id: 'output',
      name: 'New design reference',
      description:
        'Assign a name to the new design for later reference it in your command',
      usage: 'output',
      wrapper: 'item',
      types: ['ref-design'],
      isOptional: true,
    },
  ],
};

export default AutomateDesignStepDefinition;
