import * as Sentry from '@sentry/react';
import isEmbedded from '@lib/loaderHelper';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './app/app';
import {
  initializeClarity,
  initializeGA,
  initializeGTM,
} from '@analytics/initializer';

type WindowWithMount = Window &
  typeof globalThis & { mountKnowzApp: (node: HTMLElement) => void };

if (isEmbedded) {
  (window as WindowWithMount).mountKnowzApp = initApplication;
} else {
  const rootNode = document.getElementById('root');
  initApplication(rootNode as HTMLElement);
}

const knowz = import.meta.env.VITE_KNOWZ_WEBSITE_URL || '';
const api = import.meta.env.VITE_KNOWZ_API_URL || '';
const auth = import.meta.env.VITE_KNOWZ_AUTH_URL || '';
const websocket = import.meta.env.VITE_KNOWZ_WEB_SOCKET_URL || '';

Sentry.init({
  environment: import.meta.env.PROD ? 'prod' : 'test', // TODO: consider other ways of doing this
  dsn: import.meta.env.VITE_SENTRY_DSN || '',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracePropagationTargets: [
    'localhost',
    new RegExp(`^${knowz}`.replace(/\./g, '\\.')),
    new RegExp(`^${api}`.replace(/\./g, '\\.')),
    new RegExp(`^${auth}`.replace(/\./g, '\\.')),
    new RegExp(`^${websocket}`.replace(/\./g, '\\.')),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

initializeGTM();
initializeGA();
initializeClarity();

function initApplication(node: HTMLElement) {
  const root = ReactDOM.createRoot(node);

  root.render(
    <StrictMode>
      <App />
    </StrictMode>,
  );

  return root;
}
