import useAuth from '@hooks/useAuth';
import { memberships } from '@lib/agent';
import { useQuery } from '@tanstack/react-query';

type getMembershipType = {
  id?: string;
};

//? even though we globally enable all queries after isUserAuthenticated, but if we use enabled flag inside an individual query, it takes precedence so we need to check user authentication as well for these queries.
function isQueryEnabled({
  id,
  isUserAuthenticated,
}: {
  id?: string;
  isUserAuthenticated: boolean;
}) {
  return !!id && isUserAuthenticated;
}

export default function useGetMembershipByWorkspaceIdLazily({
  id,
}: getMembershipType) {
  const { me, isUserAuthenticated } = useAuth();
  const { data: membershipData } = useQuery({
    queryKey: [id, me?.uuid],
    queryFn: async () => {
      if (!id) return null;

      const { data } = await memberships.getAllMine({
        workspaceId: id,
      });

      return data[0];
    },
    enabled: isQueryEnabled({ id, isUserAuthenticated }),
    staleTime: Infinity, // TODO: fix this!
  });

  return { membershipData };
}
