import useCollectAllSmartTags from '@components/pages/side-kick/hooks/useGetAllSmartTags';
import useAppDispatch from '@hooks/useAppDispatch';
import { groupedTrainingSetsUpdated } from '@state/slices/transcription';
import { useEffect } from 'react';

export default function useAutoSelectAllTagsOnMount() {
  const dispatch = useAppDispatch();
  const { collectedSmartTags, areCollectedSmartTagsSuccessfullyLoaded } =
    useCollectAllSmartTags();

  useEffect(() => {
    if (areCollectedSmartTagsSuccessfullyLoaded) {
      dispatch(
        groupedTrainingSetsUpdated({
          actionPointsLogger: collectedSmartTags.actionPointsLogger.items.map(
            (item) => item.id,
          ),
          conversationModeration:
            collectedSmartTags.conversationModeration.items.map(
              (item) => item.id,
            ),
          minutesTracker: collectedSmartTags.minutesTracker.items.map(
            (item) => item.id,
          ),
          salesConversationAnalyser:
            collectedSmartTags.salesConversationAnalyser.items.map(
              (item) => item.id,
            ),
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areCollectedSmartTagsSuccessfullyLoaded]);
}
