import FormProvider from '@components/react-hook-form/FormProvider';
import RHFTextField from '@components/react-hook-form/RHFTextField';
import FormHeading from '@components/user-authentication/components/form-heading';
import usePromotiveSignUp from '@components/user-authentication/hooks/usePromotiveSignUp';
import useUserAuth from '@components/user-authentication/hooks/useUserAuth';
import { setupNameSchema } from '@components/user-authentication/schemas';
import { useTranslation } from '@desygner/ui-common-translation';
import { yupResolver } from '@hookform/resolvers/yup';
import useAcceptInvitation from '@hooks/useAcceptInvitation';
import useAuth from '@hooks/useAuth';
import useQueryParams from '@hooks/useQueryParams';
import { setSession } from '@lib/jwt';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

const defaultValues = {
  first_name: '',
};

type SetupNameFormType = {
  first_name: string;
};

export default function SetupName() {
  const { code, email, password, isSubscribe, handlePassword } = useUserAuth();

  const {
    setQueryParam,
    getQueryParamByKey,
    removeAllQueryParamsNoMatterWhat,
  } = useQueryParams();

  const { t } = useTranslation();

  const { handleUpdateMe } = useAuth();

  const methods = useForm<SetupNameFormType>({
    resolver: yupResolver(setupNameSchema),
    defaultValues,
  });

  const {
    formState: { errors, isDirty, isSubmitting },
    handleSubmit,
  } = methods;

  const { isPromotiveSignUpPending, mutatePromotiveSignUpAsync } =
    usePromotiveSignUp({
      onSuccess: (data) => {
        const token = data!.data.token;
        window.localStorage.removeItem('fingerPrintId');
        setSession(token);
        handleUpdateMe(token);
        toast.success(
          t('response.success.signUp', {
            defaultValue: 'Your account created successfully.',
          }),
        );
        setQueryParam('action', '');
      },
    });

  const invitationToken = getQueryParamByKey<string | null>('token');

  const { mutateAcceptInvitationAsync } = useAcceptInvitation({
    onSuccess: () => {
      toast.success(
        t('page.knowledgeBase.member.membership.accepted', {
          defaultValue: 'You have successfully accepted this invitation.',
        }),
      );
    },
  });

  const isBusy = isSubmitting || isPromotiveSignUpPending;

  async function onSubmit(data: SetupNameFormType) {
    await mutatePromotiveSignUpAsync({
      code,
      email,
      first_name: data.first_name,
      password,
      is_subscribe_marketing: isSubscribe,
    });
    if (invitationToken) {
      await mutateAcceptInvitationAsync({
        token: invitationToken,
      });
    }
    //? we remove password from state for security reasons
    handlePassword('');
    removeAllQueryParamsNoMatterWhat();
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <FormHeading
        heading={t('layout.auth.desktop.modal.steps.setupName.label', {
          defaultValue: 'Create your account',
        })}
        withBackButton
      />
      <Stack gap={5}>
        <Typography component="span" variant="body2">
          {t('layout.auth.desktop.modal.steps.setupName.description', {
            defaultValue: 'Tell us a bit about yourself.',
          })}
        </Typography>
        <RHFTextField
          size="small"
          placeholder={t(
            'layout.auth.desktop.modal.steps.setupName.form.name.placeholder',
            {
              defaultValue: 'Your name',
            },
          )}
          disabled={isBusy}
          name="first_name"
          error={!!errors.first_name?.message}
          fullWidth
          helperText={errors.first_name?.message}
        />
        <LoadingButton
          variant="contained"
          color="primary"
          disabled={!isDirty || isBusy}
          onClick={handleSubmit(onSubmit)}
          loading={isBusy}
          fullWidth
        >
          {t('layout.auth.desktop.modal.steps.setupName.form.button', {
            defaultValue: 'Create your account',
          })}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
