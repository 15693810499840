import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { BreakpointValues } from '@shared-types/theme';

export type Query = 'up' | 'down' | 'between';

export default function useResponsive(
  query: Query,
  start?: BreakpointValues,
  end?: BreakpointValues,
) {
  const theme = useTheme();

  const mediaUp = useMediaQuery(
    theme.breakpoints.up(start as BreakpointValues),
  );

  const mediaDown = useMediaQuery(
    theme.breakpoints.down(start as BreakpointValues),
  );

  const mediaBetween = useMediaQuery(
    theme.breakpoints.between(
      start as BreakpointValues,
      end as BreakpointValues,
    ),
  );

  if (query === 'up') {
    return mediaUp;
  }

  if (query === 'down') {
    return mediaDown;
  }

  return mediaBetween;
}
