import { styled } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: '100%',
  marginLeft: theme.spacing(2),
  '&, &:hover': {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
}));

export const StyledStack = styled(Stack)(() => ({
  alignItems: 'center',
  width: '100%',
  overflow: 'auto',
}));
