import paths from '@router/paths';

const DESKTOP_NAV_ITEMS = [
  {
    label: 'layout.main.sections.header.navigation.enterpriseSolution',
    defaultLabel: 'Enterprise Solutions',
    link: paths.enterprise.pathname,
  },
];

const MOBILE_NAV_ITEMS = [
  {
    label: 'layout.main.sections.header.navigation.enterpriseSolution',
    defaultLabel: 'Enterprise Solutions',
    link: paths.enterprise.pathname,
  },
  {
    label: 'layout.main.sections.header.navigation.tos',
    defaultLabel: 'Terms of Service',
    link: paths.tos.pathname,
  },
  {
    label: 'layout.main.sections.header.navigation.pp',
    defaultLabel: 'Privacy Policy',
    link: paths.pp.pathname,
  },
];

const CONFIG = {
  DRAWER_WIDTH: 280,
  DESKTOP_NAV_ITEMS,
  MOBILE_NAV_ITEMS,
};

export default CONFIG;
