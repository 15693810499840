import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { FileIcon, StyledCard } from '@components/knowledge-info-card/styles';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Box from '@mui/material/Box';
import { useTranslation } from '@desygner/ui-common-translation';
import useGetSourceDetailsByIdLazily from '@components/pages/knowledge-base/hooks/useGetSourceDetailsByIdLazily';
import Stack from '@mui/material/Stack';
import ButtonBase from '@mui/material/ButtonBase';
import { knowledgeLinksType } from '@shared-types/search-result/streaming';
import useGetCredentialByKey from '@hooks/useGetCredentialByKey';
import EllipsisTypography from '@components/ellipsis-typography';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import removeMd from 'remove-markdown';
import Skeleton from '@mui/material/Skeleton';
import FileTxtIcon from '~icons/knowz-iconify/file-txt-solid';
import { Height } from '@mui/icons-material';

type KnowledgeCardProps = {
  isOpen?: boolean;
  onClose: () => void;
  metadata?: knowledgeLinksType;
};

export default function KnowledgeInfoCard({
  isOpen,
  onClose,
  metadata,
}: KnowledgeCardProps) {
  const { t } = useTranslation();
  const { sourceDetailsData, isSourceDetailsLoading } =
    useGetSourceDetailsByIdLazily({
      id: Number(metadata?.source_id),
    });
  const hasHttps = sourceDetailsData?.path?.startsWith('https://');
  const { credentialData, isCredentialDataLoading } = useGetCredentialByKey({
    key: hasHttps ? undefined : sourceDetailsData?.path,
  });
  const theme = useTheme();

  if (!isOpen || !metadata) return null;

  function handleLinkPath() {
    let url = '';
    if (metadata?.link && metadata?.link !== 'null') {
      url = metadata.link;
    } else if (
      sourceDetailsData?.originalPath &&
      sourceDetailsData?.originalPath !== 'null'
    ) {
      url = sourceDetailsData.originalPath;
    } else if (
      sourceDetailsData?.sourceConfiguration?.handler?.toLowerCase() ===
      'website'
    ) {
      url = `https://${sourceDetailsData.folder?.name}${sourceDetailsData.name}`;
    } else {
      url = credentialData?.data?.url || '';
    }

    if (url) {
      window.open(url, '_blank');
    }
  }

  if (isSourceDetailsLoading || isCredentialDataLoading) {
    return (
      <StyledCard component={Stack} direction="row" gap={2}>
        <Box>
          <Skeleton variant="rectangular" width={40} height={40} />
        </Box>
        <Box sx={{ flex: 1 }}>
          <Skeleton variant="text" width="60%" />
          <Skeleton variant="text" width="80%" />
          <Skeleton variant="text" width="40%" />
        </Box>
      </StyledCard>
    );
  }

  return (
    <StyledCard component={Stack} direction="row" gap={2}>
      <Box>
        <FileTxtIcon style={{ fontSize: '2em' }} />
      </Box>

      <Box sx={{ flex: 1 }}>
        <Typography
          variant="subtitle2"
          sx={{
            marginBlockEnd: 1,
            color: theme.palette.mode === 'light' ? 'grey.900' : 'white.600',
          }}
        >
          {t('components.knowledge-info-card.source', {
            defaultValue: sourceDetailsData?.name
              ? sourceDetailsData?.name
              : sourceDetailsData?.folder?.name,
          })}
        </Typography>

        <EllipsisTypography
          variant="body2"
          lineClamp={3}
          sx={{
            color: theme.palette.mode === 'light' ? 'grey.800' : 'grey.200',
          }}
        >
          <Typography variant="h6" component="span">
            Preview:
          </Typography>{' '}
          {removeMd(metadata.originalText)}
        </EllipsisTypography>

        <Stack sx={{ flexDirection: 'row', gap: 2 }}>
          <ButtonBase onClick={onClose}>
            <Typography
              variant="h6"
              sx={{
                color: theme.palette.mode === 'light' ? 'grey.800' : 'grey.200',
              }}
            >
              {t('components.knowledge-info-card.dismiss', {
                defaultValue: 'Dismiss',
              })}
            </Typography>
          </ButtonBase>

          <Button
            onClick={handleLinkPath}
            size="small"
            sx={{
              color:
                theme.palette.mode === 'light'
                  ? theme.palette.info.main
                  : 'white',
            }}
            endIcon={<OpenInNewIcon />}
          >
            <Typography
              variant="h6"
              sx={{
                color:
                  theme.palette.mode === 'light'
                    ? theme.palette.info.main
                    : 'white',
              }}
            >
              {t('components.knowledge-info-card.go-to-file', {
                defaultValue: 'Go to File',
              })}
            </Typography>
          </Button>
        </Stack>
      </Box>

      <Box>
        <IconButton
          onClick={onClose}
          sx={{ color: 'grey.300', '&:hover': { color: 'grey.300' } }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </StyledCard>
  );
}
