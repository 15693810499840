import CreditQuantity from '@layouts/main/sections/toolbar/components/credit-quantity';
import {
  StyledBox,
  StyledStack,
} from '@layouts/main/sections/toolbar/mini/desktop/sections/credit-usage/styles';
import Tooltip from '@layouts/main/sections/toolbar/mini/desktop/sections/credit-usage/tooltip';
import MuiLink from '@mui/material/Link';
import paths from '@router/paths';
import { Link } from 'react-router-dom';

export default function CreditUsage() {
  return (
    <MuiLink component={Link} to={paths.upgradeAccount.pathname}>
      <Tooltip>
        <StyledBox>
          <StyledStack direction="column" gap={2}>
            <CreditQuantity variant="miniDesktop" />
          </StyledStack>
        </StyledBox>
      </Tooltip>
    </MuiLink>
  );
}
