import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SmartTagGroupIdType } from '@shared-types/sidekick';
import { SelectedAssistantsType } from '@shared-types/transcription';

export type initialStateType = {
  selectedAssistants: SelectedAssistantsType;
  groupedTrainingSets: { [key in SmartTagGroupIdType]: number[] };
};

const initialState: initialStateType = {
  selectedAssistants: {
    factChecker: true,
    knowzBot: true,
  },
  groupedTrainingSets: {
    actionPointsLogger: [],
    conversationModeration: [],
    minutesTracker: [],
    salesConversationAnalyser: [],
  },
};

const transcription = createSlice({
  name: 'transcription',
  initialState,
  reducers: {
    selectedAssistantsUpdated: (
      state,
      action: PayloadAction<SelectedAssistantsType>,
    ) => ({ ...state, selectedAssistants: action.payload }),
    groupedTrainingSetsUpdated: (
      state,
      action: PayloadAction<initialStateType['groupedTrainingSets']>,
    ) => ({
      ...state,
      groupedTrainingSets: {
        ...state.groupedTrainingSets,
        ...action.payload,
      },
    }),
  },
});

export const { groupedTrainingSetsUpdated, selectedAssistantsUpdated } =
  transcription.actions;

export default transcription.reducer;
