import { SearchModeType } from '@components/search-form/types';
import { useTranslation } from '@desygner/ui-common-translation';
import useAppDispatch from '@hooks/useAppDispatch';
import useAppSelector from '@hooks/useAppSelector';
import useAuth from '@hooks/useAuth';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Button from '@mui/material/Button';
import useTheme from '@mui/material/styles/useTheme';
import Typography from '@mui/material/Typography';
import { modeUpdated } from '@state/slices/search-result';
import { useState } from 'react';

import SimpleDropdown from '@components/dropdown/simple';
import CONFIG from '@components/search-form/config';
import {
  StyledContentWrapper,
  StyledStack,
  StyledStackMode,
  StyledTypographyDescription,
  StyledTypographyName,
} from '@components/search-form/react-hook-form-search-mode-select/styles';
import Tooltip from '@components/search-form/react-hook-form-search-mode-select/tooltip';
import useQueryParams from '@hooks/useQueryParams';
import { Controller, useFormContext } from 'react-hook-form';
import useGetCreditAsPercentage from '@hooks/useGetCreditAsPercentage';

type Props = {
  name: string;
};

export default function RHFSearchModeSelect({ name }: Props) {
  const { me } = useAuth();

  const { setQueryParam } = useQueryParams();

  const { creditsData } = useGetCreditAsPercentage();

  const hasSecureAccount =
    me?.auth_type === 'email' || me?.auth_type === 'oauth2';
  const hasCredits = creditsData && creditsData > 0;
  const searchMode = useAppSelector((state) => state.searchResult.mode);

  const [isModeDropDownOpen, setIsModeDropDownOpen] = useState<boolean>(false);

  const theme = useTheme();

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { control } = useFormContext();

  function handleModeDropdownClick() {
    if (!hasSecureAccount) {
      setQueryParam('action', 'secure-account');
      return null;
    }
    if (!hasCredits) {
      setQueryParam('action', 'upgrade-account');
      return null;
    }

    setIsModeDropDownOpen(!isModeDropDownOpen);
  }

  function handleModeSelectClick(selectedMode: keyof SearchModeType) {
    dispatch(modeUpdated(selectedMode));
  }

  return (
    <StyledStack direction="row">
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange } }) => {
          return (
            <SimpleDropdown
              disablePortal
              sx={{
                menuItemSx: {
                  p: 0,
                },
                popperSx: {
                  width: '100%',
                  transform: `translate(0, -${120 / 2.2}px)!important`,
                },
              }}
              placement="top-end"
              modifiers={[
                {
                  data: {
                    transform: '0',
                  },
                  name: 'offset',
                  options: {
                    offset: [8, 20],
                  },
                },
              ]}
              options={Object.values(CONFIG.SEARCH_MODES).map(
                ({ description, icon, name, title }) => {
                  return {
                    text: (
                      <StyledStackMode
                        direction="row"
                        sx={{
                          backgroundColor:
                            name === searchMode
                              ? 'background.card.light'
                              : 'transparent',
                        }}
                      >
                        {icon}
                        <StyledContentWrapper>
                          <StyledTypographyName variant="subtitle1">
                            {t('page.home.hero.search.mode.name', {
                              defaultValue: title,
                            })}
                          </StyledTypographyName>
                          <StyledTypographyDescription>
                            {t('page.home.hero.search.mode.description', {
                              defaultValue: description,
                            })}
                          </StyledTypographyDescription>
                        </StyledContentWrapper>
                      </StyledStackMode>
                    ),
                    onClick: () => {
                      handleModeSelectClick(name);
                      onChange(name);
                    },
                    value: name,
                  };
                },
              )}
              trigger={{
                element: (
                  <Button
                    disableFocusRipple={true}
                    disableRipple={true}
                    sx={{
                      pr: 1,
                      color: theme.palette.text.secondary,
                    }}
                    endIcon={
                      isModeDropDownOpen ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )
                    }
                  >
                    <Tooltip>
                      <Typography variant="body1" color="textPrimary">
                        {t('page.home.hero.search.mode', {
                          defaultValue: CONFIG.SEARCH_MODES[searchMode].title,
                        })}
                      </Typography>
                    </Tooltip>
                  </Button>
                ),
                onClick: () => {
                  handleModeDropdownClick();
                },
              }}
            />
          );
        }}
      />
    </StyledStack>
  );
}
