import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';

export const StyledStackWrapper = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(2, 4),
}));

export const StyledStack = styled(Stack)(() => ({
  alignItems: 'center',
  flexWrap: 'wrap',
}));
