import CreditQuantity from '@layouts/main/sections/toolbar/components/credit-quantity';
import {
  StyledLink,
  StyledStack,
} from '@layouts/main/sections/toolbar/mini/mobile/sections/credit-usage/styles';
import paths from '@router/paths';
import { Link } from 'react-router-dom';

export default function CreditUsage() {
  return (
    <StyledLink component={Link} to={paths.upgradeAccount.pathname}>
      <StyledStack direction="row" gap={2}>
        <CreditQuantity variant="miniMobile" />
      </StyledStack>
    </StyledLink>
  );
}
