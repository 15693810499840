import { useTranslation } from '@desygner/ui-common-translation';
import useResponsive from '@hooks/useResponsive';
import useTimer from '@hooks/useTimer';
import { useRoomContext } from '@livekit/components-react';
import paths from '@router/paths';
import { useConfirm } from 'material-ui-confirm';
import { useEffect } from 'react';
import { useBlocker, useNavigate } from 'react-router-dom';
import Desktop from './desktop';
import Mobile from './mobile';

export default function Content() {
  const isItDesktop = useResponsive('up', 'md');

  const room = useRoomContext();

  const { t } = useTranslation();

  const timer = useTimer();

  const confirm = useConfirm();

  const navigateTo = useNavigate();

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname,
  );

  useEffect(() => {
    if (blocker.state === 'blocked') {
      (function () {
        confirm({
          title: t('page.sideKickSession.confirmLeave.title', {
            defaultValue: 'Leave Recording Session',
          }),
          description: t('page.sideKickSession.confirmLeave.content', {
            defaultValue: 'Are you sure you want to leave recording?',
          }),
          confirmationText: t('page.sideKickSession.confirmLeave.yes', {
            defaultValue: 'Yes, I want to leave',
          }),
          cancellationText: t('page.sideKickSession.confirmLeave.no', {
            defaultValue: 'No, I want to continue',
          }),
        })
          .then(() => {
            navigateTo(paths.sideKick.pathname);
            timer.stopTimer();
            blocker.proceed();

            const encoder = new TextEncoder();
            const encodedData = encoder.encode('{}');
            room.localParticipant.publishData(encodedData, {
              reliable: true,
              topic: 'client_shut',
            });
          })
          .catch(() => {
            blocker.reset();
          });
      })();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blocker, blocker.state]);

  if (isItDesktop) {
    return <Desktop />;
  }

  return <Mobile />;
}
