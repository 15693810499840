import { useTranslation } from '@desygner/ui-common-translation';
import useWorkspace from '@hooks/useWorkspace';
import {
  StyledAlert,
  StyledButton,
  StyledDivider,
  StyledStack,
} from '@layouts/components/header-wrapper/setup-mode-alert/styles';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import { useEffect, useRef } from 'react';

export default function SetupModeAlert() {
  const { setupMode, handleSetupMode, handleSetAlertHeight } = useWorkspace();
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (setupMode === 'on') {
      handleSetAlertHeight(containerRef.current!.offsetHeight);
    } else {
      handleSetAlertHeight(0);
    }
  }, [handleSetAlertHeight, setupMode]);

  if (setupMode === 'off' || setupMode == null) return null;

  return (
    <Slide in={setupMode === 'on'} ref={containerRef}>
      <StyledAlert icon={false}>
        <StyledStack gap={5}>
          <Typography variant="subtitle2" color="white">
            {t('main.sections.header.setupMode.alert', {
              defaultValue:
                'You are using Knowz In Setup Mode, any configuration changes will affect members',
            })}
          </Typography>
          <StyledDivider orientation="vertical" />
          <StyledButton variant="text" onClick={() => handleSetupMode('off')}>
            {t('main.sections.header.exitSetupMode', {
              defaultValue: 'Exit Setup Mode',
            })}
          </StyledButton>
        </StyledStack>
      </StyledAlert>
    </Slide>
  );
}
