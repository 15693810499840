import ReactGA from 'react-ga4';

type Props = {
  userUUID: string;
  currency: string;
  transactionId: string;
  status: 'success' | 'abort';
  item: {
    plan:
      | 'Genius Search Plan monthly'
      | 'Genius Search Plan yearly'
      | 'Credit Pack'
      | 'Genius Unlimited';
    id: number;
    price: number;
  };
};

//? https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=18260565123410081788-EU&client_type=gtag#purchase
export default function sendCreditsPurchasedToAnalytics({
  currency,
  userUUID,
  status,
  transactionId,
  item,
}: Props) {
  ReactGA.gtag('event', 'purchase', {
    user_uuid: userUUID,
    transaction_id: transactionId,
    currency,
    status,
    value: item.price, //? revenue
    //? we only pass one item since we do not have multiple plan purchase capability in the business logic
    items: [
      {
        item_name: item.plan,
        price: item.price,
        id: item.id,
        quantity: 1,
      },
    ],
  });
}
