/* eslint-disable import/prefer-default-export */
import requests from '@lib/agent/requests';
import axiosAwsInstance from '@lib/agent/services/aws/axiosInstance';
import axiosApiInstance from '@lib/agent/services/api/axiosInstance';
import {
  CommandType,
  CommandMinType,
  CommandDefinition,
  UpdateOneCommandParamsType,
  CommandTriggerType,
} from '@shared-types/agent';

type CommandSubscriptionType = {
  status: 'ok';
  runToken: string;
  expiresInSeconds: number;
};

type CommandRunType = {
  status: 'ok';
  runToken: string;
  threadId: string;
};

export const commands = {
  subscribe: (commandId: number | string) =>
    requests(axiosApiInstance).get<CommandSubscriptionType>(
      `/v1/actions/command/${commandId}/subscribe`,
    ),
  trigger: (commandId: number | string, body: CommandTriggerType) =>
    requests(axiosApiInstance).post<CommandRunType>(
      `/v1/actions/command/${commandId}/trigger`,
      body,
    ),
  createOneCommand: (body: CommandMinType) =>
    requests(axiosApiInstance).post<CommandType>('/v1/entities/commands', body),
  updateOneCommand: (
    commandId: number | string,
    body: UpdateOneCommandParamsType,
  ) =>
    requests(axiosApiInstance).patch<CommandType>(
      `/v1/entities/commands/${commandId}`,
      body,
    ),
  deleteOneCommand: (commandId: number | string) =>
    requests(axiosApiInstance).delete(`/v1/entities/commands/${commandId}`),
  getAllCommands: () =>
    requests(axiosApiInstance).get<CommandMinType[]>('/v1/entities/commands'),
  getCommand: (commandId: number | string) =>
    requests(axiosApiInstance).get<CommandType>(
      `/v1/entities/commands/${commandId}`,
    ),
  getCommandDefinition: (signedUrl: string) =>
    requests(axiosAwsInstance).get<CommandDefinition>(signedUrl),
};
