import { Theme } from '@mui/material';

export default function Skeleton(theme: Theme) {
  return {
    MuiSkeleton: {
      styleOverrides: {
        root: {
          borderRadius: theme.shape.borderRadius * 2,
        },
      },
    },
  };
}
