import useLiveKitTranscriber from '@components/pages/side-kick-session/hooks/useLiveKitTranscriber';
import Card from '@components/pages/side-kick-session/sections/content/components/transcript/card';
import useScrollIntoView from '@hooks/useScrollIntoView';
import useTimer from '@hooks/useTimer';
import { useRoomContext } from '@livekit/components-react';
import { useRef } from 'react';

type Props = {
  disableAutoScrolling?: boolean;
};

export default function LiveKitAudioTranscriber({
  disableAutoScrolling,
}: Props) {
  const { getTime } = useTimer();
  const room = useRoomContext();

  const { transcript } = useLiveKitTranscriber(room);

  const chatRef = useRef(null);

  useScrollIntoView(chatRef, [transcript], disableAutoScrolling);

  if (transcript === null) return null;

  return (
    <li ref={chatRef}>
      <Card
        labels={{
          factChecks: null,
          sentiments: null,
        }}
        content={transcript}
        time={{
          minutes: getTime().minutes,
          seconds: getTime().seconds,
        }}
      />
    </li>
  );
}
