import KnowzAssistantCard from '@components/pages/side-kick/sections/cards/knowz-assistant-card';
import SmartTagsCard from '@components/pages/side-kick/sections/cards/smart-tags-card';
import StartRecording from '@components/pages/side-kick/sections/start-recording';
import { StyledPaper, StyledBox } from '@components/pages/side-kick/styles';
import {
  StyledContainerForFixedHeader,
  StyledViewportHeightStack,
} from '@components/pages/styles';
import { useTranslation } from '@desygner/ui-common-translation';
import MAIN_LAYOUT_CONFIG from '@layouts/main/config';
import { convertToRem } from '@lib/fonts';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export default function SideKick() {
  const { t } = useTranslation();

  return (
    <StyledContainerForFixedHeader
      disableGutters
      maxWidth={false}
      sx={{ paddingBlockEnd: 0 }}
    >
      <StyledViewportHeightStack
        gap={2.5}
        sx={{
          height: `calc(100svh - ${convertToRem(
            MAIN_LAYOUT_CONFIG.HEADER.HEIGHT +
              MAIN_LAYOUT_CONFIG.HEADER.PADDING_Y * 2,
          )})`,
        }}
      >
        <StyledPaper component={Stack}>
          <Stack>
            <Typography variant="subtitle1">
              {t('page.sideKick.grids.transcribe.title', {
                defaultValue: 'Live',
              })}
            </Typography>
            <StartRecording />
          </Stack>
          <StyledBox>
            <Stack gap={4} sx={{ minHeight: '100%' }}>
              <Typography variant="subtitle1">
                {t('page.sideKick.grids.mode.title', {
                  defaultValue: 'Configure your session',
                })}
              </Typography>
              <KnowzAssistantCard />
              <SmartTagsCard />
            </Stack>
          </StyledBox>
        </StyledPaper>
      </StyledViewportHeightStack>
    </StyledContainerForFixedHeader>
  );
}
