import Markdown from '@components/markdown';
import Card from '@mui/material/Card';
import { knowledgeLinksType } from '@shared-types/search-result/streaming';

type Props = {
  text: string | null;
  links?: Array<knowledgeLinksType>;
  knowledgeCard?: {
    isOpen: boolean;
    metadata: knowledgeLinksType;
  };
  setKnowledgeCard?: (value: {
    isOpen: boolean;
    metadata: knowledgeLinksType;
  }) => void;
};

export default function Info({
  text,
  links,
  knowledgeCard,
  setKnowledgeCard,
}: Props) {
  if (text == null) return null;

  return (
    <Card>
      <Markdown
        links={links}
        knowledgeCard={knowledgeCard}
        setKnowledgeCard={setKnowledgeCard}
      >
        {text}
      </Markdown>
    </Card>
  );
}
