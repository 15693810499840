import { styled } from '@mui/material';
import Stack from '@mui/material/Stack';
import CONFIG from '@layouts/main/sections/toolbar/config';
import Link from '@mui/material/Link';

export const StyledStack = styled(Stack)(({ theme }) => ({
  paddingTop: 0,
  paddingBottom: 0,
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    paddingRight: theme.spacing(0),
    paddingLeft: theme.spacing(0),
  },
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  backgroundColor: theme.palette.background.card.light,
  borderRadius: '50%',
  alignItems: 'center',
  width: 'auto',
  height: CONFIG.CREDIT_USAGE.MOBILE.HEIGHT,
  [theme.breakpoints.up('md')]: {
    width: CONFIG.CREDIT_USAGE.DESKTOP.WIDTH,
    height: 'auto',
  },
})) as typeof Link;
