import { KnowzStepDefinitionType } from '@lib/step/types';

const StructuredStepDefinition: KnowzStepDefinitionType = {
  order: 203,
  group: 'AI',
  step: {
    componentType: 'task',
    type: 'structured',
    name: 'AI Structured',
  },
  contextTypes: [
    'ref-json',
    'ref-string',
    'ref-source',
    'ref-thread',
    'ref-history-block',
  ],
  propertyTypes: [
    {
      id: 'system',
      name: 'Smart instructions',
      description: 'Define system instructions for the AI assistant to follow',
      usage: 'definition',
      wrapper: 'item',
      types: ['string'],
    },
    {
      id: 'prompt',
      name: 'Smart prompt',
      description:
        'Describe the task to the AI assistant, inlining the inputs you defined',
      usage: 'definition',
      wrapper: 'item',
      types: ['string'],
    },
    {
      id: 'structure',
      name: 'Expected structure',
      description:
        'Define the expected structure of the output in JSON schema notation',
      usage: 'definition',
      variant: 'short',
      wrapper: 'item',
      types: ['string'],
    },
    {
      id: 'output',
      name: 'Result reference',
      description:
        'Assign a name to the resulting text, for later referencing in your command',
      usage: 'output',
      variant: 'optional',
      wrapper: 'item',
      types: ['ref-json'],
    },
  ],
};

export default StructuredStepDefinition;
