import { useStepEditor } from 'sequential-workflow-designer-react';
import { BranchedStep, Branches } from 'sequential-workflow-model';
import InputBlock from './InputBlock';
import { BagItem } from './InputBlockWithContext';
import { getStepPropertiesDefinition } from '@components/pages/command-designer/config';
import Runner from '@components/pages/command-designer/sections/root-editor/Runner';
import {
  StyledModifiableTitle,
  StyledProperties,
} from '@components/pages/command-designer/sections/step-editor/styles';
import useAppSelector from '@hooks/useAppSelector';

export default function Editor() {
  const {
    type,
    componentType,
    name,
    setName,
    properties,
    setProperty,
    step,
    notifyChildrenChanged,
  } = useStepEditor();

  const runnerStatus = useAppSelector((state) => state.flowRun.runnerStatus);

  const stepPropertiesDefinition = getStepPropertiesDefinition({
    componentType,
    type,
    name,
  });

  function onNameChanged(value: string) {
    setName(value);
  }

  function onPropertyChanged(propertyId: string, value: BagItem | BagItem[]) {
    if (step.componentType === 'switch' && propertyId === 'branches') {
      value = (value as BagItem[]).filter((v) => v.data);

      const { branches } = step as BranchedStep;
      const existingBranches = Object.keys(branches);

      if (existingBranches.length < value.length) {
        for (const { data } of value) {
          if (data && !branches[data]) branches[data] = [];
        }
      } else if (existingBranches.length > value.length) {
        if (value.length >= 2) {
          for (const branch of existingBranches) {
            if (!value.find((v) => v.data === branch)) {
              delete branches[branch];
            }
          }
        } else {
          renameBranch(branches, value);
        }
      } else {
        renameBranch(branches, value);
      }

      setTimeout(() => notifyChildrenChanged());
    }

    setProperty(propertyId, value);
  }

  function renameBranch(branches: Branches, value: BagItem[]) {
    const existingBranches = Object.keys(branches);

    let newBranchName: string | null = null;
    let oldBranchName: string | null = null;

    for (const { data } of value) {
      if (data && !branches[data]) {
        newBranchName = data as string;
        break;
      }
    }

    for (const branch of existingBranches) {
      if (!value.find((v) => v.data === branch)) {
        oldBranchName = branch;
        break;
      }
    }

    if (newBranchName && oldBranchName) {
      branches[newBranchName] = branches[oldBranchName];
      delete branches[oldBranchName];
    }
  }

  if (runnerStatus !== 'idle') {
    return <Runner />;
  }

  return (
    <StyledProperties>
      <StyledModifiableTitle
        value={name}
        onChange={(e) => onNameChanged(e.target.value)}
      />

      {stepPropertiesDefinition.map((property) => (
        <InputBlock
          {...property}
          key={`property-${property.id}`}
          value={(properties && properties[property.id]) || ''}
          onChange={(value: BagItem | BagItem[]) =>
            onPropertyChanged(property.id, value)
          }
        />
      ))}
    </StyledProperties>
  );
}
