import SideKickProvider from '@components/pages/side-kick-session/providers/SideKickProvider';
import Content from '@components/pages/side-kick-session/sections/content';
import Wrapper from '@components/pages/side-kick-session/Wrapper';
import {
  LiveKitRoom,
  RoomAudioRenderer,
  RoomContext,
} from '@livekit/components-react';
import paths from '@router/paths';
import { Room, RoomEvent } from 'livekit-client';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Alert from '@mui/material/Alert';
import { useTranslation } from '@desygner/ui-common-translation';
import useStayAwake from '@hooks/useStayAwake';

type RoomStatus = 'loading' | 'active' | 'error' | 'closed';
type SideKickMetadata = {
  roomStatus: RoomStatus;
  error?: string;
  modes: { [key: string]: any }; // TODO
  threadId: string;
  sources: number[];
  isRecording: boolean;
  jwt: string;
};

export default function SideKickSession() {
  const { state }: { state: { token?: string } } = useLocation();

  const { current: room } = useRef<Room>(new Room());
  const [roomStatus, setRoomStatus] = useState<RoomStatus>('loading');
  const [roomError, setRoomError] = useState<string | null>(null);
  const { t } = useTranslation();

  useStayAwake({ enabled: true });

  // TODO: do this right
  useEffect(() => {
    function onMetadataChanged() {
      const meta = JSON.parse(room.metadata || '{}') as SideKickMetadata;

      console.log('metadata changed', meta);

      if (meta?.roomStatus && meta.roomStatus !== roomStatus) {
        setRoomStatus(meta.roomStatus);
      }

      if (meta?.error && meta.error !== roomError) {
        setRoomError(meta.error);
      }
    }

    onMetadataChanged();
    room.on(RoomEvent.RoomMetadataChanged, onMetadataChanged);
    room.once(RoomEvent.Connected, onMetadataChanged);

    return () => {
      room.off(RoomEvent.RoomMetadataChanged, onMetadataChanged);
    };
  }, [room, roomStatus, roomError]);

  const navigateTo = useNavigate();

  useEffect(() => {
    if (state?.token == null) {
      navigateTo(paths.sideKick.pathname);
      toast.error(
        t('page.sideKickSession.tokenNotFound', {
          defaultValue: 'You need to provide a token',
        }),
      );
    }
  }, [navigateTo, t, state?.token]);

  return (
    <RoomContext.Provider value={room}>
      <SideKickProvider>
        <LiveKitRoom
          token={state?.token}
          serverUrl={import.meta.env.VITE_LIVE_KIT_SERVER_URL}
          room={room}
          audio={true}
          connect={true}
        >
          <RoomAudioRenderer volume={1.0} />
          <Wrapper>
            {roomStatus === 'loading' && (
              <Alert sx={{ width: '100%' }} severity="info">
                Loading room...
              </Alert>
            )}

            {roomStatus === 'error' && (
              <Alert sx={{ width: '100%' }} severity="error">
                {roomError || 'Room closed with error'}
              </Alert>
            )}

            {roomStatus === 'closed' && (
              <Alert sx={{ width: '100%' }} severity="warning">
                Closed Room
              </Alert>
            )}

            <Content />
          </Wrapper>
        </LiveKitRoom>
      </SideKickProvider>
    </RoomContext.Provider>
  );
}
