import Box from '@mui/material/Box';
import { DirectiveDescriptor, useNestedEditorContext } from '@mdxeditor/editor';
import { alpha } from '@mui/material';
import { useState } from 'react';

export const contextRegex = /:context\[([^\]]+)\]{type="([^"]+)"}/g;

export const ContextDirectiveDescriptor: DirectiveDescriptor = {
  name: 'context',
  testNode: (node) => node.name === 'context',
  hasChildren: true,
  attributes: [],
  type: 'textDirective',
  Editor: ({ mdastNode }) => {
    const { parentEditor, lexicalNode } = useNestedEditorContext();
    const [initialValue] = useState(mdastNode.children[0]?.value);

    return (
      <Box
        component="span"
        sx={{
          color: (theme) => theme.palette.brand.purple.main,
          border: (theme) =>
            `1px solid ${alpha(theme.palette.brand.purple.main, 0.25)}`,
          padding: (theme) => theme.spacing(0.5, 2),
          borderRadius: (theme) => theme.spacing(2),
          background: (theme) => alpha(theme.palette.brand.purple.main, 0.1),
        }}
        suppressContentEditableWarning={true}
        contentEditable="true"
        onInput={(e) => {
          const node = structuredClone(mdastNode);
          node.children[0].value = e.currentTarget.textContent;

          parentEditor.update(
            () => {
              lexicalNode.setMdastNode({ ...mdastNode, ...node } as any);
            },
            { discrete: true },
          );
        }}
      >
        {initialValue || '<missing context>'}
      </Box>
    );
  },
};
