import useSideKick from '@components/pages/side-kick-session/hooks/useSideKick';
import ListItem from '@components/pages/side-kick-session/sections/content/components/transcript/list-item';
import LiveKitAudioTranscriber from '@components/pages/side-kick-session/sections/content/components/transcript/livekit-audio-transcriber';
import ContentLayout from '@components/pages/side-kick-session/sections/content/content-layout';
import { useTranslation } from '@desygner/ui-common-translation';
import Portal from '@mui/material/Portal';
import Typography from '@mui/material/Typography';
import { useCallback, useRef, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import {
  VariableSizeList as List,
  ListOnScrollProps,
  VariableSizeList,
} from 'react-window';

const CLASS_FOR_TRANSCRIPT_LIST = 'transcript-list';
const HEIGHT_OF_TRANSCRIPT_HEADER = 80;
const ESTIMATED_ROW_HEIGHT = 80;
const SCROLLING_THRESHOLD = 250;
const OVERSCAN_COUNT = 10;

export default function Transcript() {
  const { t } = useTranslation();

  const { transcriptsCounter, transcriptIndices } = useSideKick();

  const portalRef = useRef<HTMLDivElement | null>(null);

  const listRef = useRef<VariableSizeList | null>(null);

  const liRef = useRef<HTMLLIElement | null>(null);

  const rowHeights = useRef<{ [key: number]: number }>({});

  const [isAutoScrolling, setIsAutoScrolling] = useState(true);

  const getRowHeight = (index: number) => {
    return rowHeights.current[index] || ESTIMATED_ROW_HEIGHT;
  };

  const setRowHeight = useCallback((index: number, size: number) => {
    if (rowHeights.current[index] !== size) {
      rowHeights.current = { ...rowHeights.current, [index]: size };
      listRef.current?.resetAfterIndex(index);
    }
  }, []);

  function handleScroll(event: unknown, columnHeight: number) {
    const { scrollOffset, scrollDirection } = event as ListOnScrollProps;

    if (
      scrollDirection === 'backward' &&
      scrollOffset + columnHeight - HEIGHT_OF_TRANSCRIPT_HEADER <
        liRef.current!.clientHeight - SCROLLING_THRESHOLD
    ) {
      setIsAutoScrolling(false);
    }
    if (scrollDirection === 'forward') {
      setIsAutoScrolling(true);
    }
  }

  function handleItemKey(index: number) {
    return transcriptIndices[index] || index;
  }

  return (
    <ContentLayout
      headerSlot={
        <Typography>
          {t('page.sideKickSession.transcript', { defaultValue: 'Transcript' })}
        </Typography>
      }
    >
      <AutoSizer>
        {({ height, width }) => {
          return (
            <List
              onScroll={(event) => handleScroll(event, height)}
              className={CLASS_FOR_TRANSCRIPT_LIST}
              ref={listRef}
              height={height - HEIGHT_OF_TRANSCRIPT_HEADER}
              width={width}
              itemCount={transcriptsCounter}
              itemSize={getRowHeight}
              estimatedItemSize={ESTIMATED_ROW_HEIGHT}
              innerRef={liRef}
              itemKey={handleItemKey}
              itemData={{
                setRowHeight,
              }}
              overscanCount={OVERSCAN_COUNT}
            >
              {ListItem}
            </List>
          );
        }}
      </AutoSizer>
      <Portal
        //TODO: we need to find a better way to handle this
        container={document.querySelector(`.${CLASS_FOR_TRANSCRIPT_LIST}`)}
        ref={portalRef}
      >
        <LiveKitAudioTranscriber disableAutoScrolling={isAutoScrolling} />
      </Portal>
    </ContentLayout>
  );
}
