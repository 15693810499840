import useSideKick from '@components/pages/side-kick-session/hooks/useSideKick';
import useTimer from '@hooks/useTimer';
import { FactCheckType, SentimentLabelsType } from '@shared-types/sidekick';
import { DataPacket_Kind, Participant, Room, RoomEvent } from 'livekit-client';
import { useRef, useState, useSyncExternalStore } from 'react';

type SpeechToTextFinalDataType = {
  type: 'stt_sentence';
  text: string;
  index: number;
  start: number;
  end: number;
  speaker: string;
};

type SemanticSearchDataType = {
  type: 'semantic_search';
  index: number;
  result: SentimentLabelsType;
};

type SpeechToTextTempDataType = {
  type: 'stt_temp';
  text: string;
  index: number;
};

type FactCheckSearchDataType = FactCheckType & {
  type: 'fact_check';
};

type RoomTopicType =
  | SpeechToTextFinalDataType['type']
  | SemanticSearchDataType['type']
  | SpeechToTextTempDataType['type']
  | FactCheckSearchDataType['type']
  | 'stt_consolidated_buffer'
  | 'stt_bot_buffer';

export default function useLiveKitTranscriber(room: Room) {
  const [interimTranscript, setInterimTranscript] = useState<string | null>(
    null,
  );
  const { current: textDecoderRef } = useRef(new TextDecoder());

  const { minutes, seconds } = useTimer();

  const {
    handleSetTranscript,
    handleUpdateFactCheckLabelsById,
    handleUpdateSentimentLabelsById,
    handleSetFeed,
  } = useSideKick();

  function subscriber(onStoreChange: VoidFunction) {
    function handleDataReceived(
      payload: Uint8Array,
      _participant?: Participant,
      _kind?: DataPacket_Kind,
      topic?: string,
    ) {
      const strData = textDecoderRef.decode(payload);
      const parsedData = JSON.parse(strData) as unknown;
      switch (topic as RoomTopicType) {
        case 'stt_temp': {
          const typedParsedData = parsedData as SpeechToTextTempDataType;

          const text = typedParsedData.text;

          setInterimTranscript(text);
          onStoreChange();
          break;
        }
        case 'stt_sentence': {
          const { text, index, speaker } = parsedData as SpeechToTextFinalDataType;

          handleSetTranscript({
            speaker,
            content: text,
            id: index.toString(),
            time: { // TODO: move away from useTimer and use start & end fields instead
              minutes: minutes.toString(),
              seconds: seconds.toString()
            },
          });
          setInterimTranscript(null);
          onStoreChange();
          break;
        }
        case 'semantic_search': {
          const { index, result } = parsedData as SemanticSearchDataType;

          handleUpdateSentimentLabelsById({
            id: index.toString(),
            sentiments: result,
          });
          break;
        }
        case 'fact_check': {
          const { index, result, avatar, name } =
            parsedData as FactCheckSearchDataType;

          handleUpdateFactCheckLabelsById({
            id: index.toString(),
            factChecks: result.map(({ outcome, id }) => ({ id, outcome })),
          });
          handleSetFeed({
            id: index.toString(),
            feed: { avatar, index, name, result },
          });
          break;
        }
        case 'stt_consolidated_buffer': {
          console.log('stt_consolidated_buffer', parsedData);
          break;
        }
        case 'stt_bot_buffer': {
          console.log('stt_bot_buffer', parsedData);
          break;
        }
        default: {
          console.error('Unknown topic', topic);
        }
      }
    }

    const getParticipantChangeHandler = (msg: string) => {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      // if (!me?.admin) return () => {};
      return (participant: Participant) => {
        const participants = [...room.remoteParticipants]
          .map(([identity, _]) => identity)
          .join(', ');

        // eslint-disable-next-line no-restricted-syntax
        console.info(msg, participant.identity, participants);
      };
    };

    const handleParticipantConnected = getParticipantChangeHandler(
      '🔌🔌🔌 connected participant: ',
    );

    const handleParticipantDisconnected = getParticipantChangeHandler(
      '🚫🚫🚫 disconnected participant: ',
    );

    room.on(RoomEvent.DataReceived, handleDataReceived);
    room.on(RoomEvent.ParticipantConnected, handleParticipantConnected);
    room.on(RoomEvent.ParticipantDisconnected, handleParticipantDisconnected);

    return () => {
      room.off(RoomEvent.DataReceived, handleDataReceived);
      room.off(RoomEvent.ParticipantConnected, handleParticipantConnected);
      room.off(
        RoomEvent.ParticipantDisconnected,
        handleParticipantDisconnected,
      );
    };
  }

  function getSnapshot() {
    return room.state === 'connected' ? interimTranscript : null;
  }

  const transcript = useSyncExternalStore(subscriber, getSnapshot);

  return {
    transcript,
  };
}
