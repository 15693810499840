import { KnowzStepDefinitionType } from '@lib/step/types';

const SemanticTriggerStepDefinition: KnowzStepDefinitionType = {
  order: 104,
  group: 'Triggers',
  step: {
    componentType: 'task',
    type: 'semantic-trigger',
    name: 'Semantic',
  },
  triggerProperties: [
    {
      ref: 'threadId',
      type: 'id-thread',
    },
    {
      ref: 'historyBlockId',
      type: 'id-history-block',
    },
  ],
  propertyTypes: [
    {
      id: 'classification',
      name: 'Classification',
      description:
        'Define the classification of the sentence you want to match',
      usage: 'definition',
      wrapper: 'item',
      variant: 'short',
      types: ['string'],
    },
    {
      id: 'sentiment',
      name: 'Sentiment',
      description: 'Define the sentiment of the sentence you want to match',
      usage: 'definition',
      wrapper: 'item',
      variant: 'short',
      types: ['string'],
    },
    {
      id: 'output',
      name: 'Semantic Match reference',
      description: 'Assign a name to the conversation up until the match',
      usage: 'output',
      wrapper: 'item',
      types: ['ref-history-blocks'],
    },
  ],
};

export default SemanticTriggerStepDefinition;
