import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Modal from '@mui/material/Modal';

export const StyledBox = styled(Stack)(({ theme }) => ({
  width: '80vw',
  maxWidth: theme.spacing(300),
  height: '80svh',
  borderRadius: theme.spacing(3.75),
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  flexDirection: 'column',
  overflow: 'hidden',
  backgroundColor: theme.palette.background.card.light,
}));

export const StyledModal = styled(Modal)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
  },
}));
