import {
  BagItemType,
  KnowzStepDefinitionType,
  PropertyType,
  StepsDefinitionsType,
  StepType,
} from '@lib/step/types';
import { HashMap } from '@shared-types/utils';
import * as StepsDefinitions from '@lib/step';
import {
  Definition,
  Sequence,
  Step,
  StepsConfiguration,
  ValidatorConfiguration,
} from 'sequential-workflow-designer';
import { contextRegex } from './sections/step-editor/smart-prompt-editor/contextDirectiveDescriptor';

export const stepsConfig: StepsConfiguration = {
  iconUrlProvider: (componentType: string, type: string) => {
    if (type.match(/-trigger$/))
      return 'https://static.webrand.com/icons/v69/svgs/lightning.svg';

    return (
      (
        {
          'slack-message': 'https://www.svgrepo.com/show/327394/logo-slack.svg',
          'update-manifest':
            'https://static.webrand.com/icons/v81/svgs/diskette.svg',
          'load-manifest':
            'https://static.webrand.com/icons/v81/svgs/cell-align---20.svg',
          code: 'https://static.webrand.com/icons/v81/svgs/page-number-format.svg',
          structured:
            'https://static.webrand.com/icons/v81/svgs/group---20.svg',
          'dynamic-switch':
            'https://static.webrand.com/icons/v81/svgs/share.svg',
          'reference-iterator':
            'https://static.webrand.com/icons/v81/svgs/arrows-circle.svg',
          transient: 'https://static.webrand.com/icons/v81/svgs/ai---stars.svg',
          assist: 'https://static.webrand.com/icons/v81/svgs/ai---stars.svg',
          match: 'https://static.webrand.com/icons/v81/svgs/search---stars.svg',
          'design-automation':
            'https://static.webrand.com/icons/v81/svgs/fill-form-pencil.svg',
          'send-email': 'https://static.webrand.com/icons/v81/svgs/email.svg',
        } as HashMap<string>
      )[type] || 'https://static.webrand.com/icons/v81/svgs/effects.svg'
    );
  },

  isDraggable: (step: Step, parentSequence: Sequence) => {
    return !step.type.match(/-trigger$/);
  },

  isDeletable: (step: Step, parentSequence: Sequence) => {
    return true;
  },

  isDuplicable: (step: Step, parentSequence: Sequence) => {
    return true;
  },

  canInsertStep: (step: Step, targetSequence, targetIndex) => {
    return true;
  },

  canMoveStep: (sourceSequence, step: Step, targetSequence, targetIndex) => {
    return true;
  },

  canDeleteStep: (step: Step, parentSequence: Sequence) => {
    return true;
  },
};

export const validatorConfig: ValidatorConfiguration = {
  step: (step: Step, parentSequence: Sequence, definition: Definition) => {
    return areMandatoryPropertiesDefined() && areUsedContextVariablesValid();

    function areMandatoryPropertiesDefined() {
      const def = getStepDefinition(step);

      for (const prop of def.propertyTypes) {
        if (!prop.isOptional && !step.properties[prop.id]?.data) {
          return false;
        }
      }

      return true;
    }

    function areUsedContextVariablesValid() {
      for (const id in step.properties) {
        if (step.properties[id]?.type === 'string') {
          const data = step.properties[id].data || '';

          const usedVariables = data.matchAll(contextRegex);
          const result = [];
          const types = getStepContextTypes(step as StepType);

          for (const type of types) {
            const availableContextOfType = getAvailableOptionsOfType(
              id,
              type,
              definition,
            );

            result.push(
              ...availableContextOfType.map((value) => ({ value, type })),
            );
          }

          for (const usedVariable of usedVariables) {
            const type = usedVariable[2] as BagItemType;
            const value = usedVariable[1].replace(/(.+)\..*$/, '$1'); // TODO: remove this workaround once we support custom types in JSON

            const availableContextOfType = result.filter(
              (r) => r.type === type,
            );

            if (!availableContextOfType.find((r) => r.value === value)) {
              console.error(`Variable ${value} of type ${type} not found`);
              return false;
            }
          }
        }
      }

      return true;
    }
  },

  root: (definition) => {
    return (
      hasMoreThanOneStep() && startsWithTrigger() && !hasMoreThanOneTrigger()
    );

    function hasMoreThanOneStep() {
      return definition.sequence.length > 1;
    }

    function startsWithTrigger() {
      return !!definition.sequence[0].type.match(/-trigger$/);
    }

    function hasMoreThanOneTrigger() {
      return (
        definition.sequence.filter((s) => s.type.match(/-trigger$/)).length > 1
      );
    }
  },
};

export const toolboxConfig = (function () {
  const groups = [];
  const fingerprints: string[] = [];

  for (const key in StepsDefinitions) {
    const def: KnowzStepDefinitionType = (StepsDefinitions as StepsDefinitionsType)[
      key
    ];

    const fingerprint = getStepFingerprint(def.step);
    let group: any = groups.find((group) => group.name === def.group);

    if (fingerprints.includes(fingerprint)) {
      console.error(`Step with fingerprint ${fingerprint} already exists`);
      continue;
    }

    if (!group) {
      group = {
        name: def.group,
        steps: [],
      };

      groups.push(group);
    }

    group.steps.push({ ...def.step, properties: {} });
  }

  groups.sort((a, b) =>
    getGroupOrder(a.name) > getGroupOrder(b.name) ? 1 : -1,
  );

  for (const group of groups) {
    group.steps.sort((a: StepType, b: StepType) =>
      getStepOrder(a) > getStepOrder(b) ? 1 : -1,
    );
  }

  return {
    groups,
    isCollapsed: false,
  };
})();

function getStepOrder(step: StepType): number {
  const def = getStepDefinition(step);
  return def?.order || 0;
}

function getGroupOrder(group: string): number {
  const stepKey = Object.keys(StepsDefinitions).find(
    (key) => (StepsDefinitions as StepsDefinitionsType)[key].group === group,
  );

  return stepKey
    ? (StepsDefinitions as StepsDefinitionsType)[stepKey].order
    : 0;
}

function getStepFingerprint(step: StepType): string {
  return `${step.componentType}-${step.type}`;
}

export function getStepPropertiesDefinition(step: StepType): PropertyType[] {
  const def = getStepDefinition(step);
  return def ? def.propertyTypes : [];
}

export function getStepContextTypes(step: StepType): BagItemType[] {
  const def = getStepDefinition(step);
  return def?.contextTypes || [];
}

function getStepDefinition(step: StepType): KnowzStepDefinition | null {
  const fingerprint = getStepFingerprint(step);
  const key = Object.keys(StepsDefinitions).find((key) => {
    const def = (StepsDefinitions as StepsDefinitionsType)[key];
    return getStepFingerprint(def.step) === fingerprint;
  });

  return key ? (StepsDefinitions as StepsDefinitionsType)[key] : null;
}

function getFlatSequence(sequence: Sequence): StepType[] {
  const steps = [];

  for (const step of sequence) {
    steps.push(step as StepType);

    if ((step as StepType).sequence) {
      steps.push(...getFlatSequence((step as StepType).sequence!));
    }

    if ((step as StepType).branches) {
      for (const branch in (step as StepType).branches) {
        steps.push(...getFlatSequence((step as StepType).branches![branch]));
      }
    }
  }

  return steps;
}

export function getAvailableOptionsOfType(
  id: string,
  type: string,
  definition: Definition,
): BagItemType[] {
  const sequence = getFlatSequence(definition.sequence);
  const opts = [];
  const currentStepIndex = `${sequence.findIndex((s) => s.id === id)}`;

  for (const i in sequence) {
    if (currentStepIndex === i) break;
    const step = sequence[i] as StepType;
    const stepPropertiesDefinition = getStepPropertiesDefinition(step);
    const outputs = stepPropertiesDefinition.reduce((acc, p) => {
      if (p.usage === 'output') acc.push(p.id);
      return acc;
    }, []);

    for (const output of outputs) {
      const property = step.properties?.[output];

      if (property && property.data && property.type === type) {
        opts.push(property.data);
      }
    }
  }

  return opts;
}
