import { useTranslation } from '@desygner/ui-common-translation';
import useAuth from '@hooks/useAuth';
import StyledStack from '@layouts/main/sections/toolbar/expanded/sections/purchased-ai-credit/styles';
import { payments } from '@lib/agent';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import paths from '@router/paths';
import { useState } from 'react';
import { Link } from 'react-router-dom';

export default function PurchasedAICredit() {
  const { t } = useTranslation();

  const [isManagingPaymentLoading, setIsManagingPaymentLoading] =
    useState(false);

  const { me } = useAuth();

  async function managePayments() {
    setIsManagingPaymentLoading(true);
    //? https://www.abeautifulsite.net/posts/opening-a-new-window-after-an-async-operation/
    const newWindow = window.open('about:blank', '_blank');
    if (newWindow) {
      const response = await payments.getStripePortalLink();
      newWindow.location.href = response.data.url;
    }
    setIsManagingPaymentLoading(false);
  }

  if (me?.paid) {
    return (
      <StyledStack className="mini" gap={1}>
        <LoadingButton
          variant="contained"
          fullWidth
          loading={isManagingPaymentLoading}
          onClick={managePayments}
        >
          {t('layout.toolbar.manageAICredit.button', {
            defaultValue: 'Manage payments',
          })}
        </LoadingButton>
      </StyledStack>
    );
  }

  return (
    <StyledStack gap={1}>
      <Typography variant="body2">
        {t('layout.toolbar.buyAICredit.title', {
          defaultValue: 'Top Up Your AI Credits!',
        })}
      </Typography>

      <Typography variant="caption" color="text.secondary">
        {t('layout.toolbar.buyAICredit.subtitle', {
          defaultValue:
            'Purchase more credits to unlock unlimited AI potential!',
        })}
      </Typography>

      <Button
        component={Link}
        to={paths.upgradeAccount.pathname}
        fullWidth
        variant="contained"
        sx={{ marginBlockStart: 3 }}
      >
        {t('layout.toolbar.buyAICredit.button', {
          defaultValue: 'Buy more!',
        })}
      </Button>
    </StyledStack>
  );
}
