import useQueryAfterAuthenticationLoaded from '@hooks/useQueryAfterAuthenticationLoaded';
import { payments } from '@lib/agent';

export default function useGetSubscriptionLazily(priceId: string | null) {
  return useQueryAfterAuthenticationLoaded({
    queryKey: ['subscription'],
    queryFn: async () => {
      const { data } = await payments.getSubscription();
      return data;
    },
    options: {
      staleTime: Infinity,
      enabled: !!priceId,
    },
  });
}
