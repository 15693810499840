import { KnowzStepDefinitionType } from '@lib/step/types';

// TODO: rename this to SaveSourceStepDefinition
const UpdateManifestStepDefinition: KnowzStepDefinitionType = {
  order: 202,
  group: 'Knowz Workspace',
  step: {
    componentType: 'task',
    type: 'update-manifest',
    name: 'Update Manifest',
  },
  propertyTypes: [
    {
      id: 'manifest',
      name: 'Manifest',
      description: 'Select a manifest to update (content must be updated)',
      usage: 'input',
      wrapper: 'item',
      types: ['ref-source'],
    },
  ],
};

export default UpdateManifestStepDefinition;
