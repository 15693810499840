import useSideKick from '@components/pages/side-kick-session/hooks/useSideKick';
import Card from '@components/pages/side-kick-session/sections/content/components/feed/list-item/card';
import { useEffect, useMemo, useRef } from 'react';
import { ListChildComponentProps } from 'react-window';

export default function ListItem({
  data,
  index,
  style,
}: Omit<ListChildComponentProps, 'data'> & {
  data: {
    setRowHeight: (index: number, size: number) => void;
  };
}) {
  const rowRef = useRef<HTMLDivElement | null>(null);

  const { feeds, transcripts } = useSideKick();

  const { setRowHeight } = data;

  useEffect(() => {
    if (rowRef.current) {
      const newHeight = rowRef.current.clientHeight;
      setRowHeight(index, newHeight);
    }
  }, [index, data, rowRef, setRowHeight]);

  const listOfFeeds = useMemo(() => Object.values(feeds || []), [feeds]);

  const feedItem = listOfFeeds[index];

  if (listOfFeeds.length === 0 || feedItem == null || transcripts == null)
    return null;

  return (
    <div style={style}>
      <Card
        result={feedItem.result}
        setRowHeight={setRowHeight}
        ref={rowRef}
        index={index}
        time={transcripts[feedItem.index]?.time}
        avatar={feedItem.avatar}
        name={feedItem.name}
      />
    </div>
  );
}
