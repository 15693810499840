import {
  MDXEditor,
  directivesPlugin,
  headingsPlugin,
  listsPlugin,
  markdownShortcutPlugin,
  quotePlugin,
  tablePlugin,
  thematicBreakPlugin,
  toolbarPlugin,
  codeBlockPlugin,
} from '@mdxeditor/editor';
import '@mdxeditor/editor/style.css';
import { alpha, useTheme } from '@mui/material';
import { ContextDirectiveDescriptor } from './contextDirectiveDescriptor';
import { MuiCodeEditorDescriptor } from './muiCodeEditorDescriptor';

const CODE_COLOR = '#cd1d8d';

export const markdownStyles = {
  // TODO: consider bringing from tailwind (worth it?)
  '& p, & h5, & h6': {
    marginBottom: '1.25em',
  },

  '& h1': {
    fontWeight: 800,
    fontSize: '2.25em',
    marginTop: 0,
    marginBottom: '.8888889em',
    lineHeight: 1.1111111,
  },

  '& h2': {
    fontWeight: 700,
    fontSize: '1.5em',
    marginTop: '2em',
    marginBottom: '1em',
    lineHeight: 1.3333333,
  },

  '& h3': {
    fontWeight: 600,
    fontSize: '1.25em',
    marginTop: '1.6em',
    marginBottom: '.6em',
    lineHeight: 1.6,
  },

  '& h4': {
    fontWeight: 600,
    marginTop: '1.5em',
    marginBottom: '.5em',
    lineHeight: 1.5,
  },

  '& code': {
    color: CODE_COLOR,

    '> span': {
      backgroundColor: alpha(CODE_COLOR, 0.1),
      borderRadius: '0.25em',
    },
  },

  '& ul, & ol': {
    marginTop: '1.25em',
    marginBottom: '1.25em',
    paddingLeft: '1.625em',

    '& li': {
      paddingLeft: '.375em',
      listStyleType: 'initial',
    },
  },
};

export default function Editor({
  markdown,
  setUpdatedMarkdown,
  toolbarContents,
}: {
  markdown: string;
  setUpdatedMarkdown?: (markdown: string) => void;
  toolbarContents?: () => React.ReactNode;
}) {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  const plugins = [
    listsPlugin(),
    quotePlugin(),
    tablePlugin(),
    thematicBreakPlugin(), // <hr>
    codeBlockPlugin({
      defaultCodeBlockLanguage: 'txt',
      codeBlockEditorDescriptors: [MuiCodeEditorDescriptor],
    }),
    directivesPlugin({
      directiveDescriptors: [ContextDirectiveDescriptor],
    }),
    headingsPlugin(),
    // Order matters, markdownShortcutPlugin should be the last one
    // https://github.com/mdx-editor/editor/issues/290#issuecomment-1883537849
    markdownShortcutPlugin(), // Understanding typed characters as markdown
  ];

  if (toolbarContents) {
    plugins.push(
      toolbarPlugin({
        toolbarClassName: 'mdxeditor-toolbar',
        toolbarContents,
      }),
    );
  }

  // TODO: setUpdatedData is not being called. It works if the initial markdown is hardcoded
  return (
    <MDXEditor
      className={'mdxeditor-root ' + (isLight ? 'light-theme' : 'dark-theme')}
      contentEditableClassName="mdxeditor-contenteditable"
      markdown={markdown}
      plugins={plugins}
      onChange={setUpdatedMarkdown}
    />
  );
}
